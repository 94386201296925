import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserDetail from '../../../components/BarData/UserDetail';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import { actionCreators } from '../../../state';
import { RootState } from '../../../state/reducers';
import ServiceExpandButton from '../../common/ServiceListExpand';
import RTSInspectionSummary from '../../common/requestDetail/RTSInspectionSummary';
import useFetchSingleRequest, { CheckisSameID } from '../../hooks/csr/useFetchSingleRequest';
import WorkProofData from '../../csr/bulkInfoModal/components/WorkProofData';
import WorkProofPreview from '../../csr/bulkInfoModal/components/WorkProofPreview';
import useFetchCheckList from '../../hooks/useFetchCheckList';
import AcceptRejectBtn from '../PrimaryComponents/requestComponents/AcceptRejectBtn';
import RejectBox from '../PrimaryComponents/requestComponents/RejectBox';
import InfoUp from '../PrimaryComponents/requestComponents/vehicle/Info';
import MilesBox from '../PrimaryComponents/requestComponents/vehicle/LastPreventive';
import NotesPopup from '../PrimaryComponents/requestComponents/vehicle/NotesPopUp';
import Scheduled from '../PrimaryComponents/requestComponents/vehicle/Scheduled';
import StatusTrackerPopButton from '../PrimaryComponents/requestComponents/vehicle/StatusTrackerPopUp';
import InfoDown from '../PrimaryComponents/requestComponents/vehicle/VanType';
import { Button } from '@mui/material';
import Logs from '../../common/requestDetail/MaintainanceLogsModel';
import VehicleQuestionnaire from '../../csr/inspection/vehicleQuestionnaire/Index';
import useFetchVehicleChecklist from '../../hooks/useFetchVehicleChecklist';
import { SinglePriorityStatus } from '../../csr/scheduleRequest/components/PriorityIcons';
import RequestPart from '../../csr/primaryComponents/RequestPart';
export const RequestDetail = ({
  handleClose,
  item,
  type,
  body5,
  body6,
  status,
  refetch,
  fullbody2,
}: any) => {
  const dispatch = useDispatch();
  const { setAttachments } = bindActionCreators(actionCreators, dispatch);
  const { checkList, getCheckList } = useFetchCheckList();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const [rejecting, setRejecting] = useState(false);
  const { vehicleChecklist, getVehicleCheckList } = useFetchVehicleChecklist();
  useEffect(() => {
    if (item) {
      getCheckList(item, true);
      getVehicleCheckList(item);
    }
  }, [item?.id]);
  const updatedItem = useMemo(
    () => ({
      ...item,
      ...vehicleChecklist,
      setRejecting,
      refetch,
      rejecting,
      handleClose,
      ...(!CheckisSameID(user, item) && { viewOnly: true }),
    }),
    [item, vehicleChecklist]
  );
  const { handleAssignVednor }: any = useFetchSingleRequest();
  const [workProofData, setWorkProofData] = useState(false as boolean);
  return (
    item && (
      <OverlayContainer show={true}>
        <ModalForm
          title={'Add CSR'}
          onClose={() => {
            handleClose();
            setAttachments([]);
          }}
          width={'w-[59vw] '}
          noLabel>
          {workProofData ? (
            <WorkProofData
              data={{
                checklistData: checkList || {},
                item: item,
              }}
              setWorkProofData={setWorkProofData}
            />
          ) : (
            <div className='flex flex-col gap-3 h-full pb-2'>
              <div className='flex gap-2 items-center'>
                <p className='text-blue-500 font-semibold text-sm'> {item?.title}</p>
                {item?.request_type === 'repair' && (
                  <div className='w-fit bg-primary-light text-primary px-2 rounded-full'>
                    Repair
                  </div>
                )}
                <SinglePriorityStatus priority={item?.priority || '2'} />
                <RequestPart
                  item={{ ...updatedItem, refetch: updatedItem?.refetch || refetch }}
                  handleClose={handleClose}
                />
              </div>
              <div className='divide-x-2 divide-slate-100 flex h-full'>
                <section className='w-1/2 mr-3 flex flex-col justify-between h-[37.2rem] '>
                  <div className='flex  flex-col justify-between gap-1 h-[37.2rem]'>
                    <div className='h-fit w-full flex flex-col gap-2'>
                      <UserDetail
                        label={item?.dsp?.name || 'N/A'}
                        position='right'
                        labelSx='whitespace-nowrap overflow-hidden text-ellipsis w-0 flex-1 text-[0.9rem] text-slate-400'
                        AvatarSx={'w-[2rem] h-[2rem] text-[1rem]'}
                        bgColor={'#A271D3'}
                      />
                      <InfoUp item={item} />
                      <div className=''>
                        <InfoDown item={item} />
                        <div className=''>
                          <MilesBox item={item} />
                        </div>
                      </div>
                      <div className='mt-4'></div>
                    </div>
                    <>
                      <div className='flex flex-col gap-2'>
                        <RTSInspectionSummary item={item} />
                        <NotesPopup item={item} />
                      </div>
                    </>
                  </div>
                  {!updatedItem?.viewOnly && (
                    <>
                      <div className='w-full mt-1 flex flex-col h-fit'>
                        <WorkProofPreview
                          item={item}
                          checklistData={checkList}
                          setWorkProofData={setWorkProofData}
                        />
                      </div>
                    </>
                  )}
                </section>
                <section className='w-1/2 pl-3 h-[37rem]'>
                  <div className='flex flex-col justify-between h-full overflow-auto'>
                    {rejecting ? (
                      <div className='bg-primary-light p-1 rounded-lg'>
                        <RejectBox item={updatedItem} />
                      </div>
                    ) : (
                      <div className='flex flex-col gap-3'>
                        {updatedItem?.checklist_questions?.question_answers?.length > 0 && (
                          <div className='w-full flex justify-center items-center'>
                            <VehicleQuestionnaire item={updatedItem} />
                          </div>
                        )}
                        <ServiceExpandButton item={{ checkList }} variant='expandDown' />
                        <Scheduled item={updatedItem} setshowAssignTechnician={setRejecting} />
                        {user.role === 'fleetmax_partner' &&
                          !item?.bulk_id &&
                          item?.status_id === '-1' &&
                          item?.sub_stage === '2' && (
                            <Button
                              variant='outlined'
                              className='text-[1.05rem] flex gap-3 font-bold'
                              onClick={() => {
                                handleAssignVednor(item);
                              }}>
                              <i className='pi pi-plus text-[1rem] font-bold'></i> Assign
                            </Button>
                          )}
                      </div>
                    )}
                    {!updatedItem?.viewOnly && (
                      <>
                        {type === 'requests' && !rejecting && (
                          <div className='mb-[10rem]'>
                            <AcceptRejectBtn item={updatedItem} isModel={true} />
                          </div>
                        )}
                        <div className='flex justify-end flex-col gap-2'>
                          {body5 && (
                            <div className='text-center'>
                              {body5 &&
                                body5.length > 0 &&
                                body5.map((elem: any, index: number) =>
                                  typeof elem === 'string' ? elem : elem(item, index)
                                )}
                            </div>
                          )}
                        </div>
                        <div className='flex flex-col gap-2'>
                          {fullbody2 && (
                            <div className='text-center'>
                              {fullbody2 &&
                                fullbody2.length > 0 &&
                                fullbody2.map((elem: any, index: number) =>
                                  typeof elem === 'string' ? elem : elem(item, index)
                                )}
                            </div>
                          )}

                          <StatusTrackerPopButton item={item} />
                          {!rejecting && (
                            <>
                              {item.status_id === '4' &&
                                (item?.sub_stage ? item.sub_stage === '2' : true) && (
                                  <div
                                    className='container mt-auto'
                                    style={{ justifyContent: 'center', display: 'flex' }}>
                                    <button
                                      style={{
                                        padding: '0.467rem .063rem 0.467rem 0.75rem',
                                        alignItems: 'center',
                                        gap: '0.584rem',
                                        borderRadius: '0.563rem',
                                        background: '#4378C8',
                                        width: '100%',
                                        color: 'white',
                                        fontSize: '1.2rem',
                                      }}
                                      onClick={() => {
                                        item?.setAssignTechnicianModal({
                                          open: true,
                                          data: updatedItem,
                                        });
                                      }}>
                                      Assign Technician
                                    </button>
                                  </div>
                                )}
                            </>
                          )}
                          <Logs type='partner_logs' item={item} />
                        </div>
                      </>
                    )}
                  </div>
                </section>
              </div>
              {body6 && (
                <div className='w-full text-center flex flex-col gap-3'>
                  {body6.length > 0 &&
                    body6.map((elem: any) => (typeof elem === 'string' ? elem : elem(item)))}
                </div>
              )}
            </div>
          )}
        </ModalForm>
      </OverlayContainer>
    )
  );
};

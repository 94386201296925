import { v4 } from 'uuid';
import * as yup from 'yup';
import { number, object, string } from 'yup';
import emailAndPassWordRejex from './requestDetail/EmailandPassWordRules';

const { passwordRules, emailRules } = emailAndPassWordRejex();
export const editfieldDetails = [
  {
    label: 'Name*',
    key: v4(),
  },
  {
    label: 'Email ID*',
    key: v4(),
  },
  {
    label: 'Phone Number*',
    type: 'number',
    key: v4(),
  },
];
export const schema = object({
  'Name*': yup
    .string()
    .required('Name is mandatory')
    .test('len', 'Full Name must be less than 30 characters', (val) => !val || val.length < 31),
  'Email ID*': yup.string().email('Invalid email format').required().matches(emailRules, {
    message: 'Please input a valid email address',
  }),
  'Password*': string()
    .matches(passwordRules, { message: 'Please create a stronger password' })
    .required('Password is required')
    .min(8, 'Password must be at least 8-16 characters, including one special character'),
  'Confirm Password*': string()
    .oneOf([yup.ref('Password*'), null], 'Passwords must match')
    .required()
    .min(8, 'Password must be at least 8 characters'),
  'Phone Number*': number()
    .required('Phone Number* is a required field')
    .positive()
    .integer()
    .test('len', 'Length must be 10 digits', (val) => val?.toString().length === 10)
    .typeError('Must be at least 10 digits'),
}).required();

export const editschema = object({
  'Name*': yup
    .string()
    .required('Name is mandatory')
    .test('len', 'Name must be less than 30 characters', (val) => !val || val.length < 31),
  'Email ID*': yup.string().email('Invalid email format').required().matches(emailRules, {
    message: 'Please input a valid email address',
  }),
  'Phone Number*': number()
    .required('Phone Number* is a required field')
    .positive()
    .integer()
    .test('len', 'Length must be 10 digits', (val) => val?.toString().length === 10)
    .typeError('Must be at least 10 digits'),
}).required();

export const serviceSchema = yup.object().shape({
  name: yup.string().required('Service name is required'),
  price: yup.string().required('Price is required'),
  checklist_id: yup.number().required('Checklist ID is required'),
  amount: yup.number().required('Amount is required'),
  description: yup.string().required('Description is required'),
  code: yup.string().required('Product code is required'),
  qty: yup.string().required('Quantity is required'),
  tax: yup.boolean().required('Tax status is required'),
});

import { useEffect, useState } from 'react';
import PreventiveAPI from '../../../../api/preventiveApi';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import { toast } from '../../../../components/Toast/CustomToast';
import Edit from './components/Edit';
import View from './components/View';

const LeasePlan = ({ handleClose, item, refetch, inNotModel }: any) => {
  const loader = HandleLoad();
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [amount, setAmount] = useState<any>(null);
  const [approvedFrom, setApprovedFrom] = useState<any>(null);
  const [attachment, setAttachment] = useState<File[]>([]);
  const [invoiceAttachment, setInvoiceAttachment] = useState<File[]>([]);
  const [removedAttachments, setRemovedAttachments] = useState<File[]>([]);
  const [removedInvoiceAttachments, setRemovedInvoiceAttachments] = useState<File[]>([]);
  const [edit, setEdit] = useState(true);

  const removeAttachment = (index: number) => {
    const removedAttachment = attachment[index];
    const updatedAttachments = [...attachment];
    updatedAttachments.splice(index, 1);
    setAttachment(updatedAttachments);
    if (item?.lease_plan?.attachment?.includes(removedAttachment)) {
      setRemovedAttachments([...removedAttachments, removedAttachment]);
    }
  };
  const removeInvoiceAttachment = (index: number) => {
    const removedInvoiceAttachment = invoiceAttachment[index];
    const updatedInvoiceAttachments = [...invoiceAttachment];
    updatedInvoiceAttachments.splice(index, 1);
    setInvoiceAttachment(updatedInvoiceAttachments);
    if (item?.lease_plan?.invoice_attachment?.includes(removedInvoiceAttachment)) {
      setRemovedInvoiceAttachments([...removedInvoiceAttachments, removedInvoiceAttachment]);
    }
  };

  const RemoveLeasePlanAttachMents = async (
    data: any,
    removedAttachments: File[],
    removedInvoiceAttachments: File[]
  ) => {
    let payload: any = {};
    if (removedAttachments.length > 0) {
      payload['attachment'] = removedAttachments;
    }
    if (removedInvoiceAttachments.length > 0) {
      payload['invoice_attachment'] = removedInvoiceAttachments;
    }
    loader(true);
    const res = await PreventiveAPI.removeLeasePlanAttachment({
      id: item.lease_plan.id,
      payload,
    });
    if (res.status === 200) {
      handleClose();
      setEdit(false);
      refetch();
    } else {
      toast.error('Someting Went Wrong');
    }
    loader(false);
  };

  const updatePlan = async () => {
    let isEmpty = true;
    let startTime = new Date().getTime();
    if (!amount) {
      toast.error('Please add amount');
      return;
    }

    let formData = new FormData();
    if (item?.lease_plan.amount !== amount) {
      formData.append('amount', amount?.toString() || '');
      isEmpty = false;
    }
    if (item?.lease_plan.invoice_number !== invoiceNumber) {
      formData.append('invoice', invoiceNumber);
      isEmpty = false;
    }
    if (item?.lease_plan.approved_from !== approvedFrom) {
      formData.append('approved_from', approvedFrom || 'null');
      isEmpty = false;
    }
    attachment.forEach((attachment: any, index: any) => {
      if (typeof attachment !== 'string') {
        isEmpty = false;

        formData.append(`attachment_[${index}]`, attachment);
      }
    });
    invoiceAttachment.forEach((attachment, index) => {
      if (typeof attachment !== 'string') {
        isEmpty = false;
        formData.append(`invoice_attachment_[${index}]`, attachment);
      }
    });

    if (removedAttachments.length > 0 || removedInvoiceAttachments.length > 0) {
      await RemoveLeasePlanAttachMents(formData, removedAttachments, removedInvoiceAttachments);
    }

    if (!isEmpty) {
      loader(true);
      const timeoutId = setTimeout(() => {
        const currentTime = new Date().getTime();
        const elapsedTime = (currentTime - startTime) / 1000;

        if (elapsedTime > 2) {
          toast.info('Please wait till the image is uploading');
        }
      }, 2000);
      PreventiveAPI.updateLeasePlan({
        id: item.lease_plan.id,
        data: formData,
      })
        .then((res) => {
          clearTimeout(timeoutId);
          if (res.status === 200) {
            handleClose?.();
            if (inNotModel && item?.lease_plan) {
              setEdit(!edit);
              refetch();
            }
            refetch();
            toast.success('Data Updated');
          } else {
            toast.error('Someting Went Wrong');
          }
          loader(false);
        })
        .catch((err) => console.log(err));
    } else {
      setEdit(false);
    }
  };

  const insertPlan = () => {
    let startTime = new Date().getTime();
    if (!amount) {
      toast.error('Please add amount');
      return;
    }

    if (invoiceAttachment || attachment) {
      loader(true);
      const timeoutId = setTimeout(() => {
        const currentTime = new Date().getTime();
        const elapsedTime = (currentTime - startTime) / 1000;

        if (elapsedTime > 2) {
          toast.info('Please wait till the image is Uploading');
        }
      }, 2000);
      const formData = new FormData();
      formData.append('invoice', invoiceNumber);
      formData.append('amount', amount?.toString() || '');
      formData.append('preventive_request_id', item.id || item?.preventive_request_id || '');
      approvedFrom && formData.append('approved_from', approvedFrom);
      if (attachment) {
        attachment.forEach((file, index) => {
          formData.append(`attachment_[${index}]`, file);
        });
      }

      if (invoiceAttachment) {
        invoiceAttachment.forEach((file: any, index: any) => {
          formData.append(`invoice_attachment_[${index}]`, file);
        });
      }
      PreventiveAPI.createLeasePlan(formData)
        .then((res) => {
          clearTimeout(timeoutId);
          if (res.status === 200) {
            toast.success('Data Added');
            refetch();
            handleClose?.();
          } else {
            toast.error('Someting Went Wrong');
          }
          loader(false);
        })
        .catch((err) => console.log(err));
    } else {
      toast.error('Please add any one of the attachments');
    }
  };

  const handleClick = () => {
    if (item?.lease_plan) {
      updatePlan();
    } else {
      insertPlan();
    }
  };

  useEffect(() => {
    if (item?.lease_plan) {
      const { invoice_number, amount, approved_from, attachment, invoice_attachment } =
        item.lease_plan;
      setInvoiceNumber(invoice_number || '');
      setAmount(amount || '');
      setApprovedFrom(approved_from);
      setEdit(false);
      if (attachment) {
        setAttachment([...attachment]);
      }
      if (invoice_attachment) {
        setInvoiceAttachment([...invoice_attachment]);
      }
    }
  }, [item]);

  const LeasPlanData = {
    ...item,
    setApprovedFrom,
    setInvoiceNumber,
    setAmount,
    removeAttachment,
    removeInvoiceAttachment,
    setAttachment,
    handleClick,
    setInvoiceAttachment,
  };
  useEffect(() => {
    if (!item?.lease_plan) {
      setEdit(true);
    } else setEdit(false);
  }, [item]);
  return (
    <>
      {inNotModel ? (
        <>
          {edit && (
            <div className=''>
              <Edit
                approvedFrom={approvedFrom}
                invoiceNumber={invoiceNumber}
                amount={amount}
                attachment={attachment}
                invoiceAttachment={invoiceAttachment}
                data={LeasPlanData}
              />
            </div>
          )}
          {!edit && item?.lease_plan && (
            <div className='w-full'>
              <View item={item} setEdit={setEdit} isMoveToComplete={inNotModel} />
            </div>
          )}
        </>
      ) : (
        <>
          <OverlayContainer show={true}>
            <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[28rem]'} noLabel>
              <>
                {edit && (
                  <Edit
                    approvedFrom={approvedFrom}
                    invoiceNumber={invoiceNumber}
                    amount={amount}
                    attachment={attachment}
                    invoiceAttachment={invoiceAttachment}
                    data={LeasPlanData}
                  />
                )}
                <div className='w-full'>
                  {!edit && <View item={{ ...item }} setEdit={setEdit} />}
                </div>
              </>
            </ModalForm>
          </OverlayContainer>
        </>
      )}
    </>
  );
};

export default LeasePlan;

import { Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PreventiveAPI from '../../../api/preventiveApi';
import HandleLoad from '../../../components/Loader/HandleLoad';
import Modal from '../../../components/OverlayContainer/Model';
import { toast } from '../../../components/Toast/CustomToast';
import { RootState } from '../../../state/reducers';
import { makeData } from '../../common/FilterRequest';
import ImagePreview from '../../common/ImageModal';
import { ModelContainerCancel } from '../../common/requestDetail/CancelRequestModel';
import useFetchCheckList from '../../hooks/useFetchCheckList';
import Invoice from '../../vender/PrimaryComponents/invoice/Invoice';
import NotesPopup from '../../vender/PrimaryComponents/requestComponents/vehicle/NotesPopUp';
import Technician from '../../vender/components/request/AssignTechnician';
import AssignVendorModal from '../components/schedule/AssignVendorModal';
import InvoiceModal from '../primaryComponents/Invoice';
import LeasePlan from '../primaryComponents/LeasePlan';
import ImageModal from '../primaryComponents/SubmitProofModel';
import ViewVendorInvoice from '../primaryComponents/ViewInvoice';
import VehicleInfo from './VehicleInfo';
import AssignVendor from './components/PendingReqVendorAssign/AssignVendorNew';
import BulkServices from './components/BulkServices';
import ViewChecklist from './components/Services';
import WorkProofData from './components/WorkProofData';
import WorkProofPreview from './components/WorkProofPreview';
import CsrRequestDetail from './csr/RequestInfo';
import VendorRequestDetail from './vendor/RequestInfo';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import { DisputeLists } from '../primaryComponents/DisputeLists';
import RTSInspectionSummary from '../../common/requestDetail/RTSInspectionSummary';
import PartnerApi from '../../../api/partnerSpecificApi';
import { CheckisSameID } from '../../hooks/csr/useFetchSingleRequest';

function BulkInfoModel(props: {
  type?: string;
  handleClose: () => void;
  data: any;
  refetch?: () => void;
  refetchPm?: () => void;
}) {
  const { data: propsData, handleClose, refetch, refetchPm } = props;
  const loader = HandleLoad();
  const dispatch = useDispatch();
  const { setBulkRequestDetails, setServicesList, setDispute, setAttachments, setBulkReqData } =
    bindActionCreators(actionCreators, dispatch);
  const { user, servicesList, dispute }: any = useSelector((state: RootState) => state.userState);
  const [data, setData] = useState(null as any);
  const [vendorModal, setVendorModal] = useState({ open: false, data: {} });
  const [workProofData, setWorkProofData] = useState(false as boolean);
  const [showAssignVendor, setShowAssignVendor] = useState(false as boolean);
  const [checklistViewModal, setChecklistViewModal] = useState({
    data: null,
    show: false,
  } as {
    show: boolean;
    data: any;
  });
  const [bulkCheckListView, setBulkCheckListView] = useState({
    data: null,
    open: false,
  } as {
    open: boolean;
    data: any;
  });
  const [preview, setPreview] = useState<{ open: boolean; data: any[] }>({ open: false, data: [] });
  const [imageModal, setImageModal] = useState({ open: false, data: {} } as {
    open: boolean;
    data: any;
  });
  const [leasePlanModal, setLeasePlanModal] = useState({ open: false, data: {} } as {
    open: boolean;
    data: any;
  });
  const [invoiceModal, setInvoiceModal] = useState({ open: false, data: {} } as {
    open: boolean;
    data: any;
  });
  const [makePayment, setMakePayment] = useState({ open: false, data: {} } as {
    open: boolean;
    data: any;
  });
  const [viewinvoiceModal, setViewInvoiceModal] = useState({ open: false, data: {} } as {
    open: boolean;
    data: any;
  });
  const [assignTechnicianModal, setAssignTechnicianModal] = useState({ open: false, data: {} } as {
    open: boolean;
    data: any;
  });
  const [noProofConfirmationModel, setNoProofConfirmationModel] = useState({
    show: false,
    data: '',
  });
  const [showCancelModel, setShowCancelModel] = useState({ open: false, data: {} });
  const [vehicles, setVehciles] = useState([] as any[]);
  const [selectedVehicle, setSelectedVehicle] = useState(null as any);
  const { checkList, getCheckList } = useFetchCheckList();
  const isValidUser = ['fleetmax_partner', 'fleetmax_vendor'].includes(user?.role);
  const handleCloseAll = () => {
    handleClose();
    setServicesList({ ...servicesList, show: false });
    setAttachments([]);
    setDispute({
      ...dispute,
      open: false,
    });
  };
  useEffect(() => {
    if (data?.bulkSchedules) {
      const final_data: any[] = [];
      let update_selected_vehicle = null;
      data?.bulkSchedules.forEach((item: any) => {
        const obj = {
          ...item,
          id: item?.id || item.preventive_request_id,
          invoiceModal,
          setShowCancelModel: (d: any) => setShowCancelModel(d),
          setChecklistViewModal: (d: any) => setChecklistViewModal(d),
          setPreview: (d: any) => setPreview(d),
          setImageModal: (d: any) => setImageModal(d),
          setLeasePlanModal: (d: any) => setLeasePlanModal(d),
          setInvoiceModal: (d: any) => setInvoiceModal(d),
          setMakePayment: (d: any) => setMakePayment(d),
          setAssignTechnicianModal: (d: any) => setAssignTechnicianModal(d),
          handleRemoveVehicle: (d: any) => handleRemoveVehicle(d),
          handleMoveState: (id: any, status: any | string, note?: string) =>
            handleMoveState(id, status, note),
          handleRemoveTechnician: (d: any) => handleRemoveTechnician(d),
          setViewInvoiceModal: (d: any) => setViewInvoiceModal(d),
          refetch: () => {
            refetch?.();
            if (user.role === 'fleetmax_csr') {
              fetchCsrData(propsData);
            } else {
              fetchVendorData(propsData);
            }
          },
          handleClose: handleCloseAll,
        };
        final_data.push(obj);
        if (+obj?.id === (+selectedVehicle?.id || +selectedVehicle?.preventive_request_id)) {
          update_selected_vehicle = obj;
        }
      });

      setVehciles(final_data);
      setBulkRequestDetails(data);
      update_selected_vehicle
        ? setSelectedVehicle(update_selected_vehicle)
        : setSelectedVehicle(null);
    }
  }, [data?.bulkSchedules]);

  useEffect(() => {
    /// update data incase of bulk invoicing
    if (invoiceModal?.open && !invoiceModal?.data?.updateVendorInvoice) {
      setInvoiceModal({
        open: true,
        data,
      });
    }
  }, [data]);

  useEffect(() => {
    /// update data incase of vendor only single update invoicing
    if (selectedVehicle && invoiceModal.data?.updateVendorInvoice) {
      setInvoiceModal({
        open: true,
        data: {
          ...selectedVehicle,
          updateVendorInvoice: true,
        },
      });
    }
  }, [selectedVehicle]);

  useEffect(() => {
    if (propsData) {
      if (user.role === 'fleetmax_csr') {
        fetchCsrData(propsData);
      } else {
        fetchVendorData(propsData);
      }
    }
  }, [propsData]);
  const handleMoveState = (id: string, status: any | string, note?: string) => {
    loader(true);
    let status_id, sub_stage;
    if (typeof status === 'string') {
      status_id = status;
    } else {
      status_id = status.status_id;
      sub_stage = status.sub_stage;
    }
    PreventiveAPI.statusUpdate({
      preventive_request_id: id,
      status_id,
      sub_stage,
      note: noProofConfirmationModel?.data?.length > 0 ? noProofConfirmationModel?.data : note,
    })
      .then((res) => {
        if (res.status === 200) {
          setImageModal({ open: false, data: {} });
          refetch?.();
          setNoProofConfirmationModel({ show: false, data: '' });
        } else {
          toast.error('someting went wrong');
        }
        loader(false);
      })
      .catch((err) => console.log(err));
  };

  const handleRemoveVehicle = (d: { id: string; bulk_id: string }) => {
    const { id } = d;
    loader(true);
    PreventiveAPI.removeBulkSchedule({ preventive_request_id: id })
      .then((res) => {
        if (res.status === 200) {
          refetch?.();
          refetchPm?.();
        } else {
          toast.error('someting went wrong');
        }
        loader(false);
      })
      .catch((err) => console.log(err));
  };

  const handleRemoveTechnician = (item: any) => {
    loader(true);
    PreventiveAPI.removeTechnician({ preventive_request_id: item?.preventive_request_id })
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${item?.technician?.name} Removed`);
          refetch?.();
        } else {
          toast.error('Someting went wrong');
        }
        loader(false);
      })
      .catch((err) => console.log(err));
  };

  const getOpenedInfo = (d: any) => {
    if (user.role === 'fleetmax_csr') {
      return selectedVehicle?.id === d?.id;
    } else {
      return selectedVehicle?.preventive_request_id === d?.preventive_request_id;
    }
  };

  let params: any = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, [window.location.search]);

  useEffect(() => {
    if (selectedVehicle) {
      getCheckList(selectedVehicle, true);
    }
  }, [selectedVehicle?.id]);

  const fetchCsrData = (data: any, noToast?: boolean) => {
    loader(true);
    PartnerApi.getAllDataParticularId(`bulk_id=${data?.bulk_id}`)
      .then((res) => {
        if (res.status === 200) {
          const tempData = res.data?.data;
          if (tempData && tempData?.length > 0) {
            const individualRequest = ['in-process', 'complete'].includes(params.get('type'));
            const [finalData, selectedData] = makeData(tempData, individualRequest, data);
            const newBulkData = {
              ...tempData[0],
            };
            const endResult = {
              ...newBulkData,
              bulkSchedules: finalData,
            };
            setData(endResult);
            if (!selectedVehicle) {
              // no veicle selected
              setSelectedVehicle(selectedData);
            }
            loader(false);
            return [endResult, selectedData];
          } else {
            if (!noToast) {
              toast.error('No data found');
            }
          }
        } else {
          toast.error('Something went wrong');
        }
        loader(false);
      })
      .catch((err) => console.log(err));
  };

  const fetchVendorData = (data: any, noToast?: boolean) => {
    loader(true);
    let query = `bulk_id=${data?.bulk_id}`;
    if (data?.is_rejected) {
      query += `&attempt=${data?.attempt}`;
    }
    PartnerApi.getAllDataParticularId(
      `bulk_id=${data?.bulk_id}${data?.all_users ? '&all_users=true' : ''}`
    )
      .then((res) => {
        if (res.status === 200) {
          const tempData = res.data?.data;
          const isSameIdinRejected = CheckisSameID(user, tempData?.[0]);
          if (tempData && tempData?.length > 0) {
            const individualRequest = ['assigned', 'approved'].includes(params.get('type'));
            const [finalData, selectedData] = makeData(tempData, individualRequest, data, user);
            const newBulkData = {
              ...tempData[0],
            };
            const endResult = {
              ...newBulkData,
              ...(!isSameIdinRejected &&
                isValidUser && {
                  status_id: user?.role === 'fleetmax_partner' ? '-1' : '4',
                  sub_stage: '3',
                  vendor_invoice_dispute: null,
                  vendor_invoice_status: null,
                  invoice: null,
                  final_payment: null,
                }),
              bulkSchedules: finalData,
            };
            setData(endResult);
            setBulkReqData(endResult);
            if (!selectedVehicle) {
              // no veicle selected
              setSelectedVehicle(selectedData);
            }
            loader(false);
            return [endResult, selectedData];
          } else {
            setBulkReqData(null);
            if (!noToast) {
              toast.error('No data found');
            }
          }
        } else {
          toast.error('Something went wrong');
        }
        loader(false);
      })
      .catch((err) => console.log(err));
  };
  const requestInfoCSR = {
    ...data,
    setAssignTechnicianModal: (d: any) => setAssignTechnicianModal(d),
    handleRemoveTechnician: (d: any) => handleRemoveTechnician(d),
    refetch: (noToast?: boolean) => {
      refetch?.();
      if (user.role === 'fleetmax_csr') {
        fetchCsrData(propsData, noToast);
      } else {
        fetchVendorData(propsData);
      }
    },
    refetchPm: refetchPm?.(),
    checkList,
    preventive_request_id: data?.preventive_request_id || data?.id,
    setBulkCheckListView: (d: any) => setBulkCheckListView(d),
    setVendorModal,
    handleClose: handleCloseAll,
  };

  const requestInfoVendor = {
    ...data,
    refetch: (noToast?: boolean) => {
      refetch?.();
      if (user.role === 'fleetmax_csr') {
        fetchCsrData(propsData, noToast);
      } else {
        fetchVendorData(propsData);
      }
    },
    preventive_request_id: data?.preventive_request_id || data?.id,
    setBulkCheckListView: (d: any) => setBulkCheckListView(d),
    checkList,
    handleClose: handleCloseAll,
  };
  return (
    data && (
      <>
        <Modal handleClose={handleCloseAll} widthclass='w-[58vw]' heightClass='h-[70vh]' noLabel>
          {workProofData ? (
            <WorkProofData
              data={{
                checklistData: checkList || [],
                item: selectedVehicle,
              }}
              setWorkProofData={setWorkProofData}
              handleClose={handleCloseAll}
            />
          ) : (
            <>
              {showAssignVendor ? (
                <>
                  <AssignVendor
                    data={data}
                    handleClose={() => setShowAssignVendor(false)}
                    requestInfo={user.role === 'fleetmax_csr' ? requestInfoCSR : requestInfoVendor}
                  />
                </>
              ) : (
                <>
                  <div className='flex flex-col w-full h-full pr-2'>
                    <div className='flex gap-3 flex-1 h-0 w-full'>
                      <div className='h-full flex flex-1 flex-col w-1/2 gap-4 overflow-auto'>
                        {dispute?.open ? (
                          <DisputeLists item={selectedVehicle} />
                        ) : (
                          <>
                            <div className='flex flex-1 flex-col'>
                              <div className='flex gap-2 items-center mb-2'>
                                <p className='text-xl  text-primary font-bold'>
                                  {selectedVehicle?.id
                                    ? `Bulk-${selectedVehicle?.bulk_id}/${selectedVehicle.id}`
                                    : data.title}
                                </p>
                                {/* {item?.mileage_type === 'repair' && ( */}
                                {/* <div className='w-fit bg-primary-light text-primary px-2 rounded-full'>
                              Repair
                            </div> */}
                                {/* )} */}
                              </div>

                              <p className='text-xl mb-4'>{vehicles.length} Vehicles Added</p>
                              <div className='flex flex-col gap-3 overflow-auto h-0 flex-1 p-1 pr-2 '>
                                {vehicles.map((d: any, index: number) => {
                                  return (
                                    <div key={d}>
                                      <VehicleInfo
                                        item={d}
                                        active={getOpenedInfo(d)}
                                        setSelectedVehicle={() => {
                                          if (getOpenedInfo(d)) {
                                            setSelectedVehicle(null);
                                          } else {
                                            setSelectedVehicle(d);
                                          }
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <NotesPopup item={selectedVehicle} />
                            <WorkProofPreview
                              item={selectedVehicle}
                              checklistData={checkList}
                              setWorkProofData={setWorkProofData}
                            />
                            <RTSInspectionSummary item={selectedVehicle} />
                          </>
                        )}
                      </div>
                      <Divider orientation='vertical' sx={{ borderWidth: '1px', height: '100%' }} />
                      <div className='w-1/2 overflow-auto mt-9 pt-[-4rem]'>
                        {user.role === 'fleetmax_csr' && (
                          <div className='mt-[-1.3rem]'>
                            <CsrRequestDetail
                              requestInfo={requestInfoCSR}
                              selectedRequest={selectedVehicle}
                              props={{
                                showModel: noProofConfirmationModel,
                                setShowModel: setNoProofConfirmationModel,
                              }}
                            />
                          </div>
                        )}
                        {user.role !== 'fleetmax_csr' && (
                          <div className='mt-[-1.3rem]'>
                            <VendorRequestDetail
                              requestInfo={requestInfoVendor}
                              selectedRequest={selectedVehicle}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Modal>

        {checklistViewModal.show && (
          <ViewChecklist
            handleClose={() =>
              setChecklistViewModal({
                data: null,
                show: false,
              })
            }
            item={checklistViewModal.data}
          />
        )}
        {preview.open && (
          <ImagePreview
            imgArr={preview.data}
            handleClose={() => setPreview({ open: false, data: [] })}
          />
        )}
        {imageModal.open && (
          <ImageModal
            handleClose={() => {
              setImageModal({ open: false, data: {} });
            }}
            item={imageModal.data}
            handleMoveStage={handleMoveState}
          />
        )}
        {leasePlanModal.open && (
          <LeasePlan
            handleClose={() => setLeasePlanModal({ open: false, data: {} })}
            item={leasePlanModal.data}
            refetch={refetch}
          />
        )}
        {invoiceModal.open && (
          <InvoiceModal
            refetch={refetch}
            handleClose={() => setInvoiceModal({ open: false, data: {} })}
            invoiceData={invoiceModal.data?.updateVendorInvoice ? invoiceModal.data : data}
            selected={invoiceModal.data}
            handleMoveStage={handleMoveState}
          />
        )}

        {makePayment.open && (
          <Invoice
            handleClose={() => setMakePayment({ open: false, data: {} })}
            item={makePayment.data}
            fetchRequestData={() => {
              refetch?.();
              if (user.role === 'fleetmax_csr') {
                fetchCsrData(propsData);
              } else {
                fetchVendorData(propsData);
              }
            }}
          />
        )}

        {bulkCheckListView.open && (
          <BulkServices
            handleClose={() =>
              setBulkCheckListView({
                data: null,
                open: false,
              })
            }
            item={bulkCheckListView.data}
          />
        )}
        {viewinvoiceModal.open && (
          <ViewVendorInvoice
            handleClose={() => setViewInvoiceModal({ open: false, data: {} })}
            item={viewinvoiceModal.data}
          />
        )}

        {vendorModal.open && (
          <AssignVendorModal
            data={vendorModal.data}
            handleClose={() => setVendorModal({ open: false, data: {} })}
            refetch={refetch}
          />
        )}

        {assignTechnicianModal.open && (
          <Technician
            handleClose={() => setAssignTechnicianModal({ open: false, data: {} })}
            item={assignTechnicianModal.data}
            fetchRequestData={refetch}
          />
        )}

        {showCancelModel.open && (
          <ModelContainerCancel
            handleClose={() => setShowCancelModel({ open: false, data: {} })}
            item={showCancelModel.data}
            refetch={refetch}
          />
        )}
      </>
    )
  );
}

export default BulkInfoModel;

import { useSelector } from 'react-redux';
import CsrAPI from '../../../api/csrApi';
import { RootState } from '../../../state/reducers';
import { SinglePriorityStatus } from '../scheduleRequest/components/PriorityIcons';
import PrioritySelction from '../scheduleRequest/components/PrioritySelction';

function PriorityFeild({ item, width, height }: any) {
  const { user } = useSelector((state: RootState) => state.userState);
  const isVendor: boolean = user?.role === 'fleetmax_vendor';
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const priorityOnChange = (ele: any) => {
    if (+item?.priority !== +ele) {
      const payload = {
        bulk_requests: [
          { preventive_request_id: item?.preventive_request_id || item?.id, priority: ele },
        ],
      };
      CsrAPI.changePriority(payload).then((res) => {
        if (res.status === 200) {
          item?.refetch?.();
        }
      });
    }
  };
  if (
    (item?.status_id === '1' && item.request_type === 'manual_dsp') ||
    !item?.id ||
    !item?.preventive_request_id
  ) {
    return null;
  } else if (isVendor || isPartner) {
    return <SinglePriorityStatus priority={item?.priority || '2'} />;
  } else if (+item?.status_id <= 5) {
    return (
      <PrioritySelction
        data={item}
        width={width ? width : '5.2rem'}
        height={height ? height : '2rem'}
        onClick={priorityOnChange}
      />
    );
  } else return <SinglePriorityStatus priority={item?.priority || '2'} />;
}

export default PriorityFeild;

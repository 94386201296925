import { Button, Checkbox, styled, Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InvoiceSvg from '../../../assets/InvoiceSvg';
import Menu from '../../../components/DropDowns/Menu';
import { RootState } from '../../../state/reducers';
import { SortUpdated, updateBulkModalData, updateModalData } from '../../common/SortData';
import Table from '../../common/Table';
import { vehicleInfo } from '../../common/VehicleInfo';
import ViewInvoice from '../../common/ViewInvoice';
import Logs from '../../common/requestDetail/MaintainanceLogsModel';
import useFetchSingleRequest from '../../hooks/csr/useFetchSingleRequest';
import BulkInfoModel from '../bulkInfoModal';
import { UpdatedAtTime } from '../primaryComponents/AppointmentTime';
import { RequestViewBtn } from '../primaryComponents/InProcessFilter';
import InvoiceModal from '../primaryComponents/Invoice';
import LeasePlan from '../primaryComponents/LeasePlan';
import Request from '../primaryComponents/RequestModal';
import ServiceLocation from '../primaryComponents/ServiceLocation';
import TillMiles from '../primaryComponents/TillMiles';
import Invoice from '../primaryComponents/ViewInvoice';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import useFetchStations from '../../hooks/useFetchStations';
import Tootltip from '../../../components/BarData/Tootltip';
import PriorityIcons from '../scheduleRequest/components/PriorityIcons';
import CustomTooltipButton from './CustomTooltipButton';
import useTooltipData from './useTooltipData';
export const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: #0000008f;
    color: white;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 0.95rem;
    border-radius: 0.375rem;
  }
`;
const Complete = ({ completeData, refetch }: any) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const { stations } = useFetchStations();
  const { setMoveToConfirmed } = bindActionCreators(actionCreators, dispatch);
  const [requestModal, setRequestModal] = useState({ open: false, data: {} });
  const [invoiceModalEdit, setInvoiceModalEdit] = useState({ open: false, data: {} });
  const [invoiceModal, setInvoiceModal] = useState({ open: false, data: {} });
  const [leasePlanModal, setLeasePlanModal] = useState({ open: false, data: {} });
  const [bulkInfoModal, setBulkInfoModal] = useState({ open: false, data: {} } as any);
  const { user, redirectRequest, moveToConfirmed }: any = useSelector(
    (state: RootState) => state.userState
  );
  const { fetchRequestData }: any = useFetchSingleRequest();
  useEffect(() => {
    filterStatusCode('', true);
  }, [completeData]);
  const { tooltipData, fetchTooltipData } = useTooltipData();
  const menuHeads = (item: any) => {
    const isPending = item?.lease_plan?.approved_from ? false : true;
    const commonOptions = [
      {
        head: 'View Invoices',
        event: async () => {
          setInvoiceModal({ open: true, data: item });
        },
      },
      {
        head: !item?.lease_plan ? 'Lease Plan Update' : 'View Lease Plan',
        event: async () => {
          setLeasePlanModal({ open: true, data: item });
        },
      },
    ];
    commonOptions.push({
      head: 'Confirm Payment',
      event: async () => {
        setMoveToConfirmed({
          show: true,
          schedules: [],
        });
      },
    });
    return {
      isPending,
      options: [...commonOptions],
    };
  };
  const filterStatusCode = useCallback(
    (status: any, all: boolean = false) => {
      const filteredData: any[] = [];
      const map = new Map();
      const singleReqMap = new Map();
      const bulk_map = new Map();
      completeData.forEach(
        (elem: {
          status_id: string;
          dsp: { dsp_short_code: string };
          id: string;
          sub_stage: string;
          bulk_id: string;
        }) => {
          map.set(elem.id, elem);
          if (elem?.bulk_id) {
            bulk_map.set(elem?.bulk_id, elem);
          } else {
            singleReqMap.set(elem.id, elem);
          }
          if (all) {
            filteredData.push(elem);
          }
        }
      );
      if (
        (bulkInfoModal?.open || requestModal?.open || invoiceModalEdit?.open) &&
        (Object.keys(redirectRequest || {}).length === 0 || !redirectRequest)
      ) {
        updateBulkModalData(
          bulk_map,
          bulkInfoModal,
          setBulkInfoModal,
          singleReqMap,
          setRequestModal
        );
        updateModalData(map, requestModal, setRequestModal);
        updateModalData(map, invoiceModalEdit, (updated: any) => {
          setInvoiceModalEdit({
            ...updated,
            data: {
              ...invoiceModalEdit?.data,
              ...updated?.data,
            },
          });
        });
      }

      setData(SortUpdated(filteredData));
    },
    [completeData, bulkInfoModal, requestModal, invoiceModalEdit]
  );

  useEffect(() => {
    if (redirectRequest && Object.keys(redirectRequest || {}).length > 0) {
      const redirectedData = {
        id: redirectRequest?.preventive_request_id,
        bulk_id: redirectRequest?.bulk_id,
        preventive_request_id: redirectRequest?.preventive_request_id,
      };
      if (redirectRequest?.bulk_id && redirectRequest?.bulk_modal) {
        setBulkInfoModal({
          open: true,
          data: redirectedData,
        });
      } else {
        fetchRequestData(redirectedData).then((res: any) => {
          if (res) {
            setRequestModal({
              open: true,
              data: res,
            });
          }
        });
      }
    }
  }, [redirectRequest]);
  const headerValue = useMemo(
    () =>
      [
        moveToConfirmed?.show ? [(item: any) => <p className='w-[2rem]'></p>] : [],
        [
          (item: any) => <p className='w-[8rem] '></p>,
          (item: any) => 'showDspShortCode',
          (item: any) => <p className='w-[6rem]'>Van Name</p>,
          (item: any) => <p className='w-[0.5rem]'></p>,
          (item: any) => <p className='w-[12rem]'>VIN</p>,
          (item: any) => <p className='w-[6rem]'>Last PM</p>,
          (item: any) => <p className='w-[8.3rem]'>Miles Till Inspection</p>,
          (item: any) => <p className='w-[8em]'>Current Mileage</p>,
          (item: any) => <p className='w-[8rem]'>Plate Number</p>,
        ],
      ].flat(),
    [stations, moveToConfirmed]
  );

  const dataVisibleContentValue = useMemo(
    () =>
      [
        moveToConfirmed && moveToConfirmed?.show
          ? [
              (item: any, index: number) => (
                <Checkbox
                  checked={!!moveToConfirmed?.schedules?.find((d: any) => +d?.id === +item?.id)}
                  onClick={() => {
                    const isItemPresent = moveToConfirmed?.schedules?.some(
                      (d: any) => +d?.id === +item?.id
                    );

                    if (isItemPresent) {
                      const updatedSchedules = moveToConfirmed?.schedules?.filter(
                        (d: any) => +d?.id !== +item?.id
                      );
                      setMoveToConfirmed({
                        ...moveToConfirmed,
                        schedules: updatedSchedules,
                      });
                    } else {
                      setMoveToConfirmed({
                        ...moveToConfirmed,
                        schedules: [...moveToConfirmed?.schedules, item],
                      });
                    }
                  }}
                />
              ),
            ]
          : [],
        (item: any, number: number) => {
          return (
            <div className='flex items-center gap-3  w-[15rem]'>
              <div className='w-[2rem] h-[2rem]'>
                <PriorityIcons iconType={item?.priority || '2'} />
              </div>
              {item.status_id === '12' && (
                <Tooltip title='Payment Completed'>
                  <div className='w-[3.5rem] bg-green-200 flex items-center justify-center h-[2rem] rounded-[0.6rem]'>
                    <i className='pi pi-dollar' style={{ fontSize: '1rem' }}></i>
                  </div>
                </Tooltip>
              )}
           
                {item?.bulk_id ? (
                  <CustomTooltipButton
                    key={item?.bulk_id}
                    item={item}
                    tooltipData={tooltipData}
                    setBulkInfoModal={setBulkInfoModal}
                    setRequestModal={setRequestModal}
                    fetchTooltipData={fetchTooltipData}
                    RequestViewBtn={RequestViewBtn}
                  />
                ) : (
                  <div className='flex flex-col'>
                    {RequestViewBtn(
                      item,
                      () => {
                        item?.bulk_id &&
                          setBulkInfoModal({
                            open: true,
                            data: item,
                          });
                        !item?.bulk_id &&
                          setRequestModal({
                            open: true,
                            data: item,
                          });
                      },
                      () => {}
                    )}
                  </div>
                )}
        
            </div>
          );
        },
        (item: any) => <p className='w-[10rem]'>{item.dsp.station_code}</p>,
        (item: any) => (
          <p className='w-[6rem]'>
            <Tootltip title={item?.vehicle?.name} placement={'bottom'}>
              <p
                className='max-w-[6rem] text-ellipsis overflow-hidden'
                style={{ whiteSpace: 'nowrap' }}>
                {item?.vehicle?.name}
              </p>
            </Tootltip>
          </p>
        ),
        (item: any) => <hr className='rotate-90 border-slate-400' />,
        (item: any) => <p className='w-[12rem]'>{item?.vehicle?.vin}</p>,
        (item: any) => <p className='w-[6rem]'>{item?.miles || '---'}</p>,
        TillMiles,
        (item: any) => <p className='w-[5rem]'>{item?.curr_miles || '---'}</p>,
        (item: any) => <p className='w-[8rem]'>{item?.vehicle?.plate}</p>,
        (item: any, number: any) => {
          const { options } = menuHeads(item);
          return !moveToConfirmed.show && <Menu options={options} />;
        },
      ].flat(),
    [stations, moveToConfirmed, tooltipData]
  );
  const requestModalData = useMemo(
    () => ({
      body1: vehicleInfo,
      body2: [
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>CSR</p>
            <p>{user?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>DSP</p>
            <p>{item?.dsp?.name}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Vendor</p>
            <p className='capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => (
          <div>
            <p className='text-sm text-slate-400'>Notes</p>
          </div>
        ),
        (item: any) => ServiceLocation(item),
      ],
      body3: [
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Assigned Vendor</aside>
            <p className='text-lg capitalize'>{item?.vendor?.name || '---'}</p>
          </div>
        ),
        (item: any) => <div></div>,
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>DSP Cost</aside>
            <p className='text-lg'>$0</p>
          </div>
        ),
        (item: any) => (
          <div>
            <aside className='text-sm text-stone-400'>Vendor Cost</aside>
            <p className='text-lg'>{`$ ${item?.final_payment}` || '---'}</p>
          </div>
        ),
      ],
      body5: [
        (item: any) => (
          <div className='w-full flex justify-center'>
            <button className='border-none py-1.5 px-6 bg-green-100 text-green-500 rounded-lg flex items-center justify-center gap-2'>
              <i className='pi pi-check-circle'></i>
              Payment Processed
            </button>
          </div>
        ),
      ],
      body6: [
        (item: any) => (
          <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
            <div className='flex justify-between'>
              <ViewInvoice
                item={item}
                component={
                  <Button
                    variant='text'
                    startIcon={<InvoiceSvg />}
                    className='py-1 px-4  text-blue-600 border border-blue-600 rounded-xl font-bold bg-white h-full'>
                    {'View Invoice'}
                  </Button>
                }
              />
            </div>
            <Logs item={item} type='single' />
          </div>
        ),
      ],
      status: [
        (item: any) => (
          <div className={`px-3 py-2 bg-green-200 rounded-lg flex justify-between items-center`}>
            <p>Completed</p>
            <p>
              <UpdatedAtTime item={item} />
            </p>
          </div>
        ),
      ],
    }),
    []
  );
  const updatedData = useMemo(() => {
    return {
      ...requestModal.data,
      setInvoiceModal: setInvoiceModalEdit,
    };
  }, [requestModal.data]);
  useEffect(() => {
    setMoveToConfirmed({
      ...moveToConfirmed,
      schedules: [],
    });
  }, []);
  return (
    <>
      <Table
        data={data}
        havebulkrow
        header={headerValue}
        dataVisibleContent={dataVisibleContentValue}
        bulkrowformat={dataVisibleContentValue}
      />
      {requestModal.open && (
        <Request
          handleClose={() => setRequestModal({ open: false, data: {} })}
          item={updatedData}
          body1={requestModalData.body1}
          body2={requestModalData.body2}
          body3={requestModalData.body3}
          body5={requestModalData.body5}
          body6={requestModalData.body6}
          status={requestModalData.status}
          type={'complete'}
          refetch={refetch}
        />
      )}
      {bulkInfoModal.open && (
        <BulkInfoModel
          handleClose={() => setBulkInfoModal({ open: false, data: {} })}
          data={bulkInfoModal?.data}
          refetch={refetch}
        />
      )}
      {invoiceModal.open && (
        <Invoice
          handleClose={() => setInvoiceModal({ open: false, data: {} })}
          item={invoiceModal.data}
        />
      )}

      {leasePlanModal.open && (
        <LeasePlan
          handleClose={() => setLeasePlanModal({ open: false, data: {} })}
          item={leasePlanModal.data}
          refetch={refetch}
        />
      )}
      {invoiceModalEdit.open && (
        <InvoiceModal
          refetch={refetch}
          handleClose={() => {
            setInvoiceModalEdit({ open: false, data: {} });
          }}
          invoiceData={invoiceModalEdit.data}
        />
      )}
    </>
  );
};

export default Complete;

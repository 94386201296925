import { useEffect, useMemo, useRef, useState } from 'react';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import { Button } from '@mui/material';
import moment from 'moment';
import LocationInfo from '../../Schedule/LocationPicker';
import useFetchVendorServices from '../../../hooks/useFetchVendorServices';
import useFetchCheckList from '../../../hooks/useFetchCheckList';
import CsrAPI from '../../../../api/csrApi';
import { toast } from 'react-toastify';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import ServiceLocation from '../../primaryComponents/ServiceLocation';
import { AddNewServicesModel } from '../../primaryComponents/AddNewServices';
import { useFetchNotesRemarks } from '../../../hooks/useFetchNotesRemarks';
import { SortCreated } from '../../../common/SortData';
import PrioritySelction from '../../scheduleRequest/components/PrioritySelction';
import Tootltip from '../../../../components/BarData/Tootltip';
import { createServices } from '../../scheduleRequest/components/createServices';
import { RootState } from '../../../../state/reducers';
import { useSelector } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';

function EditRequestModelBulk({ handleClose, item }: { handleClose: any; item: any }) {
  const loader = HandleLoad();
  const { getCheckList, updatecheckListBulk } = useFetchCheckList();
  const [visibleList, setVisibleList] = useState<any>(item?.bulkSchedules[0] || []);
  const [serviceLocationData, setServiceLocationData] = useState<any>(null);
  const { services, fetchVenderServices } = useFetchVendorServices();
  const [checklistData, setChecklistData] = useState<any[]>([]);
  const [showDeleteModel, setShowDeleteModel] = useState<any>({ show: false, data: [] });
  const [checkedItemsMap, setCheckedItemsMap] = useState<{ [key: string]: string[] }>({});
  const checkedItemsMapRef = useRef<{ [key: string]: string[] }>({});
  const [typeValue, setTypeValue] = useState('');
  const [addnewService, setAddNewService] = useState<any>({ show: false, data: [] });
  const [priorities, setPriorities] = useState<any>([]);
  const [closeConFirmationModel, setCloseConFirmationModel] = useState(false);
  const { getRemakrs, remakrs }: any = useFetchNotesRemarks();
  const state: any = useSelector((state: RootState) => state.userState);
  useEffect(() => {
    getRemakrs(item);
  }, []);
  const latestNote: string = useMemo(() => {
    return remakrs?.length > 0
      ? remakrs
          ?.slice()
          ?.sort(
            (a: any, b: any) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
          )
          .find((el: any) =>
            [
              'schedule_appointment_to_vendor',
              'reschedule_appointment',
              'schedule_appointment',
              'assign_vendor',
              'remove_vendor',
              'schedule_appointment_to_partner',
              'assign_partner',
              'remove_partner',
            ].includes(el.remark_source)
          )?.remark || ''
      : '';
  }, [remakrs]);
  const [textFieldValue, setTextFieldValue] = useState('');
  useEffect(() => {
    if (latestNote?.length > 0) setTextFieldValue(latestNote);
  }, [latestNote]);
  const handleRemoveSchedule = (scheduleIdToRemove: any) => {
    loader(true);
    CsrAPI.removeVehicle({
      preventive_request_id: parseInt(
        scheduleIdToRemove?.id || scheduleIdToRemove?.preventive_request_id,
        10
      ),
      bulk_id: parseInt(scheduleIdToRemove?.bulk_id, 10),
      note: typeValue,
      ...(+item?.status_id === -1 && { edit_partner: true }),
    }).then((res) => {
      if (res.status === 200) {
        toast.success(`Vehicle Removed Successfully`);
        setShowDeleteModel({ show: false, data: [] });
        item?.refetch();
        setVisibleList(item?.bulkSchedules[0] || []);
        setTypeValue('');
        setCheckedItemsMap((prevMap) => {
          const updatedMap = { ...prevMap };
          delete updatedMap[scheduleIdToRemove?.id || scheduleIdToRemove?.preventive_request_id];
          return updatedMap;
        });
      } else {
        toast.error('something went wrong');
      }
      loader(false);
    });
  };
  const handleCheckboxChange = (item: any, scheduleId: string) => {
    setCheckedItemsMap((prevMap) => {
      const currentCheckedItems = checkedItemsMapRef.current[scheduleId] || [];
      const updatedCheckedItems = currentCheckedItems.includes(item)
        ? currentCheckedItems.filter((checkedItem) => checkedItem !== item)
        : [...currentCheckedItems, item];
      checkedItemsMapRef.current[scheduleId] = updatedCheckedItems;
      return {
        ...prevMap,
        [scheduleId]: updatedCheckedItems,
      };
    });
  };
  const bulkRequestsData = useMemo(() => {
    const data = [];
    for (const bulkRequestId in checkedItemsMap) {
      if (checkedItemsMap.hasOwnProperty(bulkRequestId)) {
        const checklist = checkedItemsMap[bulkRequestId];
        const filteredChecklist = checklist.filter((item) => item !== null);
        priorities.find(
          (priorityItem: any) =>
            priorityItem.id === bulkRequestId ||
            priorityItem.preventive_request_id === bulkRequestId
        );
        if (filteredChecklist.length > 0) {
          const bulkRequestObject = {
            preventive_request_id: bulkRequestId,
            checklist: createServices(filteredChecklist),
          };
          data.push(bulkRequestObject);
        }
      }
    }
    return data;
  }, [checkedItemsMap, priorities]);
  const priority = useMemo(() => {
    const data = [];
    for (const bulkRequestId in checkedItemsMap) {
      if (checkedItemsMap.hasOwnProperty(bulkRequestId)) {
        const priorityObject = priorities?.find(
          (priorityItem: any) =>
            priorityItem?.id === bulkRequestId ||
            priorityItem?.preventive_request_id === bulkRequestId
        );
        const initialPriority =
          item?.bulkSchedules?.find((schedule: any) => schedule?.id === bulkRequestId)?.priority ||
          '2';
        if (priorityObject?.priority !== initialPriority) {
          const bulkRequestObject = {
            preventive_request_id: bulkRequestId,
            priority: priorityObject?.priority || '2',
          };
          data.push(bulkRequestObject);
        }
      }
    }
    return data;
  }, [checkedItemsMap, priorities, item]);

  const handleSaveChanges = async () => {
    const payload = {
      bulk_requests: bulkRequestsData,
      service_location: serviceLocationData,
      ...(textFieldValue?.length > 0 && { note: textFieldValue }),
      ...(item?.bulk_id && { bulk_id: parseInt(item.bulk_id, 10) }),
      ...(+item?.status_id === -1 && { edit_partner: true }),
    };

    const priorityPayload = {
      bulk_requests: priority,
    };

    const allPrioritiesMatch = item.bulkSchedules.every((schedule: any, index: number) => {
      return schedule.priority === priorities[index]?.priority;
    });

    try {
      loader(true);

      const apiCalls = [CsrAPI.editRequest({ payload })];

      if (!allPrioritiesMatch) {
        apiCalls.push(CsrAPI.changePriority(priorityPayload));
      }

      const responses = await Promise.all(apiCalls);

      let success = true;

      const editResponse = responses?.[0];
      if (editResponse.status === 200) {
        toast.success('Updated successfully');
        updatecheckListBulk(item);
        handleClose();
      } else {
        toast.error('Something went wrong while updating the request');
        success = false;
      }

      if (!allPrioritiesMatch) {
        const priorityResponse = responses[1];
        if (priorityResponse.status !== 200) {
          toast.error('Something went wrong while changing priority');
          success = false;
        }
      }

      if (success) {
        item?.refetch?.();
      }
    } catch (error) {
      toast.error('An error occurred');
      console.error(error);
    } finally {
      loader(false);
    }
  };

  const arraysAreEqual = (array1: any, array2: any) => {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  };
  const handleCloseModel = () => {
    if (item && item.bulkSchedules) {
      const allChecklistsChecked = item.bulkSchedules.every((schedule: any) => {
        const requestId = schedule?.id || schedule?.preventive_request_id;
        const checklists = schedule.checklists.map((item: any) => item.name);
        const checkedItems = checkedItemsMap[requestId];
        return arraysAreEqual(checklists, checkedItems);
      });
      const allPrioritiesMatch = item.bulkSchedules.every((schedule: any, index: number) => {
        return schedule.priority === priorities[index]?.priority;
      });
      if (
        allChecklistsChecked &&
        allPrioritiesMatch &&
        ServiceLocation(item) === serviceLocationData?.locationInfo &&
        textFieldValue === latestNote
      ) {
        handleClose();
      } else {
        setCloseConFirmationModel(true);
      }
    }
  };
  useEffect(() => {
    getCheckList(item);
    fetchVenderServices({
      vendor_id: visibleList?.vendor?.id || visibleList?.partner?.id,
      vendor_account_id: '',
      vendor_name: '',
      techicians: [{ user_id: '', manager_id: '', name: '', email: '' }],
    });
  }, []);
  useEffect(() => {
    setChecklistData(services);
  }, [services]);
  const notAvailableForThisVendor = useMemo(() => {
    const result: { [key: string]: string[] } = {};
    Object.keys(checkedItemsMap).forEach((key) => {
      const checkedItems = checkedItemsMap?.[key];
      const unavailableItems = checkedItems?.filter((item) => !services?.includes(item));
      if (unavailableItems.length > 0) {
        result[key] = unavailableItems;
      }
    });
    return result;
  }, [checkedItemsMap, services]);

  // to save data when the visible data renders
  useEffect(() => {
    if (visibleList) {
      if (!checkedItemsMapRef.current[visibleList?.id]) {
        const initialCheckedItems =
          visibleList.checklists.map((checklist: any) => checklist?.name) || [];
        checkedItemsMapRef.current[visibleList.id] = initialCheckedItems;
        setCheckedItemsMap((prevMap) => ({
          ...prevMap,
          [visibleList.id]: initialCheckedItems,
        }));
      }
    }
  }, [visibleList]);
  // to save the previous checklist when the component is rendered
  useEffect(() => {
    item.bulkSchedules.forEach((schedule: any) => {
      const scheduleId = schedule.id;
      if (!checkedItemsMapRef.current[scheduleId]) {
        const initialCheckedItems =
          schedule.checklists.map((checklist: any) => checklist?.name) || [];
        checkedItemsMapRef.current[scheduleId] = initialCheckedItems;
        setCheckedItemsMap((prevMap) => ({
          ...prevMap,
          [scheduleId]: initialCheckedItems,
        }));
      }
    });
  }, [item?.bulkSchedules]);
  useEffect(() => {
    if (!serviceLocationData && remakrs) {
      const latestServiceLocationRemark = remakrs
        ? SortCreated(remakrs).find((el: any) =>
            ['service_location', 'schedule_appointment_bulk_location'].includes(el.remark_source)
          )?.remark
        : null;
      if (latestServiceLocationRemark) {
        setServiceLocationData(latestServiceLocationRemark);
      }
    }
  }, [item, serviceLocationData]);
  useEffect(() => {
    if (!item?.bulk_id && !item?.bulkSchedules?.[0]?.bulk_id) {
      setVisibleList(item?.bulkSchedules?.[0]);
    }
    const updatedBulkSchedules = item?.bulkSchedules.map((schedule: any) => ({
      ...schedule,
      priority: schedule?.priority || '2',
    }));
    setPriorities(updatedBulkSchedules);
  }, [item?.bulkSchedules]);
  console.log(notAvailableForThisVendor);
  return (
    <OverlayContainer show={true}>
      <ModalForm title={''} onClose={handleCloseModel} width='w-[70rem]' noLabel>
        <>
          <div className='w-full h-[43rem] pb-3'>
            <p className='text-[1.5rem] '>Edit Request</p>
            <p className='text-primary text-[1.2rem] font-bold'>
              {' '}
              {item?.bulk_id ? `Bulk-${item?.bulk_id}` : `Request-${item?.id || ''}`}
            </p>
            <div className='w-full h-[37rem] flex justify-between mt-3 gap-3 mb-5'>
              <div className='w-1/3 flex flex-col justify-between gap-5 pr-3 border-r-[0.1rem] border-gray-300'>
                <div className='flex flex-col gap-5'>
                  {item?.bulkSchedules?.map((ele: any, index: number) => {
                    return (
                      <div
                        key={ele}
                        style={
                          ele === visibleList
                            ? {
                                boxShadow: '0px 6px 5px -4px rgba(0, 0, 0, 0.11)',
                              }
                            : {}
                        }
                        className={`w-full  rounded-[0.7rem] ${
                          ele === visibleList
                            ? 'border-[0.3rem] border-primary-light'
                            : 'border-[0.1rem] border-gray-300'
                        }`}>
                        <Button
                          key={ele}
                          className={`w-full  flex justify-between text-[1.1rem] font-bold ${
                            ele === visibleList ? 'text-primary' : 'text-black '
                          } py-2  rounded`}
                          onClick={() => setVisibleList(ele)}>
                          <Tootltip title={ele?.vehicle?.name} placement={'bottom'}>
                            <p className='max-w-[10rem] text-ellipsis overflow-hidden whitespace-nowrap text-[1.1rem] font-bold'>
                              {ele?.vehicle?.name}
                            </p>
                          </Tootltip>
                          <div className='flex items-center gap-2'>
                            <PrioritySelction
                              data={priorities?.[index]}
                              onClick={(ele: any) => {
                                setPriorities((prevPriorities: any) =>
                                  prevPriorities.map((obj: any, idx: number) => {
                                    if (idx === index) {
                                      return {
                                        ...obj,
                                        priority: ele,
                                      };
                                    }
                                    return obj;
                                  })
                                );
                              }}
                              width={'7rem'}
                              // disabled={ele?.id !== visibleList?.id}
                            />
                            {ele?.bulk_id && (
                              <i
                                className='pi pi-minus-circle'
                                onClick={() => {
                                  setShowDeleteModel({ show: true, data: ele });
                                }}></i>
                            )}
                          </div>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='w-1/3 pl-1 pr-3 overflow-auto '>
                {state?.loading ? (
                  <div className='flex items-center justify-center w-full h-full'>
                    <ThreeDots
                      height='10'
                      width='50'
                      radius='9'
                      color={'#4378C8'}
                      ariaLabel='three-dots-loading'
                      wrapperStyle={{}}
                      visible={true}
                    />
                  </div>
                ) : (
                  <>
                    {notAvailableForThisVendor?.[visibleList?.id]?.length > 0 &&
                      notAvailableForThisVendor?.[visibleList?.id]?.map((ele, index) => (
                        <div
                          key={ele}
                          className={`w-full border-[0.1rem] rounded-xl flex p-3 mb-2 cursor-pointer bg-gray-100`}>
                          <input type='checkbox' disabled checked={true} />
                          <p className='ml-2 capitalize'>{ele}</p>
                        </div>
                      ))}
                    {checklistData &&
                      checklistData.map((ele, index) => (
                        <div
                          key={ele}
                          className={`w-full border-[0.1rem] rounded-xl flex p-3 mb-2 cursor-pointer`}
                          onClick={() => handleCheckboxChange(ele, visibleList.id)}>
                          <input
                            type='checkbox'
                            checked={checkedItemsMap[visibleList.id]?.includes(ele)}
                          />
                          <p className='ml-2 capitalize'>{ele}</p>
                        </div>
                      ))}
                  </>
                )}
              </div>
              <div className='w-1/3 h-[37rem] px-3 flex flex-col gap-6 justify-between pl-5 border-l-[0.1rem] border-gray-300'>
                <LocationInfo
                  item={item}
                  serviceLocationData={serviceLocationData}
                  saveLocationData={setServiceLocationData}
                />
                <div className='w-full border-[0.1rem] border-gray-300 rounded-[0.5rem]'>
                  <textarea
                    style={{ resize: 'none' }}
                    className='w-full h-[7rem] rounded-[1rem] pl-2 p-3 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                    value={textFieldValue || ''}
                    onChange={(e: any) => {
                      setTextFieldValue(e.target.value);
                      e.preventDefault();
                    }}
                    placeholder='Add Notes'
                  />
                </div>
                <div className='h-[20rem] flex flex-col'>
                  <Button
                    className='font-bold hover:bg-primary-light'
                    onClick={() => {
                      setAddNewService({
                        show: true,
                      });
                    }}>
                    + Add Services
                  </Button>
                </div>
                <div className='w-full h-[4rem] flex justify-center '>
                  <Button
                    disabled={
                      serviceLocationData?.coordinates === null ||
                      Object?.values(checkedItemsMap)?.some((items) => items?.length === 0)
                    }
                    className='w-3/4 bg-primary text-[1.1rem] text-white flex items-center  disabled:bg-gray-400'
                    variant='contained'
                    onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {showDeleteModel.show && (
            <DeleteConfirmationModal
              handleClose={() => setShowDeleteModel({ show: false, data: [] })}
              handleRemoveSchedule={handleRemoveSchedule}
              showDeleteModel={showDeleteModel}
              setTypeValue={setTypeValue}
              typeValue={typeValue}
            />
          )}
          {closeConFirmationModel && (
            <CloseConfirmationModel
              handleClose={() => {
                setCloseConFirmationModel(false);
              }}
              handleCloseAll={() => {
                handleClose();
                setCloseConFirmationModel(false);
              }}
              handleSaveChanges={handleSaveChanges}
            />
          )}
          {addnewService.show && (
            <AddNewServicesModel
              handleClose={() => {
                setAddNewService({
                  show: false,
                });
              }}
            />
          )}
        </>
      </ModalForm>
    </OverlayContainer>
  );
}
export default EditRequestModelBulk;

const DeleteConfirmationModal = ({
  handleClose,
  handleRemoveSchedule,
  showDeleteModel,
  typeValue,
  setTypeValue,
}: {
  handleClose: any;
  handleRemoveSchedule: any;
  showDeleteModel: any;
  typeValue: any;
  setTypeValue: any;
}) => {
  return (
    <OverlayContainer show={true}>
      <ModalForm title={''} onClose={handleClose} width='w-[25rem]' noLabel>
        <>
          <div className='flex flex-col justify-center items-center p-2 px-4 gap-4'>
            <p className='text-[1.3rem] text-primary'>
              {' '}
              Remove Vehicle : {showDeleteModel?.data?.vehicle?.name}
            </p>
            <p className='break-words max-w-[20rem] text-[1.1rem] text-gray-400'>
              This Vehicle will be treated as rejected, Please Provide a Reason for removing
            </p>
            <div className='w-full border-[0.1rem] border-gray-300 rounded-[0.5rem]'>
              <textarea
                style={{ resize: 'none' }}
                className='w-full h-[10rem] rounded-[1rem] pl-2 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                value={typeValue}
                onChange={(e) => {
                  e.preventDefault();
                  setTypeValue(e.target.value);
                }}
                placeholder='Type your reason'
              />
            </div>
            <div className='flex justify-between gap-10'>
              <div className='border-[0.1rem]  border-primary rounded-xl'>
                <Button className='px-10 text-[1.05rem]' onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              <Button
                color='primary'
                className='bg-primary px-10 text-[1.05rem] text-white disabled:bg-gray-400'
                disabled={typeValue?.length < 1}
                onClick={() => handleRemoveSchedule(showDeleteModel.data)}>
                Remove
              </Button>
            </div>
          </div>
        </>
      </ModalForm>
    </OverlayContainer>
  );
};
export const CloseConfirmationModel = ({
  handleClose,
  handleCloseAll,
  handleSaveChanges,
}: {
  handleClose: any;
  handleCloseAll: any;
  handleSaveChanges: any;
}) => {
  return (
    <OverlayContainer show={true}>
      <ModalForm title={''} onClose={handleClose} width='w-[25rem]' noLabel>
        <>
          <div className='flex w-full flex-col gap-4'>
            <p className='flex justify-center text-[1.5rem]'> Changes not saved </p>
            <div className='break-words text-gray-400 w-[20rem] text-[1.2rem] '>
              <p className='flex justify-center items-center'>You have unsaved changes. Do you</p>
              <p className='flex justify-center items-center'> want to save before exiting?</p>
            </div>
            <div className='flex justify-between px-6'>
              <Button
                className='text-primary text-[1.1rem] h-[2.7rem] hover:bg-primary-light'
                onClick={handleCloseAll}>
                Don't Save
              </Button>
              <Button
                className='bg-primary text-white text-[1.1rem] h-[2.7rem] w-[7rem]'
                onClick={handleSaveChanges}>
                Save
              </Button>
            </div>
          </div>
        </>
      </ModalForm>
    </OverlayContainer>
  );
};

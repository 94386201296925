import { IFile, UserModel, UserStateModel } from '../../models/UserModel';
import { TableRow } from '../../pages/partner/primaryComponents/types/types';
import { ActionType } from '../action-types';

let user: UserModel | null = null;

const getItem: string | null = localStorage.getItem('user');

if (getItem) user = JSON.parse(getItem);

const initialState: UserStateModel = {
  user: user,
  loading: false,
  clients: [],
  not_assigned_csr_clients: [],
  not_assigned_vendor_technician: [],
  screenType: 'CSR',
  pmData: [],
  scheduleData: [],
  rejectedDataCSR: [],
  inProcessData: [],
  completeData: [],
  requestData: [],
  assignedData: [],
  approvedData: [],
  acceptedData: [],
  rejectedData: [],
  technicianData: [],
  dspVendorData: [],
  redirectRequest: {},
  csrDataInfo: {},
  vendorDataInfo: {},
  requestServices: {},
  chatHeads: [],
  chatMessages: [],
  selectedChat: {},
  chatListType: 'all',
  chatListUsers: [],
  chatListUsersVendor: [],
  roomId: [],
  oneToOneChatMap: new Map(),
  chatLoader: false,
  selectedCatagoryChat: 'all',
  unreadMessagesCount: [],
  chatProfileDetail: {},
  appointmentTime: [],
  socketFleetMax: {},
  refetchData: [],
  moveToCompleteData: [],
  groupUsersDetails: new Map(),
  bulkRequestDetails: { bulkSchedules: [] },
  inspectionData: [],
  inspectionDataVendor: [],
  vehicleHistory: {},
  newQuestion: '',
  inspectRefetch: [],
  currentSortType: '',
  layoutContents: [],
  moveToConfirmed: [{ show: false, schedules: [] }],
  assignTechBulk: [{ show: false, schedules: [] }],
  servicesList: [{ show: false, services: [] }],
  vendorDetails: [],
  dispute: [],
  attachments: [] as IFile[],
  selectedAssignVendor: [],
  selectedAssignTech: [],
  selectedAssignPartner: [],
  requestModal: {
    open: false,
    data: null,
  },
  multiSelectReq: {
    show: false,
    data: null,
  },
  multiSelectInvoiceItems: [] as TableRow[],
  bulkRequestData: null,
  selectedDSPSLot: null,
  acceptedDataVendor: null,
  chatHeadsLoader: false,
  invoiceView: {},
  downloadView: {},
  customizeView: false,
  userInfoInvoice: {},
  alertsOffset: 0,
  filterType: '',
  pendingVehicles: [],
  requestLoader: false,
  invoiceLoader: false,
  partnerVendors: [],
};

const reducer = (state: UserStateModel = initialState, action: any): UserStateModel => {
  switch (action.type) {
    case ActionType.SET_USER:
      return { ...state, user: action.payload, loading: false };
    case ActionType.DELETE_USER:
      return { ...state, user: null, loading: false };
    case ActionType.USER_LOADER:
      return { ...state, loading: action.payload };
    case ActionType.SET_CLIENTS:
      return { ...state, clients: action.payload };
    case ActionType.SET_CSR_CLIENTS:
      return { ...state, not_assigned_csr_clients: action.payload };
    case ActionType.SET_VENDOR_TECHNICIAN:
      return { ...state, not_assigned_vendor_technician: action.payload };
    case ActionType.SET_CSR_DATA:
      return { ...state, ...action.payload };
    case ActionType.SET_VENDOR_DATA:
      return { ...state, ...action.payload };
    case ActionType.SET_VENDOR_ASSIGN_TECHNICIAN_DATA:
      return { ...state, ...action.payload };
    case ActionType.SET_DSP_ASSIGN_VENDOR_DATA:
      return { ...state, ...action.payload };
    case ActionType.SET_VIEW_SCREEN:
      return { ...state, screenType: action.payload };
    case ActionType.SET_REDIRECT_REQUEST:
      return { ...state, redirectRequest: action.payload };
    case ActionType.SET_CSR_DATA_INFO:
      return { ...state, csrDataInfo: action.payload };
    case ActionType.SET_VENDOR_DATA_INFO:
      return { ...state, vendorDataInfo: action.payload };
    case ActionType.SET_SERVICES_INFO:
      return { ...state, requestServices: action.payload };
    case ActionType.SET_CHAT_HEADS:
      return { ...state, chatHeads: action.payload };
    case ActionType.SET_CHAT_MESSAGES:
      return { ...state, chatMessages: action.payload };
    case ActionType.SET_SELECTED_CHAT:
      return { ...state, selectedChat: action.payload };
    case ActionType.SET_CHATLIST_TYPE:
      return { ...state, chatListType: action.payload };
    case ActionType.SET_CHATLIST_USERS:
      return { ...state, chatListUsers: action.payload };
    case ActionType.SET_CATAGORY_CHAT:
      return { ...state, selectedCatagoryChat: action.payload };
    case ActionType.SET_CHATLIST_USERS_VENDOR:
      return { ...state, chatListUsersVendor: action.payload };
    case ActionType.CHAT_LOADER:
      return { ...state, chatLoader: action.payload };
    case ActionType.SET_UNREAD_MESSAGES_COUNT:
      return { ...state, unreadMessagesCount: action.payload };
    case ActionType.SET_PROFILE_DETAILS:
      return { ...state, chatProfileDetail: action.payload };
    case ActionType.SET_APPOINTMENT_TIME:
      return { ...state, appointmentTime: action.payload };
    case ActionType.SET_SOCKET_INSTANCE:
      return { ...state, socketFleetMax: action.payload };
    case ActionType.FETCH_DATA:
      return { ...state, refetchData: action.payload };
    case ActionType.SET_GROUP_USERS_DETAILS:
      return { ...state, groupUsersDetails: action.payload };
    case ActionType.SET_BULK_REQUEST_DETAILS:
      return { ...state, bulkRequestDetails: action.payload };
    case ActionType.SET_INSPECION_DATA:
      return { ...state, inspectionData: action.payload };
    case ActionType.SET_INSPECION_DATA_VENDOR:
      return { ...state, inspectionDataVendor: action.payload };
    case ActionType.SET_VEHICLE_HISTORY:
      return { ...state, vehicleHistory: action.payload };
    case ActionType.SET_NEW_QUESTION:
      return { ...state, newQuestion: action.payload };
    case ActionType.SET_INSPECT_REFETCH:
      return { ...state, inspectRefetch: action.payload };
    case ActionType.SET_CURRENT_SORT_TYPE:
      return { ...state, currentSortType: action.payload };
    case ActionType.SET_LAYOUT_CONTENTS:
      return { ...state, layoutContents: action.payload };
    case ActionType.SET_MOVE_TO_CONFIRMED:
      return { ...state, moveToConfirmed: action.payload };
    case ActionType.SET_ASSIGN_TECH_BULK:
      return { ...state, assignTechBulk: action.payload };
    case ActionType.SET_SERVICES_LIST:
      return { ...state, servicesList: action.payload };
    case ActionType.SET_VENDOR_DETAILS:
      return { ...state, vendorDetails: action.payload };
    case ActionType.SET_DISPUTE:
      return { ...state, dispute: action.payload };
    case ActionType.SET_ATTACHMENTS:
      return { ...state, attachments: action.payload };
    case ActionType.SET_SELECTED_ASSIGN_VENDOR:
      return { ...state, selectedAssignVendor: action.payload };
    case ActionType.SET_SELECTED_ASSIGN_TECH:
      return { ...state, selectedAssignTech: action.payload };
    case ActionType.SET_SELECTED_ASSIGN_PARTNER:
      return { ...state, selectedAssignPartner: action.payload };
    case ActionType.SET_REQUEST_MODAL_DATA:
      return { ...state, requestModal: action.payload };
    case ActionType.SET_MULTI_SELECT_REQ:
      return { ...state, multiSelectReq: action.payload };
    case ActionType.SET_MULTI_SELECT_INVOICE_ITEMS:
      return { ...state, multiSelectInvoiceItems: action.payload };
    case ActionType.SET_BULK_REQUEST_DATA:
      return { ...state, bulkRequestData: action.payload };
    case ActionType.SET_SELECTED_DSP_SLOT:
      return { ...state, selectedDSPSLot: action.payload };
    case ActionType.SET_ACCEPTED_DATA_VENDOR:
      return { ...state, acceptedDataVendor: action.payload };
    case ActionType.CHAT_HEADS_LOADER:
      return { ...state, chatHeadsLoader: action.payload };
    case ActionType.SET_INVOICE_VIEW:
      return { ...state, invoiceView: action.payload };
    case ActionType.SET_DOWNLOAD_VIEW:
      return { ...state, downloadView: action.payload };
    case ActionType.SET_CUSTOMIZE_VIEW:
      return { ...state, customizeView: action.payload };
    case ActionType.SET_USER_INFO_INVOICE:
      return { ...state, userInfoInvoice: action.payload };
    case ActionType.SET_ALERTS_OFFSET:
      return { ...state, alertsOffset: action.payload };
    case ActionType.SET_FILTER_TYPE:
      return { ...state, filterType: action.payload };
    case ActionType.SET_PENDING_VEHICLES:
      return { ...state, pendingVehicles: action.payload };
    case ActionType.SET_REQUEST_LOADER:
      return { ...state, requestLoader: action.payload };
    case ActionType.SET_INVOICE_LOADER:
      return { ...state, invoiceLoader: action.payload };
    case ActionType.SET_REFRESH_TOKEN:
      return {
        ...state,
        user: state.user && {
          ...state.user,
          token: action.payload.token,
        },
      };
    case ActionType.SET_PARTNER_VENDORS:
      return { ...state, partnerVendors: action.payload };
    default:
      return state;
  }
};

export default reducer;

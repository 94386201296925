import { Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import ApprovedSvg from '../../../assets/Approved';
import DropDown from '../../../components/DropDowns/DropDown';
import { RootState } from '../../../state/reducers';
import useCustomParams from '../../common/CustomParams';
import { SortUpdated } from '../../common/SortData';
import Table from '../../common/Table';
import BulkInfoModel from '../../csr/bulkInfoModal';
import { UpdatedAtTime } from '../../csr/primaryComponents/AppointmentTime';
import TillMiles from '../../csr/primaryComponents/TillMiles';
import useFetchCheckList from '../../hooks/useFetchCheckList';
import useFetchSingleRequest from '../../hooks/vendor/useFetchSingleRequest';
import Invoice from '../PrimaryComponents/invoice/Invoice';
import Request from '../requestModel/RequestModal';
import Tootltip from '../../../components/BarData/Tootltip';
import InvoiceModal from '../../csr/primaryComponents/Invoice';
import LeasePlan from '../../csr/primaryComponents/LeasePlan';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import { initialView } from './PaymentConfirmed';
import PriorityIcons from '../../csr/scheduleRequest/components/PriorityIcons';
import { CustomTooltip } from '../../csr/components/Complete';
import CsrAPI from '../../../api/csrApi';
import { toast } from '../../../components/Toast/CustomToast';

export const invoiceReceivedCheck = (pmRequest: any, user: any) =>
  pmRequest?.status_id !== '12' &&
  pmRequest.status_id !== '9' &&
  pmRequest?.invoice?.invoice_status === 'sent' &&
  user.account_id !== pmRequest.invoice.invoice_created_by;
export const invoiceSentCheck = (pmRequest: any, user: any) =>
  pmRequest?.status_id !== '12' &&
  pmRequest?.invoice?.invoice_status === 'sent' &&
  user.account_id === pmRequest.invoice.invoice_created_by;

export const proofAccepetedCheck = (pmRequest: any) =>
  pmRequest.status_id === '9' ||
  (pmRequest.status_id === '11' &&
    !['sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
      pmRequest?.invoice?.invoice_status
    ));

export const paymentCompletedCheck = (pmRequest: any) =>
  pmRequest?.status_id === '12' ||
  ['amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
    pmRequest?.invoice?.invoice_status
  );

export const viewInvoiceCheck = (pmRequest: any) =>
  ['sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
    pmRequest?.invoice?.invoice_status
  ) || pmRequest?.vendor_invoice_dispute;

const Approved = ({ approvedData, fetchRequestData }: any) => {
  const dispatch = useDispatch();
  const [tooltipData, setTooltipData] = useState({} as any);
  const { user, multiSelectReq }: any = useSelector((state: RootState) => state.userState);
  const { setRequestModal } = bindActionCreators(actionCreators, dispatch);
  const [data, setData] = useState<any[]>([]);
  const [viewInvoiceModal, setViewInvoiceModal] = useState({ open: false, data: {} });
  const [invoiceModal, setInvoiceModal] = useState({
    open: false,
    data: {},
  } as any);
  const [view, setView] = useState(initialView);
  const { checkList } = useFetchCheckList();
  const { fetchRequestData: fetchRequestDetails }: any = useFetchSingleRequest();
  const [bulkInfoModal, setBulkInfoModal] = useState({ open: false, data: {} } as any);
  const [leasePlanModal, setLeasePlanModal] = useState({ open: false, data: {} });
  const { redirectRequest, requestModal } = useSelector((state: RootState) => state.userState);
  const fetchTooltipData = async (id: any) => {
    try {
      const res = await CsrAPI.getTooltipData({ id });
      if (res.status === 200) {
        setTooltipData((prevData: any) => ({
          ...prevData,
          [id]: res.data.data,
        }));
      } else {
        toast.warn('Something went wrong');
      }
    } catch (error) {
      toast.error('Failed to fetch tooltip data');
    }
  };
  useEffect(() => {
    filterProcessStatus(selectedStatusSort);
  }, [approvedData]);
  const requestFilterHead = ['Invoice Received', 'Proofs Accepted', 'Payment Completed'];

  const handleSort = (data: any) => {
    setData(SortUpdated(data));
  };

  const filterProcessStatus = (item: string) => {
    switch (item) {
      case 'All':
        handleSort(
          approvedData.filter(
            (elem: { status_id: string; dsp: { dsp_short_code: string } }) => true
          )
        );
        return;
      case 'Proofs Accepted':
        handleSort(
          approvedData.filter((elem: { status_id: string; sub_stage: string; invoice: any }) =>
            proofAccepetedCheck(elem)
          )
        );
        return;
      case 'Invoice Received':
        handleSort(
          approvedData.filter((elem: { status_id: string; sub_stage: string; invoice: any }) =>
            invoiceReceivedCheck(elem, user)
          )
        );
        return;
      case 'Invoice Sent':
        handleSort(
          approvedData.filter((elem: { status_id: string; sub_stage: string; invoice: any }) =>
            invoiceSentCheck(elem, user)
          )
        );
        return;
      case 'Payment Completed':
        handleSort(
          approvedData.filter((elem: { status_id: string; invoice: any }) =>
            paymentCompletedCheck(elem)
          )
        );
        return;

      default:
        return [];
    }
  };

  const { selectedStatusSort, navigateRequest } = useCustomParams(
    filterProcessStatus,
    'All',
    'approved'
  );
  const menuHeads = (
    item: { status_id: string; preventive_request_id: string; sub_stage: string },
    number: number
  ) => {
    if (['9'].includes(item.status_id)) {
      return [{ head: '', event: () => {} }];
    } else {
      if (viewInvoiceCheck(item)) {
        return [
          {
            head: 'View Invoice',
            event: () => {
              fetchRequestDetails(item).then((res: any) => {
                if (res) {
                  setViewInvoiceModal({
                    open: true,
                    data: { ...res, title: `Request-${item?.preventive_request_id}` },
                  });
                }
              });
            },
          },
        ];
      } else {
        return [{ head: '', event: () => {} }];
      }
    }
  };

  const handleSetView = (obj: any, data: any = {}) => {
    setView({
      ...initialView,
      ...obj,
    });
  };

  const handleRequestModalData = useMemo(
    () => ({
      body5: () => {
        // if (
        //   ['9', '11'].includes(requestModal?.data?.status_id ?? '') &&
        //   user.role === 'fleetmax_partner'
        // ) {
        //   return [
        //     (item: any) => {
        //       const serviceByPartner: boolean =
        //         item?.vendor?.account_id === item?.partner?.account_id;
        //       const tooltipMessage = !item?.lease_plan
        //         ? 'Lease Plan Data Missing'
        //         : serviceByPartner
        //         ? "Can't generate Invoice"
        //         : '';
        //       return (
        //         (getDspInvoiceStatus(item).text !== 'Sent' ||
        //           getVendorInvoiceStatus(item).text !== 'Sent') && (
        //           <ActionAccess item={item}>
        //             <Tooltip title={tooltipMessage} placement='top'>
        //               <span>
        //                 <InvoicingPopUp
        //                   item={item}
        //                   serviceByPartner={serviceByPartner}
        //                   handleClick={(onClicked: string) => {
        //                     setInvoiceModal({
        //                       open: true,
        //                       data: {
        //                         ...item,
        //                         onClicked,
        //                       },
        //                     });
        //                   }}
        //                 />
        //               </span>
        //             </Tooltip>
        //           </ActionAccess>
        //         )
        //       );
        //     },
        //   ];

        // } else {
        return [
          (item: any, number: number) =>
            viewInvoiceCheck(item) && (
              <div className='w-full flex justify-center'>
                <button
                  className='py-2 px-6 bg-blue-600 text-white rounded-lg border-none'
                  onClick={() => {
                    setViewInvoiceModal({
                      open: true,
                      data: { ...item, title: `Request-${item?.preventive_request_id}` },
                    });
                  }}>
                  View Invoice
                </button>
              </div>
            ),
        ];
        // }
      },
      body6: () => {
        return [
          (item: any) => <></>,
          // user.role === 'fleetmax_partner' && (
          //   <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
          //     <Button
          //       variant='text'
          //       className='py-1 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg text-[1rem] font-bold'
          //       onClick={() => setLeasePlanModal({ open: true, data: item })}>
          //       {item?.lease_plan ? 'View Lease Plan' : 'Lease Plan Update'}
          //     </Button>
          //   </div>
          // ),
        ];
      },
      status: () => {
        switch (requestModal.data?.status_id) {
          case '9':
            return [
              (item: any) => (
                <div
                  className={`px-3 py-2 bg-slate-200 rounded-lg flex justify-between items-center`}>
                  <p>Proofs Accepted</p>
                  <p>
                    <UpdatedAtTime item={item} />
                  </p>
                </div>
              ),
            ];
          case '11':
            if (viewInvoiceCheck(requestModal?.data)) {
              return [
                (item: any) => (
                  <div
                    className={`px-3 py-2 bg-rose-200 rounded-lg flex justify-between items-center`}>
                    <p>Invoice Received</p>
                    <p>
                      <UpdatedAtTime item={item} />
                    </p>
                  </div>
                ),
              ];
            } else {
              return [
                (item: any) => (
                  <div
                    className={`px-3 py-2 bg-slate-200 rounded-lg flex justify-between items-center`}>
                    <p>Proofs Accepted</p>
                    <p>
                      <UpdatedAtTime item={item} />
                    </p>
                  </div>
                ),
              ];
            }
          case '12':
            return [
              (item: any) => (
                <div
                  className={`px-3 py-2 bg-green-200 rounded-lg flex justify-between items-center`}>
                  <p>Payment Completed</p>
                  <p>
                    <UpdatedAtTime item={item} />
                  </p>
                </div>
              ),
            ];
          default:
            return [];
        }
      },
    }),
    [checkList, requestModal]
  );

  useEffect(() => {
    if (redirectRequest && Object.keys(redirectRequest || {}).length > 0) {
      const redirectedData = {
        id: redirectRequest?.preventive_request_id,
        bulk_id: redirectRequest?.bulk_id,
        preventive_request_id: redirectRequest?.preventive_request_id,
      };
      if (redirectRequest?.bulk_id && redirectRequest?.bulk_modal) {
        setBulkInfoModal({
          open: true,
          data: {
            id: redirectRequest?.preventive_request_id,
            bulk_id: redirectRequest?.bulk_id,
            preventive_request_id: redirectRequest?.preventive_request_id,
          },
        });
      } else {
        fetchRequestDetails(redirectedData).then((res: any) => {
          if (res) {
            setRequestModal({
              open: true,
              data: res,
            });
          }
        });
      }
    }
  }, [redirectRequest]);

  useEffect(() => {
    if (invoiceModal.open) {
      setInvoiceModal({
        ...invoiceModal,
        data: {
          ...invoiceModal?.data,
          ...requestModal?.data,
        },
      });
    }
  }, [requestModal?.data]);

  useEffect(() => {
    // update bulkInfoModal data
    if (
      bulkInfoModal?.open &&
      (Object.keys(redirectRequest || {}).length === 0 || !redirectRequest)
    ) {
      const data = approvedData.find(
        (el: any) => el.preventive_request_id === bulkInfoModal?.data.preventive_request_id
      );
      if (data) {
        setBulkInfoModal({ ...bulkInfoModal, data: data });
      } else {
        setBulkInfoModal({ ...bulkInfoModal, open: false });
      }
    }
  }, [approvedData]);
  const tableData = useMemo(
    () => ({
      header: [
        (item: any) => (
          <DropDown
            name={'Request Status'}
            mainBtnSx={'w-[13rem]'}
            selected={selectedStatusSort}
            options={['All', ...requestFilterHead]}
            handlDropDownClick={(item: string) => {
              navigateRequest(item);
            }}
            dropBtnSx='bg-white'
          />
        ),
        (item: any) => 'showDspShortCode',
        (item: any) => <p className='w-[6rem]'>Van Name</p>,
        (item: any) => <p className='w-[12rem]'>VIN</p>,
        (item: any) => <p className='w-[6rem]'>Last PM</p>,
        (item: any) => <p className='w-[5.3rem]'>Miles Till Inspection</p>,
        (item: any) => <p className='w-[5rem]'>Current Mileage</p>,
        (item: any) => <p className='w-[7rem]'>Assigned Technician</p>,
      ],
      rowData: [
        (item: any, number: number) => (
          <div className='flex items-center gap-2  w-[15rem]'>
            <div className='w-[2rem] h-[2rem] rounded-[0.6rem]'>
              <PriorityIcons iconType={item?.priority || '2'} />
            </div>
            {proofAccepetedCheck(item) && (
              <Tooltip title='Proofs Accepted'>
                <div className='w-[3.5rem] h-[2rem] rounded-[0.6rem]'>
                  <ApprovedSvg />
                </div>
              </Tooltip>
            )}
            {invoiceReceivedCheck(item, user) && (
              <Tooltip title='Invoice Received'>
                <div className='w-[3.5rem] bg-teal-200 flex items-center justify-center h-[2rem]  rounded-[0.6rem]'>
                  <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
                </div>
              </Tooltip>
            )}
            {invoiceSentCheck(item, user) && (
              <Tooltip title='Invoice Sent'>
                <div className='w-[3.5rem] bg-teal-200 flex items-center justify-center h-[2rem]  rounded-[0.6rem]'>
                  <i className='pi pi-money-bill' style={{ fontSize: '1rem' }}></i>
                </div>
              </Tooltip>
            )}
            {paymentCompletedCheck(item) && (
              <Tooltip title='Invoice Approved'>
                <div className='w-[3.5rem] bg-green-200 flex items-center justify-center h-[2rem]  rounded-[0.6rem]'>
                  <i className='pi pi-dollar' style={{ fontSize: '1rem' }}></i>
                </div>
              </Tooltip>
            )}
            {item?.bulk_id > 0 ? (
              <CustomTooltip
                title={
                  <div className='capitalize'>
                    {tooltipData?.[item?.bulk_id]?.length > 0
                      ? tooltipData[item?.bulk_id]?.map((dataItem: any) => (
                          <div key={dataItem.vehicle_name}>{dataItem.vehicle_name}</div>
                        ))
                      : 'loading...'}
                  </div>
                }>
                <button
                  onMouseEnter={async () => {
                    try {
                      if (!tooltipData[item?.bulk_id]) {
                        if (item?.bulk_id) {
                          await fetchTooltipData(item?.bulk_id);
                        }
                      }
                    } catch (error) {
                      toast.error('Failed to fetch tooltip data');
                    }
                  }}
                  className='text-blue-500 font-semibold text-sm cursor-pointer border-none py-1.5 px-3 hover:bg-slate-100 rounded-full whitespace-nowrap '
                  onClick={() => {
                    if (item?.bulk_id) {
                      setBulkInfoModal({
                        open: true,
                        data: item,
                      });
                    } else {
                      setRequestModal({
                        open: true,
                        data: item,
                      });
                    }
                  }}>
                  {item.title}
                </button>
              </CustomTooltip>
            ) : (
              <button
                className='text-blue-500 font-semibold text-sm cursor-pointer border-none py-1.5 px-3 hover:bg-slate-100 rounded-full whitespace-nowrap '
                onClick={() => {
                  if (item?.bulk_id) {
                    setBulkInfoModal({
                      open: true,
                      data: item,
                    });
                  } else {
                    setRequestModal({
                      open: true,
                      data: item,
                    });
                  }
                }}>
                {item.title}
              </button>
            )}
          </div>
        ),
        (item: any) => <p className='w-[7rem]'>{item.dsp.station_code}</p>,
        (item: any) => (
          <p className='w-[6rem]'>
            <Tootltip title={item?.vehicle?.name} placement={'bottom'}>
              <p
                className='max-w-[6rem] text-ellipsis overflow-hidden'
                style={{ whiteSpace: 'nowrap' }}>
                {item?.vehicle?.name}
              </p>
            </Tootltip>
          </p>
        ),
        (item: any) => <p className='w-[12rem]'>{item?.vehicle?.vin}</p>,
        (item: any) => <p className='w-[6rem]'>{item?.miles || '---'}</p>,
        TillMiles,
        (item: any) => <p className='w-[5rem]'>{item?.curr_miles || '---'}</p>,
        (item: any) => <p className='w-[7rem] capitalize'>{item?.technician?.name || 'N/A'}</p>,
        (item: any, number: any) =>
          menuHeads(item, number).map((elem: { head: string; event: () => void }) => (
            <button
              className='text-blue-500 font-semibold text-sm cursor-pointer border-none py-1.5 px-3 hover:bg-slate-100 rounded-full w-[6rem] whitespace-nowrap'
              onClick={elem.event}
              key={v4()}>
              {elem.head}
            </button>
          )),
      ],
    }),
    [selectedStatusSort, requestFilterHead, tooltipData]
  );

  return (
    <>
      <Table
        havebulkrow
        data={data}
        header={tableData.header}
        dataVisibleContent={tableData.rowData}
        bulkrowformat={tableData.rowData}
      />

      {requestModal.open && (
        <Request
          handleClose={() => setRequestModal({ open: false, data: {} })}
          item={requestModal?.data}
          handleView={handleSetView}
          body5={handleRequestModalData.body5()}
          body6={handleRequestModalData.body6()}
          status={handleRequestModalData.status()}
          type={'approved'}
          refetch={fetchRequestData}
        />
      )}

      {bulkInfoModal.open && (
        <BulkInfoModel
          handleClose={() => setBulkInfoModal({ open: false, data: {} })}
          data={bulkInfoModal?.data}
          refetch={fetchRequestData}
        />
      )}

      {viewInvoiceModal.open && (
        <Invoice
          handleClose={() => setViewInvoiceModal({ open: false, data: {} })}
          item={viewInvoiceModal.data}
          fetchRequestData={() => {
            fetchRequestData();
            setRequestModal({ open: false, data: {} });
          }}
        />
      )}
      {invoiceModal.open && (
        <InvoiceModal
          refetch={() => {
            fetchRequestData();
            setRequestModal({ open: false, data: {} });
          }}
          handleClose={() => {
            setInvoiceModal({ open: false, data: {} });
          }}
          invoiceData={invoiceModal?.data}
        />
      )}
      {leasePlanModal.open && (
        <LeasePlan
          handleClose={() => setLeasePlanModal({ open: false, data: {} })}
          item={leasePlanModal.data}
          refetch={fetchRequestData}
        />
      )}
    </>
  );
};

export default Approved;

import { Tooltip } from '@mui/material';
import Tootltip from '../../../../../components/BarData/Tootltip';
import { DummyDataInvoiceItem } from '../../../types';
import { ThreeDots } from 'react-loader-spinner';

export function EditFields({
  editState,
  services,
  handleInputChange,
  editedTotalAmount,
  totalAmount,
  invoiceDetails,
}: {
  editState: any;
  services: any;
  handleInputChange: any;
  editedTotalAmount: any;
  totalAmount: any;
  invoiceDetails?: any;
}) {
  const taxTotal = editState?.vendorPrices?.reduce((total: number, item: any) => {
    if (item?.tax) {
      const taxedAmount =
        (parseFloat(item?.rate || 0) || 0) *
        (item?.quantity || 0) *
        (1 + (parseFloat(invoiceDetails?.invoice?.tax_percent) || 0) / 100);
      return total + taxedAmount;
    } else {
      return total + parseFloat(item?.rate || 0) * (item?.quantity || 0);
    }
  }, 0);
  return (
    <>
      {services?.length === 0 && (
        <div className='w-full h-full flex justify-center items-center '>
          <ThreeDots
            height='30'
            width='30'
            radius='9'
            color={'#4378C8'}
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            visible={true}
          />
        </div>
      )}
      {services?.length !== 0 && (
        <>
          <table className='w-full border-collapse'>
            <thead className='sticky top-0  border-b-[0.15rem] bg-gray-50 border-gray-300 !important'>
              <tr className='border-b-[0.15rem] border-gray-300'>
                <th className='text-left p-2 font-bold border-b border-gray-300'>Service Name</th>
                <th className='text-center p-2 font-bold border-b border-gray-300'>Quantity</th>
                <th className='text-center p-2 font-bold border-b border-gray-300'>Rate</th>
                <th className='text-center p-2 font-bold border-b border-gray-300'>Total</th>
              </tr>
            </thead>
            <tbody>
              {services?.map((ele: DummyDataInvoiceItem, index: number) => (
                <tr
                  key={ele?.rate || ele?.price || index}
                  className='border-b-[0.15rem] border-gray-300 h-[2.5rem]'>
                  <td className='p-2'>
                    <Tootltip title={`${String(ele.service) || 'N/A'}`} placement={'bottom'}>
                      <p className='max-w-[10rem] text-ellipsis whitespace-nowrap overflow-hidden font-bold capitalize'>
                        {String(ele?.service) || 'N/A'}
                      </p>
                    </Tootltip>
                  </td>
                  <td className='p-2 w-[7rem]'>
                    {editState.showEdit ? (
                      <input
                        type='text'
                        value={
                          editState?.vendorPrices?.[index]?.quantity !== null
                            ? editState?.vendorPrices?.[index]?.quantity ?? 0
                            : 0
                        }
                        onChange={(e) => handleInputChange(e, index, 'quantity')}
                        className='w-full h-[2.5rem] rounded-[0.5rem] pl-2 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                      />
                    ) : (
                      <p className='text-[1.1rem] font-bold flex justify-center items-center'>
                        {editState?.vendorPrices[index]?.quantity ?? (ele?.quantity || 0)}
                      </p>
                    )}
                  </td>
                  <td className='p-2 w-[7rem]'>
                    {editState.showEdit ? (
                      <input
                        type='text'
                        value={
                          editState.vendorPrices[index]?.rate !== null
                            ? editState.vendorPrices[index]?.rate ?? 0
                            : 0
                        }
                        onChange={(e) => handleInputChange(e, index, 'rate')}
                        className='w-full h-[2.5rem] rounded-[0.5rem] pl-2 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                      />
                    ) : (
                      <p className='text-[1.1rem] font-bold flex justify-center items-center'>
                        ${editState?.vendorPrices[index]?.rate ?? ele?.rate}
                      </p>
                    )}
                  </td>
                  <td className='p-2 flex justify-center items-center h-[3.5rem] w-full'>
                    {editState.showEdit ? (
                      <div className='flex flex-col gap-2 '>
                        {editState.vendorPrices[index].tax ? (
                          <div className='w-full flex items-center'>
                            <Tooltip title={`This service is taxed`} placement='bottom'>
                              <i className='pi pi-exclamation-circle mr-1 text-primary'></i>
                            </Tooltip>
                            $
                            <Tootltip placement={'bottom'}>
                              <p className='w-[4.5rem] max-w-[4.5rem] text-ellipsis whitespace-nowrap overflow-hidden font-bold'>
                                {Number(
                                  Number(editState.vendorPrices[index]?.rate || 0) *
                                    Number(editState.vendorPrices[index]?.quantity || 0) *
                                    (1 + Number(invoiceDetails?.invoice?.tax_percent || 0) / 100)
                                ).toFixed(2) || 0}
                              </p>
                            </Tootltip>
                          </div>
                        ) : (
                          <Tootltip placement={'bottom'}>
                            <p className='w-[4.5rem] max-w-[4.5rem] text-ellipsis whitespace-nowrap overflow-hidden font-bold'>
                              $
                              {Number(
                                Number(editState.vendorPrices[index]?.rate || 0) *
                                  Number(editState.vendorPrices[index]?.quantity || 0)
                              ).toFixed(2) || 0}
                            </p>
                          </Tootltip>
                        )}
                      </div>
                    ) : (
                      <p className='text-[1.1rem] font-bold pr-2 flex justify-center items-center'>
                        {ele.is_taxable ? (
                          <p className='w-[5.5rem] max-w-[5.5rem] text-ellipsis whitespace-nowrap overflow-hidden font-bold'>
                            <div className='w-full flex items-center'>
                              <Tooltip title={`This service is taxed`} placement='bottom'>
                                <i className='pi pi-exclamation-circle mr-1 text-primary'></i>
                              </Tooltip>
                              $
                              <Tootltip placement={'bottom'}>
                                <p className='w-[5.5rem] max-w-[5.5rem] text-ellipsis whitespace-nowrap overflow-hidden font-bold text-[0.95rem]'>
                                  {Number(
                                    Number(ele.rate) *
                                      Number(ele.quantity || 0) *
                                      (1 + Number(invoiceDetails?.invoice?.tax_percent || 0) / 100)
                                  ).toFixed(2) || 0}
                                </p>
                              </Tootltip>
                            </div>
                          </p>
                        ) : (
                          <Tootltip placement={'bottom'}>
                            <p className='w-[5.5rem] max-w-[5.5rem] text-ellipsis whitespace-nowrap overflow-hidden font-bold text-[0.95rem]'>
                              ${Number(ele.rate) * Number(ele.quantity || 0) || 0}{' '}
                            </p>
                          </Tootltip>
                        )}
                      </p>
                    )}
                  </td>
                </tr>
              ))}
              <tr key={'subTotal'} className='border-t-[0.15rem] border-gray-300'>
                <td className='p-2 font-bold'>SUB TOTAL</td>
                <td colSpan={3} className='text-right p-2 font-bold'>
                  $
                  {editState?.showEdit
                    ? editedTotalAmount?.toFixed(2)
                    : invoiceDetails?.invoice?.subtotal_amount?.toFixed(2) ||
                      invoiceDetails?.invoice?.total_amount?.toFixed(2)}
                </td>
              </tr>
              <tr key={'tax'} className='border-t-[0.15rem] border-gray-300'>
                <td className='p-2 font-bold'>
                  TAX (NYC {invoiceDetails?.invoice?.tax_percent || 0} %)
                </td>
                <td colSpan={3} className='text-right p-2 font-bold'>
                  $
                  {editState?.showEdit ? (
                    <>{(taxTotal - editedTotalAmount)?.toFixed(2)}</>
                  ) : (
                    <>
                      {Number(
                        invoiceDetails?.invoice?.total_amount?.toFixed(2) -
                          invoiceDetails?.invoice?.subtotal_amount?.toFixed(2) || 0
                      ).toFixed(2)}
                    </>
                  )}
                </td>
              </tr>
              <tr key={'totalDue'} className='border-t-[0.15rem] border-gray-300'>
                <td className='p-2 font-bold'>TOTAL DUE</td>
                <td colSpan={3} className='text-right p-2 font-bold'>
                  $
                  {editState?.showEdit ? (
                    taxTotal?.toFixed(2) || 0
                  ) : (
                    <>{Number(invoiceDetails?.invoice?.total_amount?.toFixed(2)) || 0}</>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

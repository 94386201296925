import { Button, Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { v4 } from 'uuid';
import ManualDspSvg from '../../../../assets/Manul_dsp';
import Tootltip from '../../../../components/BarData/Tootltip';
import DropDown from '../../../../components/DropDowns/DropDown';
import Menu from '../../../../components/DropDowns/Menu';
import { actionCreators } from '../../../../state';
import { RootState } from '../../../../state/reducers';
import useCustomParams from '../../../common/CustomParams';
import MultipleAppointmentExpand from '../../../common/MultipleAppointmentExpand';
import { SortUpdated, updateBulkModalData, updateModalData } from '../../../common/SortData';
import Table from '../../../common/Table';
import { vehicleInfo } from '../../../common/VehicleInfo';
import CancelRequestModel, {
  ModelContainerCancel,
} from '../../../common/requestDetail/CancelRequestModel';
import Logs from '../../../common/requestDetail/MaintainanceLogsModel';
import useFetchSingleRequest from '../../../hooks/csr/useFetchSingleRequest';
import BulkInfoModel from '../../bulkInfoModal';
import AppointmentTime from '../../primaryComponents/AppointmentTime';
import LeasePlan from '../../primaryComponents/LeasePlan';
import RequestModel from '../../primaryComponents/RequestModal';
import TillMiles from '../../primaryComponents/TillMiles';
import AssignVendorModal from './AssignVendorModal';
import AssignPartnerModel from './components/AssignPartnerModel';
import useFetchVendorSingleRequest from '../../../hooks/vendor/useFetchVendorSingleRequest';
import PriorityIcons from '../../scheduleRequest/components/PriorityIcons';
import { getBulkPriorityStatus } from '../../scheduleRequest/components/PrioritySelction';
import VendorAPI from '../../../../api/vendorApi';
import { getDateAndTime } from '../../../vender/PrimaryComponents/requestComponents/AcceptRejectBtn';
import { CustomTooltip } from '../Complete';

export const ScheduleTime: any = ({
  item,
  selectedRequest,
}: {
  item: any;
  selectedRequest: any;
}) => {
  const onlyThisType: boolean =
    !['3', '10', '12'].includes(item.status_id) &&
    !(['4'].includes(item.status_id) && ['3'].includes(item.sub_stage));
  const btn =
    item?.cancelRequestbtn ||
    (onlyThisType && (
      <div
        className='px-2 rounded-[0.5rem] hover:cursor hover:bg-gray-200'
        style={{ color: 'red', cursor: 'pointer' }}
        onClick={() => item?.setShowCancelModel({ open: true, data: item })}>
        Cancel Request
      </div>
    ));
  const cancelBtn = <div>{item?.bulk_id ? selectedRequest && btn : btn}</div>;
  if (item?.status_id === '1') {
    return (
      <div className=''>
        <div className='flex justify-between'>
          <p
            className=''
            style={{
              fontWeight: '400',
              color: '#AD741E',
              fontSize: '0.95rem',
              borderRadius: '0.25rem',
              backgroundColor: 'rgba(200, 122, 30, 0.09)',
              padding: '0.031rem',
              height: '1.5rem',
              width: '9.188rem',
              textAlign: 'center',
              marginBottom: '0.313rem',
            }}>
            Approval Pending
          </p>
          {cancelBtn}
        </div>
        {item?.appointments?.length <= 1 && (
          <>
            <div
              className='flex flex-col h-full overflow-auto self-end gap-2  pr-3'
              style={{ color: '#4378c8' }}>
              {item?.appointments?.map((el: { date: string; time: string }) => (
                <AppointmentTime item={el} key={v4()} noInfo multiple />
              ))}
            </div>
          </>
        )}
        {item?.appointments?.length > 1 && (
          <MultipleAppointmentExpand item={item} variant='expandDown' />
        )}
      </div>
    );
  }

  if (item?.status_id === '2') {
    return (
      <div className=''>
        <div>
          <div className='flex justify-between'>
            <p
              className='flex justify-between items-center text-[#1EAD2D] rounded-[0.25rem] mb-[0.35rem] px-1'
              style={{ backgroundColor: 'rgba(15, 164, 30, 0.09)' }}>
              Approved Scheduled
            </p>
            {cancelBtn}
          </div>
          <div style={{ color: 'blue', fontWeight: '500', paddingBottom: '0.225rem' }}>
            <AppointmentTime item={item} noInfo />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='label text-primary text-sm ' style={{ fontWeight: 'normal' }}>
      <div className='flex justify-between'>
        <div className='row-container flex flex-col gap-1' style={{ marginBottom: '0.3rem' }}>
          Scheduled For
        </div>
        {cancelBtn}
      </div>
      <div
        className='date-time text-primary'
        style={{ fontWeight: 'bold', paddingBottom: '0.5rem' }}>
        <AppointmentTime item={item} />
      </div>
    </div>
  );
};
const scheduleFilterHead = [
  'Approval Pending From DSP',
  'Approval Pending From Vendor',
  'Approved',
  // 'Declined',
  'Scheduled by DSP',
  // 'Scheduled',
  'Approval Pending from Partner',
  'Assigned to Partner',
];

const Schedule = ({ scheduleData, refetch }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [vendorModal, setVendorModal] = useState({ open: false, data: {} });
  const [partnerModel, setPartnerModel] = useState({ open: false, data: {} });
  const [bulkInfoModal, setBulkInfoModal] = useState({ open: false, data: {} } as any);
  const [leasePlanModal, setLeasePlanModal] = useState({ open: false, data: {} });
  const [requestModel, setRequestModel] = useState<any>({ open: false, data: {} });
  const [showCancelModel, setShowCancelModel] = useState({ open: false, data: {} });
  const { fetchRequestData }: any = useFetchSingleRequest();
  const { redirectRequest }: any = useSelector((state: RootState) => state.userState);
  const { setBulkVehicles } = bindActionCreators(actionCreators, dispatch);
  const { fetchRequestData: fetchRequestDetails }: any = useFetchVendorSingleRequest();
  useEffect(() => {
    filterScheduleStatus(selectedStatusSort);
  }, [scheduleData]);
  const getVehicleCheckList = async (data: any) => {
    const response = await VendorAPI.getVehicleCheckList({
      preventive_request_id: data?.id || data?.preventive_request_id || '',
    });
    if (response.status === 200) {
      return {
        checklist_questions: {
          question_answers: response?.data?.data?.[0]?.checklist_questions?.question_answers,
        },
      };
    }
  };
  const handleSchedule = async (item: any, reschedule?: boolean, slot?: any) => {
    await fetchRequestDetails(item).then(async (res: any) => {
      if (res) {
        const data = {
          ...res,
          id: res?.preventive_request_id || res?.id,
          preventive_request_id: res?.preventive_request_id || res?.id,
          title: `Request-${res?.preventive_request_id || res?.id}`,
        };
        const services = data?.checklists
          ?.filter((checklist: any) => checklist.name)
          ?.map((checklist: any) => checklist.name);
        const timeSlots = data?.appointments?.map((appointment: any) => {
          const timeArray = appointment?.time?.split(':');
          let hour = parseInt?.(timeArray?.[0]);
          let period = 'AM';
          if (hour >= 12) {
            period = 'PM';
            if (hour > 12) {
              hour -= 12;
            }
          }
          return getDateAndTime(appointment);
        });
        setBulkVehicles({
          bulkVehicles: [
            {
              ...(await getVehicleCheckList(data)),
              ...data,
              hasAdded: true,
              isSingleSchedule: true,
              services: services?.length > 0 ? services : [],
              customServices: services?.length > 0 ? services : [],
              timeSlots: timeSlots,
              ...(reschedule && { isReschedule: true, scheduleTo: 'Schedule to DSP' }),
              ...(!reschedule && { scheduleFromDSP: true, scheduleTo: 'Schedule to Vendor' }),
            },
          ],
        });
        navigate('/scheduleRequest');
      }
    });
  };

  const menuHeads = (item: any) => {
    const leasPlan = item?.lease_plan
      ? {
          head: 'View Lease Plan',
          event: async () => {
            setLeasePlanModal({ open: true, data: item });
          },
        }
      : {
          head: 'Lease Plan Update',
          event: async () => {
            setLeasePlanModal({ open: true, data: item });
          },
        };

    const commonOptions = [
      // { head: 'Cancel Schedule', event: () => cancelSchedule(item) },
      {
        event: async () => {
          setShowCancelModel({ open: true, data: item });
        },
        head: 'Cancel Request',
      },
      leasPlan,
    ];

    const menuArr = [];

    if (['1', '2'].includes(item.status_id) && item.request_type !== 'manual_dsp') {
      menuArr.push({
        head: 'Reschedule',
        event: () => handleSchedule(item, true),
      });
    }

    switch (item.status_id) {
      case '-1':
        return [
          {
            head: 'Re Assign Partner',
            event: async () => {
              if (item?.checklists?.length !== 0) {
                setPartnerModel({
                  open: true,
                  data: {
                    ...item,
                    suggested_partner: null,
                  },
                });
              }
            },
          },
          {
            event: async () => {
              setShowCancelModel({ open: true, data: item });
            },
            head: 'Cancel Request',
          },
          leasPlan,
        ];
      case '1':
        menuArr.push(...commonOptions);
        return menuArr;

      case '2':
        menuArr.push(
          ...[
            ...commonOptions,
            {
              head: 'Assign Vendor',
              event: async () => {
                setVendorModal({ open: true, data: { ...item, suggested_vendor: null } });
              },
            },
          ]
        );

        return menuArr;

      case '4':
        return [
          {
            head: 'Re Assign Vendor',
            event: async () => {
              setVendorModal({ open: true, data: { ...item, suggested_vendor: null } });
            },
          },
          {
            event: async () => {
              setShowCancelModel({ open: true, data: item });
            },
            head: 'Cancel Request',
          },
          leasPlan,
        ];

      default:
        return [];
    }
  };

  const filterStatusCode = useCallback(
    (status: any, all: boolean = false) => {
      let status_id: string = '';
      let sub_stage: string = '';
      let request_type: string = '';
      if (typeof status === 'string') {
        status_id = status;
      } else {
        status_id = status.status_id;
        sub_stage = status.sub_stage;
        request_type = status?.request_type;
      }
      const filteredData: any[] = [];
      const map = new Map();
      const bulk_map = new Map();

      scheduleData.forEach(
        (elem: {
          status_id: string;
          dsp: { dsp_short_code: string };
          id: string;
          sub_stage: string;
          request_type: string;
          bulk_id: string;
        }) => {
          map.set(elem.id, elem);
          if (elem?.bulk_id) {
            bulk_map.set(elem?.bulk_id, elem);
          }
          if (all) {
            filteredData.push(elem);
          } else if (typeof status === 'string') {
            if (elem.status_id === status_id || all) {
              if (status_id === '1') {
                if (elem?.request_type !== 'manual_dsp') {
                  filteredData.push(elem);
                }
              } else if (status_id === '2') {
                filteredData.push(elem);
              } else if (status_id === '3') {
                if (elem?.sub_stage !== '3' && elem?.request_type !== 'manual_dsp') {
                  filteredData.push(elem);
                }
              }
            }
          } else if (sub_stage) {
            if (elem.status_id === status_id || all) {
              if ([sub_stage].includes(elem?.sub_stage)) {
                filteredData.push(elem);
              }
            }
          } else if (request_type) {
            if (elem.status_id === status_id || all) {
              if ([request_type].includes(elem?.request_type)) {
                filteredData.push(elem);
              }
            }
          }
        }
      );
      if (
        (bulkInfoModal?.open || requestModel?.open || bulkInfoModal?.open) &&
        (Object.keys(redirectRequest || {}).length === 0 || !redirectRequest)
      ) {
        bulkInfoModal?.open && updateBulkModalData(bulk_map, bulkInfoModal, setBulkInfoModal);
        bulkInfoModal?.open &&
          updateBulkModalData(bulk_map, bulkInfoModal, setBulkInfoModal, map, setRequestModel);
        requestModel?.open && updateModalData(map, requestModel, setRequestModel);
      }
      setData(SortUpdated(filteredData));
    },
    [scheduleData, bulkInfoModal, bulkInfoModal, requestModel]
  );

  const filterScheduleStatus = (item: string) => {
    switch (item) {
      case 'All':
        return filterStatusCode('', true);

      case 'Approval Pending From DSP':
        return filterStatusCode('1');

      case 'Approved':
        return filterStatusCode('2');

      case 'Declined':
        return filterStatusCode('3');

      case 'Scheduled by DSP':
        return filterStatusCode({ status_id: '1', request_type: 'manual_dsp' });

      case 'Scheduled':
        return filterStatusCode({ status_id: '3', sub_stage: '2' });

      case 'Approval Pending From Vendor':
        return filterStatusCode({ status_id: '4', sub_stage: '1' });

      case 'Approval Pending from Partner':
        return filterStatusCode({ status_id: '-1', sub_stage: '1' });

      case 'Assigned to Partner':
        return filterStatusCode({ status_id: '-1', sub_stage: '2' });

      default:
        return [];
    }
  };

  const { selectedStatusSort, navigateRequest } = useCustomParams(
    filterScheduleStatus,
    'All',
    `schedule`
  );

  const showRedirectedModals = useCallback(
    async (item: any) => {
      const redirectedData = {
        id: redirectRequest?.preventive_request_id,
        bulk_id: redirectRequest?.bulk_id,
        preventive_request_id: redirectRequest?.preventive_request_id,
      };
      if (item?.bulk_id) {
        const found = item;
        if (found) {
          setBulkInfoModal({
            open: true,
            data: redirectedData,
          });
        }
      } else {
        const found = await fetchRequestData(redirectedData);

        if (found) {
          setRequestModel({
            open: true,
            data: found,
          });
        }
      }
    },
    [redirectRequest]
  );

  useEffect(() => {
    // Redirect to the the modal
    if (redirectRequest && Object.keys(redirectRequest || {}).length > 0 && scheduleData) {
      showRedirectedModals(redirectRequest);
    }
  }, [redirectRequest, scheduleData]);

  const header = [
    (item: any) => (
      <DropDown
        name={'Request Status'}
        mainBtnSx={'w-[13rem]'}
        selected={selectedStatusSort}
        options={['All', ...scheduleFilterHead]}
        handlDropDownClick={(item: string) => {
          navigateRequest(item);
        }}
        dropBtnSx='bg-white'
      />
    ),
    (item: any) => 'showDspShortCode',
    (item: any) => <p className='w-[6rem]'>Van Name</p>,
    (item: any) => <p className='w-[0.5rem]'></p>,
    (item: any) => <p className='w-[12rem]'>VIN</p>,
    (item: any) => <p className='w-[6rem]'>Last PM</p>,
    (item: any) => <p className='w-[5.3rem]'>Miles Till Inspection</p>,
    (item: any) => <p className='w-[5rem]'>Current Mileage</p>,
    (item: any) => <p className='w-[8rem]'>Plate Number</p>,
  ];

  const getRequestTypeData = useCallback((item: any) => {
    if (item.status_id === '-1' && item?.sub_stage === '1') {
      return (
        <div className={`p-1.5 bg-amber-100 text-[#AC9059] rounded-lg flex gap-2 w-[13rem]`}>
          <p
            className='max-w-[12rem] texy-sm'
            style={{ wordBreak: 'break-word', textAlign: 'center' }}>
            Approval Pending from Partner
          </p>
        </div>
      );
    }
    if (item.status_id === '-1' && item.sub_stage === '2') {
      return (
        <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg  w-[13rem]`}>
          <p>Scheduled to Partner</p>
        </div>
      );
    }
    if (item.status_id === '4' && item?.sub_stage === '1') {
      return (
        <div className={`p-1.5 bg-amber-100 text-[#AC9059] rounded-lg flex gap-2 w-[13rem]`}>
          <p
            className='max-w-[12rem] texy-sm'
            style={{ wordBreak: 'break-word', textAlign: 'center' }}>
            Approval Pending From Vendor
          </p>
        </div>
      );
    }

    if (item.request_type === 'manual_dsp') {
      return (
        <div className='flex gap-2 items-center w-[13rem]'>
          <ManualDspSvg />
          <div className='w-[13rem]'>
            <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg`}>
              <p>Scheduled by DSP</p>
            </div>
          </div>
        </div>
      );
    }

    if (item.status_id === '3' && item.sub_stage === '2') {
      return (
        <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg  w-[13rem]`}>
          <p>Scheduled</p>
        </div>
      );
    }

    return (
      <div className='flex gap-2 items-center justify-center'>
        <div className='w-[13rem]'>
          {item.status_id === '1' && (
            <div
              className={`p-1.5 bg-amber-100 text-[#AC9059] rounded-lg`}
              style={{ wordBreak: 'break-word', textAlign: 'center' }}>
              <p>Approval Pending From DSP</p>
            </div>
          )}
          {item.status_id === '2' && (
            <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg`}>
              <p>Approved Schedule</p>
              <p>
                <AppointmentTime item={item} noInfo />
              </p>
            </div>
          )}
          {item.status_id === '3' && item?.sub_stage !== '2' && (
            <div className={`p-1 bg-red-100 text-[#CC5555] rounded-lg`}>
              <p>Declined</p>
            </div>
          )}

          {item.status_id === '3' && item.sub_stage === '2' && (
            <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg`}>
              <p>Scheduled</p>
              <p>
                <AppointmentTime item={item} noInfo />
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }, []);

  const getRequestTypeBulkData = useCallback((item: any) => {
    if (item.status_id === '-1' && item?.sub_stage === '1') {
      return (
        <div className={`p-1.5 bg-amber-100 text-[#AC9059] rounded-lg flex gap-2 w-[13rem]`}>
          <p
            className='max-w-[12rem] texy-sm'
            style={{ wordBreak: 'break-word', textAlign: 'center' }}>
            Approval Pending from Partner
          </p>
        </div>
      );
    }
    if (item.status_id === '-1' && item.sub_stage === '2') {
      return (
        <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg  w-[13rem]`}>
          <p>Scheduled to Partner</p>
        </div>
      );
    }
    if (item.status_id === '4' && item?.sub_stage === '1') {
      return (
        <div className={`p-1.5 bg-amber-100 text-[#AC9059] rounded-lg flex gap-2 w-[13rem]`}>
          <p
            className='max-w-[12rem] texy-sm'
            style={{ wordBreak: 'break-word', textAlign: 'center' }}>
            Approval Pending From Vendor
          </p>
        </div>
      );
    }

    if (item.request_type === 'manual_dsp') {
      return (
        <div className='flex gap-2 items-center w-[13rem]'>
          <ManualDspSvg />
          <div className='w-[13rem]'>
            <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg`}>
              <p>Scheduled by DSP</p>
            </div>
          </div>
        </div>
      );
    }

    if (item.status_id === '3' && item.sub_stage === '2') {
      return (
        <div className={`p-1 bg-emerald-100 text-[#62C649] rounded-lg  w-[13rem]`}>
          <p>Scheduled</p>
        </div>
      );
    }
    const remainingApproval = item.bulkSchedules.filter((d: any) => +d.status_id === 1).length;
    const remainingScheduled = item.bulkSchedules.filter((d: any) => +d.status_id === 2).length;
    const remainingCanceled = item.bulkSchedules.filter((d: any) => +d.status_id === 3).length;
    return (
      <div className='w-[13rem] py-2 bg-purple-100 flex rounded-lg justify-center gap-2'>
        <div className={'flex gap-1 items-center'}>
          <Tooltip title='Approval Pending'>
            <i className='pi pi-clock mx-2 text-[#AC9059]'></i>
          </Tooltip>
          ({remainingApproval})
        </div>
        <div className={'flex gap-1 items-center'}>
          <Tooltip title='Approved Schedule'>
            <i className='pi pi-check-circle mx-2 text-[#62C649]'></i>
          </Tooltip>
          ({remainingScheduled})
        </div>
        <div className={'flex items-center gap-1  '}>
          <Tooltip title='Declined'>
            <i className='pi pi-times-circle mx-2 text-[#CC5555]'></i>
          </Tooltip>
          ({remainingCanceled})
        </div>
      </div>
    );
  }, []);

  const handleSingleRequestModal = useCallback(
    (item: any) => {
      if (item?.request_type === 'manual_dsp' && +item.status_id === 1) {
        setRequestModel({ open: true, data: item });
      } else {
        setRequestModel({ data: item, open: true });
      }
    },
    [requestModel]
  );

  const dataVisibleContent = useMemo(
    () => [
      (item: any) => (
        <div className='flex items-center justify-center  gap-1 w-[15rem]'>
          {!(item?.status_id === '1' && item.request_type === 'manual_dsp') && (
            <div className='w-[2rem] h-[2rem]'>
              <PriorityIcons iconType={item?.priority || '2'} />
            </div>
          )}
          {getRequestTypeData(item)}
        </div>
      ),
      (item: any) => (
        <button
          className='hover:bg-slate-100 p-2 rounded-lg border-none w-[10rem]'
          onClick={() => handleSingleRequestModal(item)}>
          {item.dsp.station_code}
        </button>
      ),
      (item: any) => (
        <p className='w-[6rem]'>
          <Tootltip title={item?.vehicle?.name} placement={'bottom'}>
            <p
              className='max-w-[6rem] text-ellipsis overflow-hidden'
              style={{ whiteSpace: 'nowrap' }}>
              {item?.vehicle?.name}
            </p>
          </Tootltip>
        </p>
      ),
      (item: any) => <hr className='rotate-90 border-slate-400' />,
      (item: any) => <p className='w-[12rem]'>{item?.vehicle?.vin}</p>,
      (item: any) => <p className='w-[6rem]'>{item?.miles || '---'}</p>,
      TillMiles,
      (item: any) => <p className='w-[5rem]'>{item?.curr_miles || '---'}</p>,
      (item: any) => <p className='w-[8rem]'>{item?.vehicle?.plate}</p>,
      (item: any) => <Menu options={menuHeads(item)} />,
    ],
    [requestModel]
  );

  const handleBulkRequestModal = useCallback(
    (item: any) => {
      if (item?.request_type === 'manual_dsp' && +item.status_id === 1) {
        setBulkInfoModal({ open: true, data: item });
      } else {
        setBulkInfoModal({
          ...bulkInfoModal,
          open: true,
          data: item,
        });
      }
    },
    [bulkInfoModal]
  );

  const bulkRowFormat = useMemo(
    () => [
      (item: any) => (
        <div className='flex items-center justify-center  gap-1 w-[15rem]'>
          {!(item?.status_id === '1' && item.request_type === 'manual_dsp') && (
            <div className='w-[2rem] h-[2rem]'>
              <PriorityIcons iconType={getBulkPriorityStatus(item)} />
            </div>
          )}
          {getRequestTypeBulkData(item)}
        </div>
      ),
      (item: any) => (
        <button
          className='hover:bg-slate-100 p-2 rounded-lg border-none w-[10rem]'
          onClick={() => handleBulkRequestModal(item)}>
          {item.dsp.station_code}
        </button>
      ),
      (item: any) => (
        <CustomTooltip
          title={
            <div className='capitalize'>
              {item?.bulkSchedules?.length > 0
                ? item?.bulkSchedules?.map((dataItem: any) => (
                    <div key={dataItem.vehicle.name}>{dataItem.vehicle.name}</div>
                  ))
                : 'loading...'}
            </div>
          }>
          <p className='w-[6rem] text-primary cursor-pointer'>
            Vehicles ({item?.bulkSchedules?.length})
          </p>
        </CustomTooltip>
      ),
      (item: any) => <hr className='rotate-90 border-slate-400' />,
      (item: any) => <p className='w-[12rem]'></p>,
      (item: any) => <p className='w-[8rem]'>{}</p>,
      (item: any) => <p className='w-[6rem]'>{}</p>,
      (item: any) => <p className='w-[5rem]'>{}</p>,
      (item: any) => <p className='w-[8rem]'>{}</p>,
      (item: any) =>
        item.status_id === '3' && item?.sub_stage === '2' && <Menu options={menuHeads(item)} />,
    ],
    [bulkInfoModal]
  );
  const requestData1 = useMemo(
    () => ({
      ...requestModel.data,
      setPartnerModel,
      handleReschedule: handleSchedule,
      setRequestModel,
      handleSchedule,
      cancelRequestbtn: (
        <CancelRequestModel
          item={requestModel.data}
          refetch={() => {
            setRequestModel({ open: false, data: {} });
            refetch();
          }}
        />
      ),
      refetch,
    }),
    [requestModel?.data]
  );

  const body6 = useMemo(
    () => [
      (item: any) => (
        <div className='w-full flex justify-between bg-[#F3F3F3] p-2 rounded-2xl'>
          <div className='w-full flex justify-between'>
            <Button
              variant='text'
              className='py-1 px-6 hover:bg-stone-200 text-blue-600 border border-blue-600 rounded-lg font-bold'
              onClick={() => setLeasePlanModal({ open: true, data: item })}>
              {item?.lease_plan ? 'View Lease Plan' : 'Lease Plan Update'}
            </Button>
            <Logs item={item} type='single' />
          </div>
        </div>
      ),
    ],
    []
  );
  return (
    <>
      <Table
        havebulkrow
        data={data}
        header={header}
        dataVisibleContent={dataVisibleContent}
        bulkrowformat={bulkRowFormat}
      />

      {requestModel.open && (
        <>
          <RequestModel
            handleClose={() => setRequestModel({ open: false, data: {} })}
            item={requestData1}
            body1={vehicleInfo}
            body6={body6}
            type={'schedule'}
          />
        </>
      )}
      {bulkInfoModal.open && (
        <BulkInfoModel
          handleClose={() => setBulkInfoModal({ open: false, data: {} })}
          data={bulkInfoModal?.data}
          refetch={refetch}
        />
      )}
      {leasePlanModal.open && (
        <LeasePlan
          handleClose={() => setLeasePlanModal({ open: false, data: {} })}
          item={leasePlanModal.data}
          refetch={refetch}
        />
      )}
      {vendorModal.open && (
        <AssignVendorModal
          data={vendorModal.data}
          handleClose={() => setVendorModal({ open: false, data: {} })}
          refetch={refetch}
        />
      )}
      {partnerModel?.open && (
        <AssignPartnerModel
          data={partnerModel?.data}
          handleClose={() => setPartnerModel({ open: false, data: {} })}
          refetch={refetch}
        />
      )}

      {showCancelModel.open && (
        <ModelContainerCancel
          handleClose={() => setShowCancelModel({ open: false, data: {} })}
          item={showCancelModel.data}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default Schedule;

import { SearchIcon } from '@heroicons/react/solid';
import { Avatar, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../../../components/OverlayContainer/Model';
import { RootState } from '../../../../state/reducers';
import SubmitAssignModal from './SubmitAssignModal';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import VendorAPI from '../../../../api/vendorApi';
import { ThreeDots } from 'react-loader-spinner';

const AssignVendorModal = ({ data, handleClose, refetch }: any) => {
  const dispatch = useDispatch();
  const { dspVendorData, user }: any = useSelector((state: RootState) => state.userState);
  const [vendorList, setVendorList] = useState<any[]>([]);
  const isPartnerLogin = user?.role === 'fleetmax_partner';
  const [selectedVendor, setSelectedVendor] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const { setDspVendorData } = bindActionCreators(actionCreators, dispatch);
  const [assignVendorModal, setAssignVendorModal] = useState({
    open: false,
    data: {},
  });
  const getVendorList = () => {
    setLoader(true);
    VendorAPI.getAllData({})
      .then((res) => {
        if (res.status === 200) {
          setDspVendorData({ dspVendorData: res.data.data });
        }
        setLoader(false);
      })
      .catch((err) => console.log(err));
  };
  const getpartnerVendorList = () => {
    setLoader(true);
    VendorAPI.getAllVendorUnderPartner({ id: data?.partner?.account_id })
      .then((res) => {
        if (res.status === 200) {
          setDspVendorData({ dspVendorData: res.data.data });
        }
        setLoader(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setLoader(true);
    if (!data?.partner?.account_id) {
      getVendorList();
    } else {
      getpartnerVendorList();
    }
  }, []);
  const allTechnicians: any[] = [];
  dspVendorData.forEach((d: any) => {
    if (d?.techicians) {
      allTechnicians.push(...d?.techicians);
    }
  });
  const addPartner: any = {
    vendor_account_id: user.account_id,
    vendor_name: user.name,
    techicians: allTechnicians,
  };
  const allvendors = isPartnerLogin ? [addPartner, ...dspVendorData] : [...dspVendorData];
  useEffect(() => {
    if (allvendors) {
      setVendorList(allvendors);
      if (data?.suggested_vendor && allvendors.length > 0) {
        const selectedOne = allvendors.find(
          (d: any) => d.vendor_account_id === data.suggested_vendor
        );
        setSelectedVendor(selectedOne);
      }
    } else {
      setVendorList([]);
    }
  }, [dspVendorData]);

  const handleSearch = (e: { target: { value: string } }) => {
    const target = e?.target?.value?.toLowerCase()?.trim();

    if (target === '') {
      setVendorList(allvendors);
    } else {
      setVendorList(
        allvendors.filter((item: any) => item?.vendor_name?.toLowerCase()?.trim()?.includes(target))
      );
    }
  };
  console.log(selectedVendor);
  return (
    <>
      <Modal widthclass='auto' heightClass='' handleClose={handleClose} noLabel>
        <div className='flex flex-col gap-5 h-[35rem]'>
          <div>
            <p className='text-lg'>Vendors</p>
          </div>

          <div className='flex justify-between items-center'>
            <div className='relative flex items-center w-[22rem]'>
              <SearchIcon style={{ width: '17px', position: 'absolute', marginLeft: '8px' }} />
              <input
                type='text'
                placeholder='Search...'
                onChange={handleSearch}
                className='border-solid border-2 border-gray p-1.5 pl-8 pt-2 rounded-xl w-full focus:outline-none focus:border-stone-400 transition ease-in duration-300'
              />
            </div>
            <button
              className='outline-none border-blue-400 py-2 px-4 text-blue-400 bg-slate-50 rounded-lg w-1/4 disabled:border-slate-100 disabled:text-stone-400'
              disabled={!selectedVendor.vendor_account_id}
              onClick={() => setAssignVendorModal({ open: true, data: selectedVendor })}>
              Assign Vendor
            </button>
          </div>
          {!loader && (
            <div className='flex flex-wrap gap-5 w-[45rem] max-h-[30rem] overflow-y-auto'>
              {vendorList.length > 0 ? (
                vendorList.map((item: any, index: number) => (
                  <Vendors
                    key={item?.vendor_account_id}
                    handleSelected={() => {
                      if (selectedVendor.vendor_account_id === item.vendor_account_id) {
                        setSelectedVendor({});
                      } else {
                        setSelectedVendor(item);
                      }
                    }}
                    item={item}
                    data={data}
                    selectedVendor={selectedVendor}
                  />
                ))
              ) : (
                <div className='w-full h-full grid place-items-center'>
                  <p>no data found</p>
                </div>
              )}
            </div>
          )}
          {loader && (
            <div className='w-[45rem] h-[30rem] flex justify-center items-center'>
              <ThreeDots
                height='30'
                width='50'
                radius='9'
                color={'#4378C8'}
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                visible={true}
              />
            </div>
          )}
        </div>
      </Modal>
      {assignVendorModal.open && (
        <SubmitAssignModal
          handleClose={() => {
            setAssignVendorModal({ open: false, data: {} });
            handleClose();
          }}
          handleBack={() => setAssignVendorModal({ open: false, data: {} })}
          handleDone={() => {
            setSelectedVendor({});
            refetch();
          }}
          item={assignVendorModal.data}
          vendorData={data}
        />
      )}
    </>
  );
};

const Vendors = ({ handleSelected, item, selectedVendor, data }: any) => {
  const checkboxRef = useRef<any>(null);
  const handleClick = () => {
    if (!data.suggested_vendor) {
      handleSelected();
    }
  };
  const ifExist =
    data.suggested_vendor === item?.vendor_account_id
      ? 'shadow-sm bg-slate-100'
      : 'border-transparent border-2 border-slate-50 text-gray-400';
  const disabledState = data.suggested_vendor
    ? ifExist
    : 'cursor-pointer border-transparent border-2 hover:border-slate-50 shadow-sm';
  const ifExistInput = data.suggested_vendor === item?.vendor_account_id ? false : true;
  const inputDisabled = data.suggested_vendor ? ifExistInput : false;
  return (
    <div
      className={`relative flex flex-col justify-center items-center gap-1 w-[10rem] h-[7rem] p-2 hover:bg-primary-light rounded-lg ${disabledState}`}
      onClick={handleClick}>
      <Avatar>{item.vendor_name.slice(0, 2).toUpperCase()}</Avatar>
      <Typography className='w-full text-center' noWrap>
        {item.vendor_name}
      </Typography>
      <input
        ref={checkboxRef}
        disabled={inputDisabled}
        checked={selectedVendor?.vendor_account_id === item?.vendor_account_id}
        type='checkbox'
        className='absolute top-[8px] right-[8px] w-[1.1rem] h-[1.1rem] rounded-full'
      />
    </div>
  );
};

export default AssignVendorModal;

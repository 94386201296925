import VehiclesIcon from '../../assets/VehiclesIcon';
import { Badge } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ChatIconDrawer from '../../assets/ChatIconDrawer';
import { RootState } from '../../state/reducers';
import { useSelector } from 'react-redux';
import Logout from '../../components/Buttons/Logout';
import { Avatar } from 'primereact/avatar';
import InProcessIcon from '../../assets/inProcessIcon';
import InspectIconSidebar from '../../assets/InspectIconSidebar';
import Tootltip from '../../components/BarData/Tootltip';
import DashBoardIcon from '../../assets/DashBoardIcon';
import InvoiceIcon from '../../assets/InvoiceIcon';
import ServiceIcon from '../../assets/ServiceIcon';
import CheklistIcon from '../../assets/CheklistIcon';
import getNameAlias from '../../pages/common/NameAlias';
import dayjs from 'dayjs';

function DrawerComps(type?: string) {
  const navaigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { user, unreadMessagesCount }: any = useSelector((state: RootState) => state.userState);
  const isVehiclesPage = window.location.href.includes('vendor/vehicle');
  const isVehiclesPageCSR = window.location.href.includes('csr/dashboard');
  const isPartnerPage = window.location.href.includes('partner/dashboard');
  const isVendorDashboard = window.location.href.includes('vendor/dashboard');
  const isPartnerVehiclePage = window.location.href.includes('partner/vehicle');
  const isVendorVehiclePage = window.location.href.includes('vendor/vehicle');
  const isInspectPage = window.location.href.includes('inspect/');
  const isTechnicianPage = window.location.href.includes('vendor/technician');
  const isTechnicianPagePartner = window.location.href.includes('partner/technician');
  const isQuestionnairePage = window.location.href.includes('/csr/inspection-questionnaire');
  const isVehicleChecklistPage = window.location.href.includes('/csr/vehicleChecklist');
  const isChatPage = window.location.href.includes('chat');
  const isInvoicePage = window.location.href.includes('invoice');
  const isServicePage = window.location.href.includes('serviceList');
  const [urlParams, setSearchParams] = useSearchParams();
  const isTech = isTechnicianPage || isTechnicianPagePartner;
  const isCSR: boolean = user?.role === 'fleetmax_csr';
  const isPartner: boolean = user?.role === 'fleetmax_partner';
  const isVendor: boolean = user?.role === 'fleetmax_vendor';
  const ChatBadge = () => {
    return (
      <Badge
        badgeContent={unreadMessagesCount?.allCount}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          color: 'red',
          '& .MuiBadge-badge': {
            backgroundColor: '#CC5555',
            color: 'white',
            boxShadow: '0px 1.26667px 2.53333px rgba(0, 0, 0, 0.25)',
            width: '1.4rem',
            height: '1.4rem',
            padding: '0.1rem 0.2rem',
            marginLeft: '0.2rem',
          },
        }}
        max={99}></Badge>
    );
  };
  const isVehicleSelected = () => {
    if (isPartner) {
      return isPartnerVehiclePage;
    }
    if (isVendor) {
      return isVendorVehiclePage;
    } else return isVehiclesPage || isVehiclesPageCSR;
  };
  const vehicleOnCLick = () => {
    if (!isVehiclesPage && !isVehiclesPageCSR) {
      if (isCSR) {
        navaigate(`csr/dashboard?type=pending&offset=1`);
      } else if (isPartner) {
        navaigate(`partner/vehicle?type=requests&offset=1`);
      } else if (isVendor) {
        navaigate(`vendor/vehicle?type=requests&offset=1`);
      }
    } else return null;
  };
  const inspectionOnCLick = () => {
    if (isPartner) {
      return navaigate(`partner/inspect/`);
    }
    if (!isInspectPage) {
      navaigate(`${isCSR ? 'csr' : 'vendor'}/inspect/`);
    }
  };
  const chatOnCLick = () => {
    if (!isChatPage) {
      if (isPartner) {
        return navaigate(`partner/chat`);
      } else if (!isPartner) {
        navaigate(`${isCSR ? 'csr' : 'vendor'}/chat`);
      }
    }
  };
  const invoiceOnCLick = () => {
    if (!isInvoicePage) {
      if (isPartner) {
        return navaigate(`partner/invoice`);
      } else if (!isPartner) {
        navaigate(`vendor/invoice`);
      }
    }
  };
  const serviceOnCLick = () => {
    if (!isServicePage) {
      if (isCSR) {
        return navaigate(`csr/serviceList`);
      }
    }
  };
  const navigateFromProfile = () => {
    if (isPartner) {
      return;
    }
    const basePath = `/${isCSR ? 'csr' : 'vendor'}`;
    const ifNotDashboard =
      pathname === `${basePath}/profile` || pathname === `${basePath}/cancel-requests`;
    if (ifNotDashboard) {
      navaigate(`${basePath}/dashboard`);
    } else {
      navaigate(`${basePath}/profile`);
    }
  };
  const getProfileStyle = () => {
    const basePath = `/${isCSR ? 'csr' : 'vendor'}`;
    const profilePath = `${basePath}/profile`;
    const notificationsPath = `${basePath}/notifications`;

    if (pathname === profilePath || pathname === notificationsPath) {
      return {
        background: 'rgba(0, 0, 0, 0.07)',
        cursor: 'pointer',
      };
    } else {
      return { cursor: 'pointer' };
    }
  };
  const weekOfYear = (date: dayjs.Dayjs) => {
    const startOfYear = date.startOf('year');
    const weekNumber = Math.ceil(date.diff(startOfYear, 'week', true)) + 1;
    return weekNumber;
  };
  const handleMenuItemClick = (time: string) => {
    const durationMap: { [key: string]: number } = {
      '4 Weeks': 4,
      '8 Weeks': 8,
      '10 Weeks': 10,
      '12 Weeks': 12,
    };

    const durationInWeeks = durationMap[time] || 4;
    const endDate = dayjs().startOf('week');
    const startDate = endDate.subtract(durationInWeeks - 1, 'week');

    const selectedWeeks: string[] = [];
    let currentDate = endDate;

    while (currentDate.isAfter(startDate, 'day') || currentDate.isSame(startDate, 'day')) {
      const weekNumber = weekOfYear(currentDate);
      const year = currentDate.year();
      selectedWeeks.unshift(`W${weekNumber}-${year}`);
      currentDate = currentDate.subtract(1, 'week');
    }

    const newParams = new URLSearchParams();
    newParams.set('page_number', '1');
    newParams.set('revenue_sort', 'desc');
    newParams.set(
      'weeks',
      JSON.stringify(
        selectedWeeks.map((item) => {
          const [weekNumber, year] = item.split('-');
          return `${year.toLowerCase()}-${weekNumber}`;
        })
      )
    );
    newParams.set('isWeek', 'true');
    if (urlParams?.get('vendor_ids')) {
      newParams.set('vendor_ids', JSON?.stringify([user?.account_id]) || '');
    }
    const newUrl = isPartner
      ? `/partner/dashboard/revenue?${newParams.toString()}`
      : `/vendor/dashboard/revenue?${newParams.toString()}`;
    navaigate(newUrl);
  };
  const comps = {
    dashboard: {
      icon: (
        <div
          className={`${
            (isPartnerPage || isVendorDashboard) && 'bg-primary-light'
          } p-2 rounded-full`}>
          <DashBoardIcon fill={isPartnerPage || isVendorDashboard ? '#4378C8' : '#595959'} />
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-3 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            (isPartnerPage || isVendorDashboard) && 'bg-primary-light'
          }`}
          onClick={() => {
            if (!isPartnerPage) navaigate(`partner/dashboard`);
            if (!isVendorDashboard) {
              // can remove this once dashboard page is implemented
              // handleMenuItemClick('10 Weeks');

              navaigate(`vendor/dashboard?type=requests&offset=1`);
            }
          }}>
          <p className='w-fit flex gap-2 justify-start items-center'>
            <DashBoardIcon fill={isPartnerPage || isVendorDashboard ? '#4378C8' : '#595959'} />{' '}
            <p
              className={`text-[1.1rem] text-[${
                isPartnerPage || isVendorDashboard ? '#4378C8' : '#595959'
              }]`}>
              {'Dashboard'}
            </p>
          </p>
        </div>
      ),
    },
    vehicles: {
      icon: (
        <div className={`${isVehicleSelected() && 'bg-primary-light'} p-2 rounded-full`}>
          <VehiclesIcon fill={isVehicleSelected() ? '#4378C8' : '#595959'} />
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-3 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            isVehicleSelected() && 'bg-primary-light'
          }`}
          onClick={vehicleOnCLick}>
          <p className='w-fit flex gap-2 justify-start items-center'>
            <VehiclesIcon fill={isVehicleSelected() ? '#4378C8' : '#595959'} />{' '}
            <p className={`text-[1.1rem] text-[${isVehicleSelected() ? '#4378C8' : '#595959'}]`}>
              Vehicles
            </p>
          </p>
        </div>
      ),
    },
    questionnaire: {
      icon: (
        <div
          className={`${
            isQuestionnairePage && 'bg-primary-light'
          } p-3 flex items-center justify-center rounded-full`}>
          <i
            className={`pi pi-question text-[1.5rem] font-bold ${
              isQuestionnairePage ? 'text-[#4378C8]' : 'text-[#595959]'
            } `}></i>
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-3 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            isQuestionnairePage && 'bg-primary-light'
          }`}
          onClick={() => {
            if (!isTechnicianPage) navaigate(`csr/inspection-questionnaire`);
          }}>
          <p className='w-fit flex gap-3 justify-start items-center items-center'>
            <i
              className={`pi pi-question text-[1.5rem] font-bold ${
                isQuestionnairePage ? 'text-[#4378C8]' : 'text-[#595959]'
              } `}></i>
            <Tootltip title={'Inspection Questionnaire'} placement={'bottom'}>
              <p
                className={`text-[1.1rem] max-w-fit text-ellipsis ${
                  window.innerWidth > 1276 ? 'whitespace-nowrap' : 'whitespace-normal'
                } overflow-hidden  text-[${isQuestionnairePage ? '#4378C8' : '#595959'}]`}>
                Inspection Questionnaire
              </p>
            </Tootltip>
          </p>
        </div>
      ),
    },
    vehiclesQuestionnaire: {
      icon: (
        <div
          className={`${
            isVehicleChecklistPage && 'bg-primary-light'
          } p-3 flex items-center justify-center rounded-full`}>
          <CheklistIcon fill={isVehicleChecklistPage ? '#4378C8' : '#595959'} />
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-3 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            isVehicleChecklistPage && 'bg-primary-light'
          }`}
          onClick={() => {
            if (!isTechnicianPage) navaigate(`csr/vehicleChecklist`);
          }}>
          <p className='w-fit flex gap-3 justify-start items-center items-center'>
            <CheklistIcon fill={isVehicleChecklistPage ? '#4378C8' : '#595959'} />
            <Tootltip title={'Vehicles Questionnaire'} placement={'bottom'}>
              <p
                className={`text-[1.1rem] max-w-fit text-ellipsis ${
                  window.innerWidth > 1276 ? 'whitespace-nowrap' : 'whitespace-normal'
                } overflow-hidden  text-[${isVehicleChecklistPage ? '#4378C8' : '#595959'}]`}>
                Vehicles Checklist
              </p>
            </Tootltip>
          </p>
        </div>
      ),
    },
    inspection: {
      icon: (
        <div className={`${isInspectPage && 'bg-primary-light'} p-2 rounded-full`}>
          <InspectIconSidebar
            height='2rem'
            width='2rem'
            fill={isInspectPage ? '#4378C8' : '#595959'}
          />
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-2 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            isInspectPage && 'bg-primary-light'
          }`}
          onClick={inspectionOnCLick}>
          <p className='w-fit flex gap-3 justify-start items-center items-center'>
            <InspectIconSidebar
              height='2rem'
              width='2rem'
              fill={isInspectPage ? '#4378C8' : '#595959'}
            />
            <p
              className={`text-[1.1rem]  whitespace-nowrap text-[${
                isInspectPage ? '#4378C8' : '#595959'
              }]`}>
              Vehicle Inspection
            </p>
          </p>
        </div>
      ),
    },
    technicians: {
      icon: (
        <div className={`${isTech && 'bg-primary-light'} p-3 rounded-full`}>
          <InProcessIcon fill={isTech ? '#4378c8' : '#595959'} width='1.7rem' height='1.7rem' />
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-3 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            isTech && 'bg-primary-light'
          }`}
          onClick={() => {
            if (!isTech)
              navaigate(
                `${isPartner || isTechnicianPagePartner ? 'partner' : 'vendor'}/technician`
              );
          }}>
          <p className='w-fit flex gap-4 justify-start items-center items-center'>
            <InProcessIcon fill={isTech ? '#4378c8' : '#595959'} width='1.7rem' height='1.7rem' />
            <p
              className={`text-[1.1rem]  whitespace-nowrap text-[${
                isTech ? '#4378C8' : '#595959'
              }]`}>
              Technicians
            </p>
          </p>
        </div>
      ),
    },
    chat: {
      icon: (
        <div
          className={`flex justify-between text-[1.3rem] items-center text-primary ${
            isChatPage && 'bg-primary-light'
          } hover:bg-primary-light p-2  rounded-full font-bold`}>
          <ChatIconDrawer height='2rem' width='2rem' fill={isChatPage ? '#4378C8' : '#595959'} />
          {ChatBadge()}
        </div>
      ),
      content: (
        <div
          className={` w-full flex items-center px-2 gap- cursor-pointer rounded-full hover:bg-primary-light py-3 ${
            isChatPage && 'bg-primary-light'
          }`}
          onClick={chatOnCLick}>
          <ChatIconDrawer
            height='2.1rem'
            width='2.1rem'
            fill={isChatPage ? '#4378C8' : '#595959'}
          />
          {ChatBadge()}
          <p className={`text-[1.1rem]  ml-3 text-${isChatPage ? '[#4378C8]' : '[#595959]'}`}>
            Chat
          </p>
        </div>
      ),
    },
    invoice: {
      icon: (
        <div
          className={`${
            isInvoicePage && 'bg-primary-light'
          } flex items-center justify-center p-3 rounded-full`}>
          <InvoiceIcon fill={isInvoicePage ? '#4378C8' : '#595959'} />
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-3 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            isInvoicePage && 'bg-primary-light'
          }`}
          onClick={invoiceOnCLick}>
          <p className='w-fit flex gap-2 justify-start items-center'>
            <InvoiceIcon fill={isInvoicePage ? '#4378C8' : '#595959'} />{' '}
            <p
              className={`text-[1.1rem] whitespace-nowrap text-[${
                isInvoicePage ? '#4378C8' : '#595959'
              }]`}>
              Invoice Management
            </p>
          </p>
        </div>
      ),
    },
    service: {
      icon: (
        <div
          className={`${
            isServicePage && 'bg-primary-light'
          } flex items-center justify-center p-3 rounded-full`}>
          <ServiceIcon fill={isServicePage ? '#4378C8' : '#595959'} />
        </div>
      ),
      content: (
        <div
          className={`w-full flex px-3 cursor-pointer rounded-full hover:bg-primary-light py-4 ${
            isServicePage && 'bg-primary-light'
          }`}
          onClick={serviceOnCLick}>
          <p className='w-fit flex gap-2 justify-start items-center'>
            <ServiceIcon fill={isServicePage ? '#4378C8' : '#595959'} />{' '}
            <p
              className={`text-[1.1rem] whitespace-nowrap text-[${
                isServicePage ? '#4378C8' : '#595959'
              }]`}>
              Service List
            </p>
          </p>
        </div>
      ),
    },
  };
  const renderIcons = () => {
    if (user?.role === 'fleetmax_csr') {
      return (
        <>
          {comps?.vehicles?.icon}
          {comps?.vehiclesQuestionnaire?.icon}
          {comps?.service?.icon}
          {comps?.questionnaire?.icon}
          {comps?.inspection?.icon}
          {comps?.chat?.icon}
        </>
      );
    } else if (user?.role === 'fleetmax_vendor') {
      return (
        <>
          {comps?.dashboard?.icon}
          {comps?.vehicles?.icon}
          {comps?.inspection?.icon}
          {comps?.technicians?.icon}
          {comps?.invoice?.icon}
          {comps?.chat?.icon}
        </>
      );
    } else if (user?.role === 'fleetmax_partner') {
      return (
        <>
          {comps?.dashboard?.icon}
          {comps?.vehicles?.icon}
          {comps?.technicians?.icon}
          {comps?.invoice?.icon}
          {comps?.chat?.icon}
        </>
      );
    } else return null;
  };
  const renderContent = () => {
    if (user?.role === 'fleetmax_csr') {
      return (
        <>
          {comps?.vehicles?.content}
          {comps?.vehiclesQuestionnaire?.content}
          {comps?.service?.content}
          {comps?.questionnaire?.content}
          {comps?.inspection?.content}
          {comps?.chat?.content}
        </>
      );
    } else if (user?.role === 'fleetmax_vendor') {
      return (
        <>
          {comps?.dashboard?.content}
          {comps?.vehicles?.content}
          {comps?.inspection?.content}
          {comps?.technicians?.content}
          {comps?.invoice?.content}
          {comps?.chat?.content}
        </>
      );
    } else if (user?.role === 'fleetmax_partner') {
      return (
        <>
          {comps?.dashboard?.content}
          {comps?.vehicles?.content}
          {comps?.technicians?.content}
          {comps?.invoice?.content}
          {comps?.chat?.content}
        </>
      );
    } else return null;
  };
  const userAvatar = () => {
    return (
      <Avatar className='bg-primary-light text-primary h-[3rem] w-[3rem] text-[1.7rem] rounded-full border-[0.2rem] border-[#E8EEF8] '>
        {getNameAlias(user?.name)}
      </Avatar>
    );
  };
  const userIcon = () => {
    return (
      <>
        <div className='w-full flex justify-center items-center py-5 border-t-[0.1rem] border-gray-300'>
          {userAvatar()}
        </div>
      </>
    );
  };
  const userContent = () => {
    return (
      <>
        <div
          className='flex items-center justify-between gap-4 h-fit w-[90%] mb-3 p-3 rounded-[0.5rem] whitespace-nowrap hover:bg-primary-lighter'
          style={getProfileStyle()}>
          <div className='flex gap-2 items-center ' onClick={navigateFromProfile}>
            {userAvatar()}
            <Tootltip title={user?.name || ''} placement={'bottom'}>
              <p className='text-xl max-w-[8rem] text-ellipsis overflow-hidden capitalize'>
                {user?.name || ''}
              </p>
            </Tootltip>
          </div>
          <Logout />
        </div>
      </>
    );
  };
  if (type === 'icons') {
    return <>{renderIcons()}</>;
  } else if (type === 'content') {
    return <>{renderContent()}</>;
  } else if (type === 'userIcon') {
    return <>{userIcon()}</>;
  } else if (type === 'userContent') {
    return <>{userContent()}</>;
  } else return null;
}

export default DrawerComps;

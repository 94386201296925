import React from 'react';
import OverlayContainer from '../../../components/OverlayContainer';
import ModalForm from '../../../components/ModalForm';
import { Button } from '@mui/material';

function ProceedWithoutProofs({ props }: any) {
  const { showModel, setShowModel, onClick } = props;
  return (
    <OverlayContainer show={true}>
      <ModalForm
        title={'Add CSR'}
        onClose={() => {
          setShowModel({ show: false, data: '' });
        }}
        width={'w-[25vw]'}
        noLabel>
        <div className=' flex flex-col gap-3 justify-between h-[15rem]'>
          <p className='text-[1.1rem] font-normal w-full flwx items-center justify-center text-[1.3rem]'>
            Accept Proofs
          </p>
          <div className='flex flex-col gap-2'>
            <p className='text-[1.1rem] font-normal'>
              Are you sure you want to move forward without adding proofs to skipped services?
            </p>
            <div className='w-full flex flex-col'>
              <p className='text-gray-400 text-[0.8rem] flex justify-between w-full pl-4'>
                Reason
                {showModel?.data?.length >= 251 && (
                  <span className='flex gap-2 text-[#C55]'>
                    Please enter reason within 250 charecters!
                  </span>
                )}
              </p>
              <input
                className='w-full h-[3rem] rounded-[1rem] px-3 p-1 order transition-colors duration-300 outline-none focus:border-blue-500 border-1'
                type='text'
                value={showModel?.data}
                onChange={(e) => {
                  setShowModel({ show: true, data: e.target.value });
                }}
                placeholder='Type your reason...'
              />
            </div>
            <div className='flex justify-center'>
              <Button
                className='w-[7rem] font-bold'
                variant='contained'
                disabled={showModel?.data?.length >= 251 || showModel?.data?.length === 0}
                onClick={() => {
                  onClick();
                }}>
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </ModalForm>
    </OverlayContainer>
  );
}

export default ProceedWithoutProofs;

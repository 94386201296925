import { Tooltip } from '@mui/material';
import CrossButtons from '../../../../../assets/CrossButton';
import TickButton from '../../../../../assets/TickButton';

export function renderPriceWarning(service: any, updateVendorInvoice: any) {
  if ((updateVendorInvoice && service?.disputed_amount) || service?.disputed_rate) {
    return (
      <div className='flex justify-center items-center '>
        <Tooltip
          title={`The disputed amount is $
          ${service?.disputed_amount || service?.disputed_rate || ''}`}
          placement='bottom'>
          <i className='pi pi-exclamation-circle mr-1 text-[#C55]'></i>
        </Tooltip>
      </div>
    );
  }
  if (updateVendorInvoice && service.vendor_price && service.price !== service.vendor_price) {
    return (
      <div className='flex justify-center items-center '>
        <p className='w-fit flex justify-center items-center bg-[#CC555514] text-[#C55] py-1 rounded-[0.5rem] text-[0.8rem] px-3'>
          {' '}
          <i className='pi pi-exclamation-circle mr-2'></i>The amount should be $
          {service.vendor_price || ''}
        </p>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export function showAmount(service: any, invoiceDetails: any, invoices: any, tax: any) {
  const totalPriceWithoutTax = Number(service?.rate || 0) * Number(service.qty || 0);

  return <>{<>{totalPriceWithoutTax.toFixed(2) || 0.0}</>}</>;
}

export function invoiceHeadings() {
  const headings = [
    { name: 'Service Date', width: '10%' },
    { name: 'Product/Service', width: '15%' },
    { name: 'Description', width: '25%' },
    { name: 'Product Code', width: '10%' },
    { name: 'Rate', width: '10%' },
    { name: 'Qty', width: '5%' },
    { name: 'Amount', width: '10%' },
    { name: 'Tax', width: '3%' },
    { name: '', width: '3%' },
  ];
  return (
    <div className='w-full flex justify-between gap-2 p-3 border-b-[0.2rem] font-bold sticky top-0 bg-white z-10'>
      {headings.map((heading, index) => (
        <div key={index} className={`w-[${heading.width}] flex items-center justify-center`}>
          {heading.name}
        </div>
      ))}
    </div>
  );
}
export const DecimalInput = ({ value, onChange, disabled, placeholder }: any) => {
  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
      onChange(inputValue);
    }
  };
  return (
    <input
      type='text'
      value={value}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
      className='w-[80%] h-[2.1rem] text-right rounded-r-[0.40rem] pr-2 focus:border-primary focus:outline-none text-bold'
    />
  );
};
export function AddNewService({ onChange, props }: any) {
  const { newService, addNewService, CrossButton } = props;
  return (
    <div className='p-3 border-b-[0.2rem] '>
      <div className='flex justify-between'>
        <div>
          <input
            type='text'
            placeholder='Enter Service Here'
            className='w-[20rem] border bg-gray-100 px-2 py-1 rounded-[0.5rem] focus:outline-none focus:border-primary border-transparent'
            autoFocus
            value={newService.name}
            onChange={onChange}
          />
        </div>
        <div className='flex gap-7'>
          <button
            className='text-[#4378C8] border-transparent ml-3 rounded-full'
            onClick={addNewService}>
            <TickButton />
          </button>
          <button className='text-[#4378C8] border-transparent rounded-full' onClick={CrossButton}>
            <CrossButtons />
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4, v4 } from 'uuid';
import { RootState } from '../../state/reducers';
import CalenderDropdown from '../../components/DropDowns/CalenderDropdown';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state';
import { useDispatch, useSelector } from 'react-redux';
import useFetchStations from '../hooks/useFetchStations';
import StationCodeDropDown from '../../components/DropDowns/StationCodeDropDown';
import { getFormattedDateTable as getFormattedDate } from '../csr/chat/components/getFormatedDate';
import { getActions } from './TableComps';
import { Checkbox, Tooltip } from '@mui/material';
import RequestSelectionRow from './RequestSelectionRow';
const getRowInfo = (item: any) => {
  let groupedBulk = false;
  if ([1, 4].includes(+item?.status_id)) {
    if (item?.bulk_id) {
      groupedBulk = true;
    }
  }
  return groupedBulk;
};
function getDate(item: any) {
  if (item?.bulkSchedules && item?.bulkSchedules?.length > 0) {
    return item?.bulkSchedules?.[0]?.appointments?.[0]?.date;
  } else {
    return item?.appointments?.[0]?.date;
  }
}
function RedirectSVG() {
  const navigate = useNavigate();
  const [urlParams]: any = useSearchParams();
  const { user }: any = useSelector((state: RootState) => state.userState);
  const isVendor: boolean = user?.role === 'fleetmax_vendor';
  const redirect = () => {
    if (isVendor) navigate(`/vendor/vehicle?type=${urlParams.get('type')}&offset=1`);
    else navigate(`/partner/vehicle?type=${urlParams.get('type')}&offset=1`);
  };
  return (
    <Tooltip
      title={`Redirect to ${
        urlParams?.get('type')?.charAt(0)?.toUpperCase() + urlParams?.get('type')?.slice(1)
      } Tab`}>
      <div className='cursor-pointer'>
        <svg
          onClick={() => {
            redirect();
          }}
          width='1.5rem'
          height='1.5rem'
          viewBox='0 0 28 28'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <mask
            id='mask0_17465_40061'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='28'
            height='28'>
            <rect width='28' height='28' fill='#D9D9D9' />
          </mask>
          <g mask='url(#mask0_17465_40061)'>
            <path
              d='M14 3.5C15.4389 3.5 16.7951 3.77708 18.0687 4.33125C19.3424 4.88542 20.4556 5.63889 21.4083 6.59167C22.3611 7.54444 23.1146 8.65764 23.6687 9.93125C24.2229 11.2049 24.5 12.5611 24.5 14C24.5 15.4389 24.2229 16.7951 23.6687 18.0687C23.1146 19.3424 22.3611 20.4556 21.4083 21.4083C20.4556 22.3611 19.3424 23.1146 18.0687 23.6687C16.7951 24.2229 15.4389 24.5 14 24.5C13.2028 24.5 12.4347 24.4125 11.6958 24.2375C10.9569 24.0625 10.2181 23.8097 9.47917 23.4792L11.2583 21.7C11.7056 21.8556 12.1576 21.9722 12.6146 22.05C13.0715 22.1278 13.5333 22.1667 14 22.1667C16.2556 22.1667 18.1806 21.3694 19.775 19.775C21.3694 18.1806 22.1667 16.2556 22.1667 14C22.1667 11.7444 21.3694 9.81944 19.775 8.225C18.1806 6.63056 16.2556 5.83333 14 5.83333C11.7444 5.83333 9.81944 6.63056 8.225 8.225C6.63056 9.81944 5.83333 11.7444 5.83333 14C5.83333 14.4667 5.87222 14.9285 5.95 15.3854C6.02778 15.8424 6.14444 16.2944 6.3 16.7417L4.55 18.4917C4.2 17.7917 3.9375 17.0674 3.7625 16.3187C3.5875 15.5701 3.5 14.7972 3.5 14C3.5 12.5611 3.77708 11.2049 4.33125 9.93125C4.88542 8.65764 5.63889 7.54444 6.59167 6.59167C7.54444 5.63889 8.65764 4.88542 9.93125 4.33125C11.2049 3.77708 12.5611 3.5 14 3.5ZM15.1667 18.6667V14.4667L5.13333 24.5L3.5 22.8667L13.5333 12.8333H9.33333V10.5H17.5V18.6667H15.1667Z'
              fill='#699A00'
            />
          </g>
        </svg>
      </div>
    </Tooltip>
  );
}
const Table = ({
  data,
  header,
  dataVisibleContent,
  colSpan,
  bulkrowformat,
  noCalendar,
}: {
  data: any[];
  header: any[];
  dataVisibleContent: any[];
  colSpan?: any[];
  havebulkrow?: boolean;
  bulkrowformat?: any[];
  noCalendar?: boolean;
}) => {
  const dispatch = useDispatch();
  const { stations } = useFetchStations();
  const scrollableDivRef: any = useRef(null);
  const [isSpaceLeft, setIsSpaceLeft] = useState(false);
  const { setMultReq } = bindActionCreators(actionCreators, dispatch);
  const { csrDataInfo, vendorDataInfo, user, assignTechBulk }: any = useSelector(
    (state: RootState) => state.userState
  );
  const hasMoreData: boolean = csrDataInfo?.hasMoreData || vendorDataInfo?.hasMoreData;
  const [urlParams, setSearchParams]: any = useSearchParams();
  const { redirectRequest, multiSelectReq } = useSelector((state: RootState) => state.userState);
  const isVendor: boolean = user.role === 'fleetmax_vendor';
  const isPartner: boolean = user.role === 'fleetmax_partner';
  const isRequestsTab = window.location.href.includes('requests');
  const isAcceptedTab = window.location.href.includes('accepted');
  const isPartenerDashboard = window.location.href.includes('partner');
  const isvendorDashboard =
    window.location.href.includes('vendor/dashboard') ||
    window.location.href.includes('partner/dashboard');
  const [filterType, setFilterType] = useState('');

  const checkRedirect = (item: any) => {
    let check = false;
    const groupedBulk = getRowInfo(redirectRequest);
    if (redirectRequest) {
      if (groupedBulk) {
        if (redirectRequest?.bulk_id && item?.bulk_id) {
          check = +redirectRequest.bulk_id === +item.bulk_id;
        }
      } else {
        check = +redirectRequest?.preventive_request_id === +item?.id;
      }
    }
    return check ? 'animate-[colorAnimation_0.5s_ease-in-out_infinite]' : 'bg-white';
  };
  useEffect(() => {
    let element: any = '';
    if (
      redirectRequest?.preventive_request_id ||
      redirectRequest?.bulk_id ||
      redirectRequest?.status_id
    ) {
      const groupedBulk = getRowInfo(redirectRequest);
      if (groupedBulk) {
        element = document.getElementById(redirectRequest.bulk_id + 'bulk_request_row');
      } else {
        element = document.getElementById(redirectRequest.preventive_request_id + 'requestRow');
      }
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [redirectRequest, data]);
  const generateClassName = (
    item: any,
    index: number,
    array: any[],
    isFirstEleofSameDate?: boolean,
    isLastEleofSameDate?: boolean
  ): string => {
    return `flex justify-center items-center h-[6rem]  
    ${selectedReqColor(item)}
    ${index === 0 ? 'border-l-2' : ''} 
    ${index === array.length - 1 ? 'border-r-2' : ''} 
    ${isFirstEleofSameDate ? 'border-t-2' : ''} 
    border-b-2 border-slate-200 
    ${isFirstEleofSameDate && index === 0 ? 'rounded-tl-[1rem]' : ''}  
    ${isFirstEleofSameDate && index === array.length - 1 ? 'rounded-tr-[1rem]' : ''}
    ${isLastEleofSameDate && index === 0 ? 'rounded-bl-[1rem]' : ''} 
    ${isLastEleofSameDate && index === array.length - 1 ? 'rounded-br-[1rem]' : ''}`;
  };
  const RowType = (item: any, parentindex: number) => {
    let mapArr = item?.bulk_id ? bulkrowformat : dataVisibleContent;
    return mapArr?.map((elem: any, index: number) => (
      <td
        key={uuidv4()}
        className={`child:m-auto`}
        style={{
          borderBottomRightRadius:
            parentindex + 1 === data.length && index + 1 === dataVisibleContent.length
              ? '1rem'
              : '0',
        }}>
        {typeof elem === 'string' ? item[elem] : elem(item, index)}
      </td>
    ));
  };

  const multiSelectLogic = (item: any) => {
    return (
      multiSelectReq &&
      multiSelectReq?.show && (
        <td key={uuidv4()} className={`child:m-auto`}>
          <Checkbox
            checked={!!multiSelectReq?.data?.find((d: any) => +d?.id === +item?.id)}
            onClick={() => {
              const isItemPresent = multiSelectReq?.data?.some((d: any) => +d?.id === +item?.id);
              if (isItemPresent) {
                const updatedSchedules = multiSelectReq?.data?.filter(
                  (d: any) => +d?.id !== +item?.id
                );
                setMultReq({
                  ...multiSelectReq,
                  data: updatedSchedules,
                });
              } else {
                const current = multiSelectReq?.data || [];
                setMultReq({
                  ...multiSelectReq,
                  data: [...current, item],
                });
              }
            }}
          />
        </td>
      )
    );
  };

  const RowTypeAcceptedRequests = (
    item: any,
    parentindex: number,
    isLastEleofSameDate?: boolean,
    isFirstEleofSameDate?: boolean
  ) => {
    let mapArr = item?.bulk_id ? bulkrowformat : dataVisibleContent;
    return mapArr?.map((elem: any, index: number, array: any[]) => (
      <>
        <td
          key={uuidv4()}
          className={`child:m-auto h-[6rem] child:table-collapse p-0`}
          style={{
            borderBottomRightRadius:
              parentindex + 1 === data.length && index + 1 === dataVisibleContent.length
                ? '1rem'
                : '0',
          }}>
          <div
            className={`child:m-auto h-[6rem] child:table-collapse p-0 ${generateClassName(
              item,
              index,
              array,
              isFirstEleofSameDate,
              isLastEleofSameDate
            )}`}>
            {typeof elem === 'string' ? item[elem] : elem(item, index)}
          </div>
        </td>
      </>
    ));
  };

  const TimeandReqTypeRow = (item?: any, parentindex?: number) => {
    let mapArr = dataVisibleContent;
    return mapArr?.map((elem: any, index: number, array: any[]) => (
      <>
        {index === 0 && (
          <>
            {(isVendor || isPartenerDashboard) && (isRequestsTab || isAcceptedTab) ? (
              <td
                className={`  pl-2 items-center w-fit py-3  h-[2rem] break-all bg-white text-[0.9rem]`}>
                {getFormattedDate(getDate(item))}
              </td>
            ) : (
              <td
                className={`  pl-2 items-center w-fit py-3  h-fit break-all bg-white text-[0.9rem]`}></td>
            )}
          </>
        )}
        {index !== 0 && index !== array.length - 1 && (
          <td
            className={`  pl-2 items-center w-fit py-3  h-fit break-all bg-white text-[0.9rem]`}></td>
        )}
        {index === array.length - 1 && parentindex === 0 && (
          <td className='w-fit'>
            <div className='w-fit'></div>
          </td>
        )}
      </>
    ));
  };
  const getRowId = (item: any) => {
    const groupedBulk = getRowInfo(item);
    if (groupedBulk) {
      return item.bulk_id + 'bulk_request_row';
    }
    return item.id + 'requestRow';
  };

  useEffect(() => {
    if (data.length > 0) {
      const parentDiv = scrollableDivRef.current;
      const childDivs = parentDiv.querySelectorAll('.child');
      let totalChildHeight = 0;
      childDivs.forEach((childDiv: any) => {
        totalChildHeight += childDiv.clientHeight;
      });
      // 94 -> removing header height
      if (totalChildHeight < parentDiv.clientHeight - 94) {
        setIsSpaceLeft(true);
      } else {
        setIsSpaceLeft(false);
      }
    }
  }, [data]);

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    const handleScroll = () => {
      if (scrollableDiv.scrollTop + scrollableDiv.clientHeight + 1 >= scrollableDiv.scrollHeight) {
        if (hasMoreData) {
          urlParams.set('offset', +urlParams?.get('offset') + 1);
          setSearchParams(urlParams);
        }
      }
    };
    scrollableDiv?.addEventListener('scroll', handleScroll);
    return () => {
      scrollableDiv?.removeEventListener('scroll', handleScroll);
    };
  }, [urlParams, hasMoreData]);

  useEffect(() => {
    if (isSpaceLeft && hasMoreData) {
      urlParams.set('offset', +urlParams?.get('offset') + 1);
      setSearchParams(urlParams);
    }
  }, [hasMoreData]);
  const selectedReqColor = (item: any) => {
    const isBulk = !!item?.bulk_id;
    const idToCheck = isBulk ? item?.bulk_id : item?.id;
    const isScceptedPage = urlParams.get('type') === 'accepted';

    const isItemSelected = assignTechBulk?.schedules?.some((items: any) =>
      isBulk ? items?.bulk_id === idToCheck : items?.id === idToCheck
    );

    return isItemSelected && isScceptedPage ? 'bg-[#E8EEF8]' : `${checkRedirect(item)}`;
  };
  const headerUpdated = multiSelectReq.show ? ['', ...header] : header;
  const showStationCode = (item?: any) => {
    let content;

    if (typeof item === 'string') {
      content = item;
    } else if (item?.() === 'showDspShortCode') {
      content = (
        <div className='w-[10rem]'>
          <StationCodeDropDown options={stations} />
        </div>
      );
    } else {
      content = item?.();
    }

    return <>{content}</>;
  };

  const element = (ref?: any) => {
    return (
      <>
        <table className='w-full rounded-[1rem] border-1 border-border-color '>
          <thead className='bg-tableHead sticky top-0 z-10 rounded-[1rem]'>
            <tr className='child:p-2 child:py-3 child:font-medium child:break-words child:text-center '>
              {headerUpdated.map((item: any, index: number) => {
                const cSpan = colSpan?.find((elem) => elem[0] === index);
                return (
                  <th
                    key={v4()}
                    colSpan={cSpan ? cSpan[1] : 1}
                    className={`text-[#4378C8] child:m-auto child:font-semibold  `}>
                    {showStationCode(item)}
                  </th>
                );
              })}
              {!noCalendar && (
                <td className='flex gap-2 items-center ' style={{ borderTopRightRadius: '1rem' }}>
                  <div className='w-full flex justify-end items-center gap-4'>
                    <CalenderDropdown filterType={filterType} setFilterType={setFilterType} />
                    {isvendorDashboard && <RedirectSVG />}
                  </div>
                </td>
              )}
            </tr>
          </thead>
          <thead className=''>
            <tr className=' child:font-medium child:text-center relative'>
              <RequestSelectionRow />
            </tr>
          </thead>
          {isVendor && (isAcceptedTab || isRequestsTab) ? (
            <tbody className={`${isvendorDashboard ? 'h-[10rem] overflow-auto' : ''}`}>
              {data.map((item: any, parentindex: number, array: any[]) => {
                const actions = getActions({ item, parentindex, array });
                const { showDate, isFirstEleofSameDate, isLastEleofSameDate } = actions;
                return (
                  <React.Fragment key={v4()}>
                    {showDate && (
                      <>
                        {/*time and heading space */}
                        <tr
                          className={` child:text-center table-collapse  h-[4rem] z-0 break-all child`}
                          style={{ borderCollapse: 'collapse' }}
                          id={getRowId(item)}
                          key={item}>
                          {TimeandReqTypeRow(item, parentindex)}
                        </tr>
                      </>
                    )}
                    <tr
                      className={` child:text-center table-collapse  h-[4rem] break-all  ${checkRedirect(
                        item
                      )} child`}
                      style={{ borderCollapse: 'collapse' }}
                      id={getRowId(item)}>
                      {multiSelectLogic(item)}
                      {RowTypeAcceptedRequests(
                        item,
                        parentindex,
                        isLastEleofSameDate,
                        isFirstEleofSameDate
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          ) : (
            <>
              <tbody
                // className={`bg-tableHead rounded-tl-2xl rounded-tr-2xl ${
                className={`rounded-tl-2xl rounded-tr-2xl ${
                  isvendorDashboard ? 'h-[10rem] overflow-auto' : ''
                }`}>
                {data.map((item: any, parentindex: number) => (
                  <>
                    {(isVendor || isPartner) && parentindex === 0 && (
                      <>
                        {/* heading space */}
                        <tr className={`h-[4rem] z-5`} id={getRowId(item)}></tr>
                      </>
                    )}
                    <tr
                      key={v4()}
                      className={`child:text-center child:py-2 border-b-2 border-t-2 border-solid border-slate-100 h-[4rem] break-all ${checkRedirect(
                        item
                      )} child`}
                      id={getRowId(item)}>
                      {multiSelectLogic(item)}
                      {RowType(item, parentindex)}
                    </tr>
                  </>
                ))}
              </tbody>
            </>
          )}
        </table>
        {data.length === 0 && (
          <div className='w-full grid place-items-center my-4'>
            <p className='text-[1.1rem] text-gray-500 flex gap-2 items-center  '>
              <i className='pi pi-info-circle'></i>No Data Found!{' '}
            </p>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      {isvendorDashboard ? (
        <div className='w-full relative h-[30rem] border-[0.1rem] p-2 rounded-[0.5rem] border-gray-300 bg-white'>
          <div
            style={{ borderTopRightRadius: '5rem' }}
            className='w-full relative h-full overflow-auto rounded-t-[0.5rem] rounded-tr-[1.7rem] mr-5'
            ref={scrollableDivRef}
            id={'myTable'}>
            {element()}
          </div>
        </div>
      ) : (
        <div
          className={`overflow-auto ${isvendorDashboard ? '' : 'h-0'} flex-1 pr-1  rounded-[1rem]`}
          ref={scrollableDivRef}
          id={'myTable'}>
          {element(scrollableDivRef)}
        </div>
      )}
    </>
  );
};

export default Table;

import { useEffect, useMemo, useState } from 'react';
import ImagePreview from '../../../common/ImageModal';
import WorkProofModal from './WorkProofModal';
import { Tooltip } from '@mui/material';
import { PlayVideoSVg } from '../../primaryComponents/SubmitProofModel';

const WorkProofPreview = ({
  checklistData,
  item,
  setWorkProofData,
}: {
  checklistData: any[];
  item: any;
  setWorkProofData?: (item: boolean) => void;
}) => {
  const [proofsData, setProofsData] = useState<any>({
    open: false,
    data: [],
    selected_id: null,
  });
  const [workProofModal, setWorkProofModal] = useState({ open: false, data: {} } as {
    open: boolean;
    data: any;
  });
  const [Proofs, setProofs] = useState<any[]>([]);

  const TotalProofs = useMemo(() => {
    let tempProofs: any[] = [];
    const result = checklistData?.reduce((acc, item) => {
      if (item?.proofs) {
        tempProofs.push(
          ...(item?.proofs && item?.proofs?.length > 0
            ? item.proofs.map((d: any) => ({ ...d, heading: item?.name }))
            : [])
        );
      }
      if (item?.titles?.length > 0) {
        item.titles.forEach((title: any) => {
          if (title.proofs?.length > 0) {
            tempProofs.push(...title.proofs.map((d: any) => ({ ...d, heading: title.title })));
          }
        });
      }
      const proofsCount =
        (item?.proofs?.length || 0) +
        (item?.titles?.reduce(
          (titleAcc: any, title: any) => titleAcc + (title?.proofs?.length || 0),
          0
        ) || 0);

      return acc + proofsCount;
    }, 0);
    setProofs(tempProofs);
    return result;
  }, [checklistData]);

  useEffect(() => {
    if (proofsData?.open) {
      setProofsData((prev: any) => ({ ...prev, data: Proofs }));
    }
  }, [Proofs]);
  if (!item || +item?.status_id < 7 || (+item?.status_id === 10 && +item?.sub_stage === 1)) {
    return null;
  }
  return (
    <div className='bg-primary-light p-2 rounded-lg  flex flex-col gap-4 max-h-[8rem]'>
      <div
        className='flex gap-2 text-primary font-bold text-[1rem] items-center cursor-pointer'
        onClick={() => {
          setWorkProofData?.(true);
        }}>
        <p>Work Proofs</p>
        <i className={`pi pi-angle-right`}></i>
      </div>
      {TotalProofs ? (
        <div className='grid grid-cols-10 w-full gap-1 overflow-auto h-0 flex-1'>
          {Proofs.map((d) => (
            <Tooltip title={`${d.name || d?.proof_name || 'image'}`} key={d?.proof_id}>
              <div>
                {d.proof_type === 'Image' ? (
                  <img
                    key={d?.file_url}
                    src={d.file_url}
                    onClick={() => {
                      setProofsData({
                        open: true,
                        data: Proofs,
                        selected_id: d?.proof_id,
                      });
                    }}
                    style={{
                      width: '1.8rem',
                      height: '1.8rem',
                    }}
                    className='cursor-pointer'
                    alt='ProofPng'></img>
                ) : (
                  <div className='relative flex items-center justify-center z-10'>
                    <div className='relative bg-gray-400 rounded-[0.2rem]'>
                      <div
                        className='absolute top-[0.17rem] right-[0.17rem] flex justify-center items-center cursor-pointer z-30'
                        onClick={() => {
                          setProofsData({
                            open: true,
                            data: Proofs,
                            selected_id: d?.proof_id,
                          });
                        }}>
                        <PlayVideoSVg height='1.45rem' width='1.45rem' />
                      </div>
                      <video
                        src={d.file_url}
                        onClick={() => {
                          setProofsData({
                            open: true,
                            data: Proofs,
                            selected_id: d?.proof_id,
                          });
                        }}
                        controls={false}
                        className='w-[1.8rem] h-[1.8rem] rounded-xl object-contain overflow-hidden z-50'
                        style={{ filter: 'brightness(0.5)' }}></video>
                    </div>
                  </div>
                )}
              </div>
            </Tooltip>
          ))}
        </div>
      ) : (
        <p>No Proofs Uploaded</p>
      )}
      {proofsData.open && (
        <ImagePreview
          imagesDataObj={proofsData}
          requestInfo={{ item }}
          handleClose={() => setProofsData({ open: false, data: [] })}
        />
      )}
      {workProofModal.open && (
        <WorkProofModal
          handleClose={() => setWorkProofModal({ open: false, data: {} })}
          data={workProofModal.data}
        />
      )}
    </div>
  );
};

export default WorkProofPreview;

import { useEffect, useMemo, useState } from 'react';
import UserDetail from '../../../components/BarData/UserDetail';
import ModalForm from '../../../components/ModalForm';
import OverlayContainer from '../../../components/OverlayContainer';
import ServiceExpandButton from '../../common/ServiceListExpand';
import useFetchCheckList from '../../hooks/useFetchCheckList';
import NotesPopup from '../../vender/PrimaryComponents/requestComponents/vehicle/NotesPopUp';
import WorkProofData from '../bulkInfoModal/components/WorkProofData';
import WorkProofPreview from '../bulkInfoModal/components/WorkProofPreview';
import AssignVendorModal from '../components/schedule/AssignVendorModal';
import RemoveVendorNote from './requestComponents/RemoveVendorNote';
import Scheduled from './requestComponents/ScheduledInfo';
import { RootState } from '../../../state/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import DisputeHistoryPopup from '../../common/DisputeHistoryPopup';
import { DisputeLists } from './DisputeLists';
import RTSInspectionSummary from '../../common/requestDetail/RTSInspectionSummary';
import useFetchVendorSingleRequest from '../../hooks/vendor/useFetchVendorSingleRequest';
import VehicleQuestionnaire from '../inspection/vehicleQuestionnaire/Index';
import { Button } from '@mui/material';
import CustomConfirm from '../../../components/PopOver/CustomDelete';
import useFetchVehicleChecklist from '../../hooks/useFetchVehicleChecklist';
import PriorityFeild from './PriorityFeild';
import { ThreeDots } from 'react-loader-spinner';
import useFetchInvoiceDetails from './Invoice/components/useFetchInvoiceDetails';
import RequestPart from './RequestPart';

const Request = ({
  handleClose,
  item,
  body1,
  fullbody2,
  body4,
  body5,
  status,
  body6,
  type,
  refetch,
}: any) => {
  const dispatch = useDispatch();
  const { fetchRequestData: fetchRequestDetails }: any = useFetchVendorSingleRequest();
  const isDisputedPage = window.location.href.includes('dispute');
  const { servicesList, dispute, requestLoader }: any = useSelector(
    (state: RootState) => state.userState
  );
  const { setServicesList, setDispute, setAttachments, setRequestLoader } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const { invoices, fetchInvoices } = useFetchInvoiceDetails();
  const [vendorModal, setVendorModal] = useState({ open: false, data: {} });
  const [removing, setRemoving] = useState(false);
  const [requestData, setRequestData] = useState<any>(null);
  const { checkList: checklistData, getCheckList } = useFetchCheckList();
  const { vehicleChecklist, getVehicleCheckList } = useFetchVehicleChecklist();
  const handleRemove = () => {};

  const updatedItem = useMemo(
    () => ({
      ...item,
      ...requestData,
      ...vehicleChecklist,
      invoices,
      fetchInvoices,
      ...(invoices?.[0]?.invoices?.filter((invoices: any) => invoices.recipient === 'vendor')
        ?.length > 0 && {
        vendor_invoice_amount: invoices?.[0]?.invoices?.filter(
          (invoices: any) => invoices.recipient === 'vendor'
        )?.[0]?.total_amount,
      }),
      setVendorModal,
      handleRemoveVendor: handleRemove,
      setRejecting: setRemoving,
    }),
    [item, requestData, vehicleChecklist, invoices]
  );
  const isSchedulesByDSP: boolean =
    updatedItem?.status_id === '1' && updatedItem.request_type === 'manual_dsp';
  const [workProofData, setWorkProofData] = useState(false as boolean);
  const workProofDataObj = { item: updatedItem, checklistData };

  useEffect(() => {
    if (![-1, 4].includes(+item?.status_id)) {
      getCheckList(item, true, true);
    }
  }, [item?.id]);
  const fetch = async () => {
    setRequestLoader(true);
    await fetchRequestDetails(item, true).then((res: any) => {
      if (res) {
        const data = {
          ...res,
          id: res?.preventive_request_id || res?.id,
          preventive_request_id: res?.preventive_request_id || res?.id,
          title: `Request-${res?.preventive_request_id || res?.id}`,
        };
        setRequestData(data);
        if (item?.invoiceModal?.open && item?.setInvoiceModal) {
          item?.setInvoiceModal({ ...item?.invoiceModal, data: data });
        }
      }
    });
    await getVehicleCheckList(item, true);
    await fetchInvoices(item, true);
    // for multiple refetch in edit requets
    if ([-1, 4].includes(+item?.status_id) && +item.sub_stage !== 3) {
      await getCheckList(item, true, true);
    }
    setRequestLoader(false);
  };
  useEffect(() => {
    if (item) {
      fetch();
    }
  }, [item]);
  const handleCloseAll = () => {
    handleClose();
    setAttachments([]);
    setServicesList({ ...servicesList, show: false });
    setDispute({
      ...dispute,
      open: false,
    });
  };
  const renderLeftDivContents = () => {
    if (dispute?.open) {
      return <DisputeLists item={updatedItem} />;
    } else {
      return (
        <>
          <div className='flex flex-col gap-4'>
            <UserDetail
              label={updatedItem?.dsp?.name || 'N/A'}
              position='right'
              labelSx='whitespace-nowrap overflow-hidden text-ellipsis w-0 flex-1 text-[0.9rem] text-slate-400'
              AvatarSx={'w-[2rem] h-[2rem] text-[1rem]'}
              bgColor='#A271D3'
            />
            <div className=''>
              {body1 &&
                body1.length > 0 &&
                body1.map((elem: any) => (typeof elem === 'string' ? elem : elem(updatedItem)))}
            </div>
          </div>
          {!isSchedulesByDSP && <RTSInspectionSummary item={updatedItem} />}
          <div className=' '>
            <NotesPopup item={updatedItem} type={type} />
          </div>
        </>
      );
    }
  };
  return (
    updatedItem && (
      <>
        <OverlayContainer show={true}>
          <ModalForm title={'Add CSR'} onClose={handleCloseAll} width={'w-[59vw]'} noLabel>
            <>
              {workProofData ? (
                <WorkProofData
                  data={workProofDataObj}
                  setWorkProofData={setWorkProofData}
                  handleClose={handleClose}
                />
              ) : (
                <>
                  {requestLoader && (
                    <div className='flex items-center justify-center w-full min-h-[32rem]'>
                      <ThreeDots
                        height='10'
                        width='50'
                        radius='9'
                        color={'#4378C8'}
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        visible={true}
                      />
                    </div>
                  )}
                  {!requestLoader && (
                    <div className='flex flex-col gap-3  min-h-[32rem] justify-between'>
                      <div className='flex gap-2 items-center'>
                        <p className='text-blue-500 font-semibold text-sm'> {updatedItem?.title}</p>
                        {updatedItem?.request_type === 'repair' && (
                          <div className='w-fit bg-primary-light text-primary px-2 rounded-full'>
                            Repair
                          </div>
                        )}{' '}
                        <PriorityFeild
                          item={{
                            ...updatedItem,
                            refetch: updatedItem?.refetch || refetch,
                            isSmall: true,
                          }}
                        />
                        <RequestPart
                          item={{ ...updatedItem, refetch: updatedItem?.refetch || refetch }}
                          handleClose={handleClose}
                        />
                      </div>
                      <div className='divide-x-2 divide-slate-100 flex h-full'>
                        <section className='w-1/2 mr-3 flex flex-col justify-between gap-4'>
                          {renderLeftDivContents()}
                        </section>
                        <section className='w-1/2 p-1 pl-5 h-full'>
                          <div className='flex flex-col justify-between h-full gap-2'>
                            {removing ? (
                              <div className='bg-primary-light p-1 rounded-lg'>
                                <RemoveVendorNote item={updatedItem} />
                              </div>
                            ) : (
                              <div className='flex flex-col gap-3'>
                                {!isSchedulesByDSP && (
                                  <>
                                    <div className='w-full flex justify-center items-center'>
                                      <VehicleQuestionnaire item={updatedItem} />
                                    </div>
                                    <ServiceExpandButton
                                      item={{ checkList: checklistData }}
                                      variant='expandDown'
                                    />
                                  </>
                                )}

                                {/* {isDisputedPage && ( */}
                                <DisputeHistoryPopup
                                  item={updatedItem}
                                  refetch={refetch || updatedItem?.refetch}
                                  variant='expandDown'
                                />
                                {/* )} */}
                                <Scheduled
                                  item={updatedItem}
                                  refetch={refetch || updatedItem?.refetch}
                                />
                                {[1, 2].includes(+item.status_id) && (
                                  <>
                                    {updatedItem.status_id === '1' ? (
                                      <>
                                        <CustomConfirm
                                          comp={
                                            <Button
                                              variant='contained'
                                              className='w-full mx-auto mt-auto font-bold px-4'>
                                              Assign
                                            </Button>
                                          }
                                          title={`Assign`}
                                          info={`This request is not approved by the DSP yet, do you wish to continue?`}
                                          acceptText='Yes'
                                          accept={() => {
                                            item?.handleSchedule(updatedItem);
                                          }}
                                          bottomBarStyle='px-20'
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          variant='contained'
                                          className='w-full mx-auto mt-auto font-bold px-4'
                                          onClick={() => {
                                            item?.handleSchedule(updatedItem);
                                          }}>
                                          Assign
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                                {['1', '2'].includes(updatedItem.status_id) &&
                                  updatedItem.request_type !== 'manual_dsp' && (
                                    <>
                                      <Button
                                        className='w-full font-bold p-2 bg-blue-500 text-white rounded-lg w-2/3 border-none hover:bg-blue-600'
                                        onClick={() => {
                                          item?.handleReschedule(updatedItem, true);
                                          item?.setRequestModel({ open: false, data: {} });
                                        }}>
                                        Reschedule
                                      </Button>
                                    </>
                                  )}
                                {!servicesList.show && (
                                  <>
                                    {fullbody2 ? (
                                      <div className='grid gap-5 '>
                                        {fullbody2.length > 0 &&
                                          fullbody2.map((elem: any) =>
                                            typeof elem === 'string' ? elem : elem(updatedItem)
                                          )}
                                      </div>
                                    ) : (
                                      <WorkProofPreview
                                        item={updatedItem}
                                        checklistData={checklistData}
                                        setWorkProofData={setWorkProofData}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                            {!servicesList.show && (
                              <>
                                {!fullbody2 && (
                                  <div className='flex flex-col gap-2 max-h-[15rem] overflow-y-auto'>
                                    {body4 &&
                                      body4.length > 0 &&
                                      body4.map((elem: any) =>
                                        typeof elem === 'string'
                                          ? elem
                                          : elem(updatedItem, checklistData)
                                      )}
                                    {body5 && (
                                      <div className='text-center'>
                                        {body5.length > 0 &&
                                          body5.map((elem: any) =>
                                            typeof elem === 'string'
                                              ? elem
                                              : elem(updatedItem, checklistData)
                                          )}
                                      </div>
                                    )}
                                    {status && (
                                      <div className='text-center'>
                                        {status.length > 0 &&
                                          status.map((elem: any) =>
                                            typeof elem === 'string'
                                              ? elem
                                              : elem(updatedItem, checklistData)
                                          )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </section>
                      </div>

                      {body6 && (
                        <div className='w-full text-center flex flex-col gap-3'>
                          {body6.length > 0 &&
                            body6.map((elem: any) =>
                              typeof elem === 'string' ? elem : elem(updatedItem)
                            )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          </ModalForm>
        </OverlayContainer>
        {vendorModal.open && (
          <AssignVendorModal
            data={vendorModal.data}
            handleClose={() => setVendorModal({ open: false, data: {} })}
            refetch={updatedItem?.refetch}
          />
        )}
      </>
    )
  );
};

export default Request;

import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import CsrAPI from '../../../../api/csrApi';
import InvoiceForDspButton from '../../../../assets/InvoiceForDspButton';
import InvoiceForVendorButton from '../../../../assets/InvoiceForVendorButton';
import UserDetail from '../../../../components/BarData/UserDetail';
import HandleLoad from '../../../../components/Loader/HandleLoad';
import ModalForm from '../../../../components/ModalForm';
import OverlayContainer from '../../../../components/OverlayContainer';
import { toast } from '../../../../components/Toast/CustomToast';
import useFetchCheckList from '../../../hooks/useFetchCheckList';
import BulkVehciles from './components/BulkVehicles';
import ShowInvoicesDSP from './dsp/components/ShowInvoices';
import ShowInvoiceVendor from './vendor/ShowInvoiceVendor';
import ServicesOptedVendor from './vendor/components/ServicesOptedVendor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/reducers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state';
import DropdownBulkMove from '../../moveToComplete/components/MoveBulkDropDown';
import useFetchInvoiceDetails from './components/useFetchInvoiceDetails';
import moment from 'moment';
import Address from './components/Address';
import { ThreeDots } from 'react-loader-spinner';
interface Service {
  service?: string;
  name?: string;
  price: string;
  custom?: string;
  vendor_price: string;
  code?: string;
  description?: string;
  qty?: string;
  amount?: string;
  tax?: string;
}

const getStyle = (status: string) => {
  const sent = {
    text: 'Sent',
    color: 'text-green-700',
  };

  const generated = {
    text: 'Generated',
    color: 'text-primary',
  };

  const default_style = {
    text: 'Pending',
    color: 'text-[#FFC857]',
  };

  const updated = {
    text: 'Updated',
    color: 'text-primary',
  };

  const check_updated = status === 'Updated' ? updated : default_style;
  const check_generate = status === 'Generated' ? generated : check_updated;
  return status === 'Sent' ? sent : check_generate;
};

export const getDspInvoiceStatus = (pmRequest: any) => {
  switch (pmRequest?.dsp_invoice_status) {
    case 'sent':
      return {
        text: 'Sent',
        color: 'text-green-700',
      };
    case 'updated':
      return {
        text: 'Updated',
        color: 'text-primary',
      };
    case 'generated':
      return {
        text: 'Generated',
        color: 'text-primary',
      };
    default:
      return {
        text: 'Pending',
        color: 'text-[#FFC857]',
      };
  }
};

export function updateServicesWithChecklists(services: any, item: any) {
  const map2 = new Map();
  item?.checklists?.forEach((checklist: any) => map2.set(checklist?.name, checklist?.id));
  const updatedServices = services?.map((service: any) => {
    return {
      ...service,
      checklist_id: map2.get(service.name),
    };
  });
  return updatedServices;
}

export const getVendorInvoiceStatus = (pmRequest: any) => {
  switch (pmRequest?.vendor_invoice_status) {
    case 'amount_submitted': // by vendor
      return {
        text: 'Sent',
        color: 'text-green-700',
      };
    case 'sent':
      return {
        text: 'Sent',
        color: 'text-green-700',
      };
    case 'updated':
      return {
        text: 'Updated',
        color: 'text-primary',
      };
    case 'amount_submitted_by_csr':
      return {
        text: 'Amount Submitted',
        color: 'text-primary',
      };
    case 'generated':
      return {
        text: 'Generated',
        color: 'text-primary',
      };
    default:
      return {
        text: 'Pending',
        color: 'text-[#FFC857]',
      };
  }
};

export const getStatus = (item: any, showVendor: boolean) => {
  const status: { text: string; color: string } = showVendor
    ? getVendorInvoiceStatus(item)
    : getDspInvoiceStatus(item);
  return <p className={`${status.color} self-end`}>{status.text}</p>;
};
export const Invoice = ({ handleClose, invoiceData, selected, refetch }: any) => {
  const loader = HandleLoad();
  const dispatch = useDispatch();
  const updateVendorInvoice = invoiceData?.updateVendorInvoice || selected?.updateVendorInvoice;
  const { moveToCompleteData } = useSelector((state: RootState) => state.mainState);
  const { userInfoInvoice } = useSelector((state: RootState) => state.userState);
  const { setCompleteData, setuserInfoInvoice } = bindActionCreators(actionCreators, dispatch);
  const isMoveToComplete = moveToCompleteData?.selected;
  const [isValidConfirmationValue, setIsValidConfirmationValue] = useState(false);
  const [confirmNumber, setConfirmNumber] = useState<any>(null);
  const [showVendor, setShowVendor] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<boolean>(false);
  const { checkList: checklists, getCheckList } = useFetchCheckList();
  const [predefinedServices, setPredefinedServices] = useState<Service[]>([]);
  const [customServices, setCustomServices] = useState<Service[]>([]);
  const [vendorPDF, setVendorPDf] = useState<any>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<any>(null);
  const [note, setNote] = useState<any>(null);
  const [item, setItem] = useState<any>(null);
  const isMoveToCompletePage = window.location.href.includes('moveToComplete');
  const [services, setServices] = useState<Service[]>(predefinedServices);
  const [savedVendorData, setSavedVendorData] = useState(null as any);
  const [savedDSPData, setSavedDSPData] = useState(null as any);
  const [address, setAddress] = useState({ phoneNumber: '', email: '', address: '' });
  const {
    invoiceDetails,
    fetchInvoiceDetails,
    invoices,
    fetchInvoices,
    invoiceLoader,
    setInvoiceLoader,
  } = useFetchInvoiceDetails();

  const taxIndex = invoices?.[0]?.invoices?.findIndex((invoice: any) =>
    showVendor ? invoice.recipient === 'vendor' : invoice.recipient === 'dsp'
  );
  const isPartner: boolean = item?.vendor?.account_id === item?.partner?.account_id;
  const tax =
    taxIndex !== -1 && invoices?.[0]?.invoices
      ? invoices?.[0]?.invoices?.[taxIndex]?.tax_percent || 0
      : invoices?.[0]?.initial_tax_percent || 0;
  const [showEdit, setShowEdit] = useState<any>({
    show: false,
    value: '',
    defaultValue: tax,
  });

  const getChecklistName = new Map();
  item?.checklists?.forEach((checklist: any) =>
    getChecklistName.set(checklist?.name, checklist?.id)
  );
  useEffect(() => {
    setShowEdit({ ...showEdit, defaultValue: tax });
  }, [invoices, showVendor]);
  useEffect(() => {
    setServices([...predefinedServices, ...customServices]);
  }, [predefinedServices, customServices, updateVendorInvoice]);
  const taxRate = Number(showEdit?.defaultValue || 8.3) / 100;

  const handelNextButton = () => {
    setNextPage(!nextPage);
  };
  const handleDSPInvoice = (payload: any) => {
    let startTime = new Date().getTime();
    const timeoutId = setTimeout(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - startTime) / 1000;
      if (elapsedTime > 2) {
        toast.info('Please wait till PDF is generating');
      }
    }, 2000);
    CsrAPI.generateDSPInvoice(payload).then((res) => {
      if (res.status === 200) {
        setuserInfoInvoice({ ...userInfoInvoice, dsp: {} });
        refetch?.();
        setSavedDSPData(null);
        fetchInvoices(item);
        if (nextPage) {
          toast.success(`Invoice sent to DSP`);
        } else {
          toast.success(`Invoice generated`);
        }
        clearTimeout?.(timeoutId);
      } else {
        toast.error(res?.response?.data?.message || 'something went wrong');
        clearTimeout?.(timeoutId);
      }
      loader(false);
    });
  };
  const handleUpdateDSPInvoice = (payload: any) => {
    let startTime = new Date().getTime();
    const timeoutId = setTimeout(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - startTime) / 1000;
      if (elapsedTime > 2) {
        toast.info('Please wait till PDF is generating');
      }
    }, 2000);
    CsrAPI.updateDSPInvoice(payload).then((res) => {
      if (res.status === 200) {
        setuserInfoInvoice({ ...userInfoInvoice, dsp: {} });
        refetch?.();
        setSavedDSPData(null);
        fetchInvoices(item);
        if (nextPage) {
          toast.success(`Invoice sent to DSP`);
        } else {
          toast.success(`Invoice updated`);
        }
        clearTimeout?.(timeoutId);
      } else {
        toast.error(res?.response?.data?.message || 'something went wrong');
        clearTimeout?.(timeoutId);
      }
      loader(false);
    });
  };
  const generateVendorInvoice = (formData: any) => {
    let startTime = new Date().getTime();
    const timeoutId = setTimeout(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - startTime) / 1000;
      if (elapsedTime > 2) {
        toast.info('Please wait till PDF is generating');
      }
    }, 2000);
    CsrAPI.generateVendorInvoice(formData).then((res) => {
      clearTimeout?.(timeoutId);
      if (res.status === 200) {
        setuserInfoInvoice({ ...userInfoInvoice, vendor: {} });
        refetch?.();
        setSavedVendorData(null);
        fetchInvoices(item);
        if (nextPage) {
          toast.success(`Invoice sent to Vendor`);
        } else {
          toast.success(`Invoice ${updateVendorInvoice ? 'Updated' : 'Generated'}`);
        }
      } else {
        toast.error(res?.response?.data?.message || 'something went wrong');
      }
      loader(false);
    });
  };
  const generateVendorInvoiceForDispute = (formData: any) => {
    let startTime = new Date().getTime();
    const timeoutId = setTimeout(() => {
      const currentTime = new Date().getTime();
      const elapsedTime = (currentTime - startTime) / 1000;
      if (elapsedTime > 2) {
        toast.info('Please wait till PDF is generating');
      }
    }, 2000);
    CsrAPI.generateVendorInvoiceForDispute(formData).then((res) => {
      clearTimeout?.(timeoutId);
      if (res.status === 200) {
        refetch?.();
        setSavedVendorData(null);
        fetchInvoices(item);
        if (nextPage) {
          toast.success(`Invoice sent to Vendor`);
        } else {
          toast.success(`Invoice Updated!`);
        }
      } else {
        toast.error(res?.response?.data?.message || 'something went wrong');
      }
      loader(false);
    });
  };
  const sendVendorInvoice = (data: any) => {
    CsrAPI.sendVendorInvoice(data).then((res) => {
      if (res.status === 200) {
        setSavedVendorData(null);
        refetch?.();
        if (nextPage) {
          toast.success(`Invoice sent to Vendor`);
        } else {
          toast.success(`Invoice generated`);
        }
      } else {
        toast.error('something went wrong');
      }
      loader(false);
    });
  };
  const sendDSPInvoice = (data: any) => {
    CsrAPI.sendDSPInvoice(data).then((res) => {
      if (res.status === 200) {
        refetch?.();
        setSavedDSPData(null);
        if (nextPage) {
          toast.success(`Invoice sent to DSP`);
        } else {
          toast.success(`Invoice generated`);
        }
      } else {
        toast.error('something went wrong');
      }
      loader(false);
    });
  };

  async function generateAndUploadPDFs(
    services: any[],
    invoiceNumber: string,
    note: string,
    item?: any
  ) {
    const transformedServices = services.map((service: any) => {
      const estimatedAmountDSP = service?.tax
        ? Number(service?.rate || 0) *
          Number(service?.qty || 0) *
          (1 + Number(showEdit?.defaultValue) / 100)
        : Number(service?.rate || 0) * Number(service?.qty || 0);
      return {
        ...(!service.custom && {
          checklist_id:
            service?.checklist_id?.toString() || String(getChecklistName.get(service.name)),
        }),
        service_name: service.name,
        quantity: Number(service?.qty || 0) || 0,
        rate: service.rate ? String(service.rate) : '0',
        ...(service.custom && {
          custom: true,
        }),
        ...(service?.code?.length > 0 && { product_code: String(service?.code) || '' }),
        ...(service?.date && {
          service_date:
            String(moment?.utc(service.date.$d || service?.date).format('YYYY-MM-DD')) || '',
        }),
        ...(service?.tax && { is_taxable: Boolean(service?.tax) || '' }),
        ...(service?.description && { description: String(service?.description) || '' }),
        subtotal_amount: Number(service?.rate || 0) * Number(service?.qty || 0),
        estimated_amount: Number(estimatedAmountDSP || 0),
        address:
          String(address?.address) ||
          'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920',
        email: address?.email || 'fleet@lmdmax.com',
        phone: address?.phoneNumber || '425-299-2247',
      };
    });
    const payload = {
      preventive_request_id: item.preventive_request_id || item.id || '',
      invoice_number: invoiceNumber,
      tax_percent: String(showEdit?.defaultValue || 0),
      services: transformedServices,
      company_id: Number(item?.vehicle?.company),
      ...(note.length > 0 && { note: note || '' }),
    };
    if (['updated', 'sent'].includes(item?.dsp_invoice_status)) {
      handleUpdateDSPInvoice(payload);
    } else {
      handleDSPInvoice(payload);
    }
  }
  const handelNextProcess = () => {
    if (!nextPage) {
      if (!invoiceNumber) {
        toast.info('Please enter invoice number');
        return;
      }
      if (address?.email.length === 0) {
        toast.info('Please add email!');
        return;
      }
      if (address?.address.length === 0) {
        toast.info('Please add address!');
        return;
      }
      if (address?.phoneNumber.length === 0) {
        toast.info('Please add email!');
        return;
      }
    }
    const estimatedTotal = services
      .reduce((acc: number, service: any) => {
        const totalPriceWithoutTax = Number(service.rate || 0) * (service.qty || 0);
        const taxAmount = service.tax ? totalPriceWithoutTax * taxRate : 0;
        const totalPriceWithTax = totalPriceWithoutTax + taxAmount;
        return acc + totalPriceWithTax;
      }, 0)
      .toFixed(2);
    if (isMoveToComplete && isMoveToCompletePage && showVendor && !nextPage) {
      if (!confirmNumber && showVendor) {
        toast.info('Please enter Confirm Number');
        return;
      }
      if (+estimatedTotal !== +confirmNumber) {
        setIsValidConfirmationValue(true);
        toast.error('Please enter correct Confirmation Value*');
        return;
      } else {
        setIsValidConfirmationValue(false);
      }
    }
    const formData = new FormData();
    loader(true);

    formData.append('preventive_request_id', item.id || item?.preventive_request_id);

    if (showVendor) {
      if (!nextPage) {
        const transformedServices = services.map((service: any) => {
          const serviceDate =
            String(moment?.utc(service.date.$d || service?.date).format('YYYY-MM-DD')) || '';
          const checklist =
            service?.checklist_id?.toString() || getChecklistName.get(service.name) !== undefined
              ? String(getChecklistName.get(service.name))
              : undefined;

          return {
            service_name: service?.name?.toString(),
            rate: service?.rate ? String(service?.rate) : '0',
            service_date: serviceDate,
            ...(!service?.custom && { checklist_id: checklist }),
            ...(service?.custom && { custom: true }),
            ...(service?.code?.length > 0 && { product_code: String(service?.code) || '' }),
            ...(service?.tax && {
              tax: Boolean(service?.tax) || '',
            }),
            quantity: Number(service?.qty || 0) || 0,
            ...(service?.description && { description: String(service?.description) || '' }),
          };
        });
        const subtotal = services
          .reduce((acc: number, service: any) => {
            const totalPrice = Number(service.rate || 0) * (service.qty || 0);
            return acc + totalPrice;
          }, 0)
          .toFixed(2);
        const payload = {
          ...(isMoveToComplete &&
            confirmNumber?.length > 0 && { final_amount: Number(confirmNumber) || 0 }),
          preventive_request_id: item.preventive_request_id || item.id || '',
          invoice_number: invoiceNumber,
          services: transformedServices,
          tax_percent: String(showEdit?.defaultValue || 0),
          subtotal_amount: subtotal === null ? 0 : Number(subtotal || 0),
          estimated_amount: Number(estimatedTotal || 0),
          address:
            String(address?.address) ||
            'Americas Repair Solutions 7 PATRIOT HILL DR - BASKING RIDGE, NJ 07920',
          email: address?.email || 'fleet@lmdmax.com',
          phone: address?.phoneNumber || '425-299-2247',
          ...(note.length > 0 && { note: note || '' }),
        };
        // case for dispute
        if (
          (['raised', 'declined']?.includes(item?.vendor_invoice_dispute) || isMoveToComplete) &&
          ['sent', 'updated', 'amount_submitted_by_csr']?.includes(item?.vendor_invoice_status)
        ) {
          generateVendorInvoiceForDispute(payload);
        } else {
          // case if invoice already updated
          if (
            ['sent', 'updated', 'amount_submitted_by_csr']?.includes(item?.vendor_invoice_status)
          ) {
            generateVendorInvoiceForDispute(payload);
          } else {
            generateVendorInvoice(payload);
          }
        }
      } else {
        sendVendorInvoice({ preventive_request_id: item.id || item?.preventive_request_id });
      }
    } else {
      // Dsp Invoice Handle
      // status_id=11 // inovice_send // sub_stage ==1 // inovice_sent_to_dsp // sub_stage 3: sendt to both
      // status_id=9 not sent to anyone

      if (!nextPage) {
        // Not Yet Sent

        generateAndUploadPDFs(services, invoiceNumber, note, item);
      } else {
        sendDSPInvoice({ preventive_request_id: item.id || item?.preventive_request_id });
      }
    }
  };
  const { data: VENDOR_INVOICE } = useQuery(
    ['VENDOR_INVOICE', item?.id || item?.preventive_request_id],
    async (data) =>
      await CsrAPI.getVendorInvoice({
        preventive_request_id: item.id || item?.preventive_request_id,
      }),
    {
      onSuccess: (response: any) => {
        if (!(response.status >= 200 && response.status <= 210)) {
          toast.error(
            response?.response?.data?.message || 'Something went wrong please reload this page!'
          );
        }
      },
      onError: (error: Error) => {
        console.log(error);
        toast.error('Something went wrong please reload this page!');
      },
      enabled: !!item?.id,
    }
  );

  const VENDOR_SERVICES = React.useMemo(() => {
    if (VENDOR_INVOICE) {
      return (VENDOR_INVOICE?.data?.data && VENDOR_INVOICE?.data?.data[0].services) || [];
    } else {
      return [];
    }
  }, [VENDOR_INVOICE]);
  const vendorInvoices = useMemo(() => {
    if (!item) return null;
    return invoices?.[0]?.invoices?.filter((invoices: any) => invoices.recipient === 'vendor');
  }, [invoices, showVendor]);
  const dspInvoices = useMemo(() => {
    if (!item) return null;
    return invoices?.[0]?.invoices?.filter((d: any) => d.recipient === 'dsp');
  }, [invoices]);

  useEffect(() => {
    fetchInvoices(item);
  }, [item?.preventive_request_id, item?.id]);
  useEffect(() => {
    if (showVendor) fetchInvoiceDetails(vendorInvoices?.[0]?.id);
  }, [vendorInvoices?.[0]?.id, showVendor]);

  const handleNextPageofVendor = () => {
    if (
      isMoveToComplete &&
      !['amount_submitted', 'amount_submitted_by_csr'].includes(item?.vendor_invoice_status) &&
      isNaN(item?.final_payment)
    ) {
      setNextPage(false);
    } else {
      setNextPage(true);
    }
  };

  const showVendorDetails = async (item: any) => {
    if (savedVendorData) {
      const { vendorPDF, note, invoiceNumber, services } = savedVendorData;
      setVendorPDf(vendorPDF);
      setNote(note);
      setInvoiceNumber(invoiceNumber);
      setServices(services);
      if (
        ['generated', 'sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
          item?.vendor_invoice_status
        )
      ) {
        if (!nextPage) {
          handleNextPageofVendor();
        }
      } else {
        if (nextPage) {
          setNextPage(false);
        }
      }
      return;
    }

    if (
      ['generated', 'sent', 'amount_submitted', 'amount_submitted_by_csr', 'updated'].includes(
        item?.vendor_invoice_status
      )
    ) {
      const servicesArr: any[] = [];
      const customArray: any[] = [];
      invoiceDetails?.invoice?.additional_info?.length > 0 &&
        (invoiceDetails?.invoice?.additional_info || [])?.forEach(
          (d: {
            service: 'string';
            price: 'string';
            rate?: string;
            custom?: boolean;
            name?: string;
            vendor_price?: string;
            id?: any;
            checklist_id?: any;
            disputed_amount?: any;
            product_code?: string;
            tax?: boolean;
            is_taxable?: boolean;
            description: string;
            service_date: any;
            quantity?: any;
            disputed_quantity?: any;
            disputed_rate?: any;
          }) => {
            if (d.service) {
              if (d?.custom || !d.checklist_id || !d?.id) {
                customArray.push({
                  name: d.service || d.name,
                  rate: d?.rate || d?.price,
                  custom: true,
                  tax: d?.tax || d?.is_taxable || false,
                  date: d?.service_date || item?.appointments?.[0]?.date,
                  ...(d?.disputed_amount && { disputed_amount: d?.disputed_amount }),
                  ...(d?.disputed_rate && { disputed_rate: d?.disputed_rate }),
                  ...(d?.disputed_quantity && { disputed_quantity: d?.disputed_quantity }),
                  ...(d?.product_code && { code: d?.product_code }),
                  ...(d?.description && { description: d?.description }),
                  ...(d?.quantity && { qty: d?.quantity }),
                });
              } else {
                servicesArr.push({
                  name: d.service || d.name,
                  rate: d.rate || d?.price,
                  tax: d?.tax || d?.is_taxable || false,
                  date: d?.service_date || item?.appointments?.[0]?.date,
                  checklist_id: d.checklist_id || d.id || getChecklistName.get(d.service || d.name),
                  ...(d?.disputed_amount && { disputed_amount: d?.disputed_amount }),
                  ...(d?.disputed_rate && { disputed_rate: d?.disputed_rate }),
                  ...(d?.disputed_quantity && { disputed_quantity: d?.disputed_quantity }),
                  ...(d?.product_code && { code: d?.product_code }),
                  ...(d?.description && { description: d?.description }),
                  ...(d?.quantity && { qty: d?.quantity }),
                });
              }
            }
          }
        );
      setNote(invoiceDetails?.invoice?.note || '');
      setInvoiceNumber(invoiceDetails?.invoice?.number || '');
      setPredefinedServices(servicesArr);
      setCustomServices(customArray);

      if (
        (updateVendorInvoice &&
          (item?.disputeUpdate || !['updated'].includes(item?.vendor_invoice_status))) ||
        (isMoveToCompletePage && !item?.final_payment)
      ) {
        setNextPage(false);
      } else {
        handleNextPageofVendor();
      }
    } else {
      const filteredData: any[] = VENDOR_SERVICES.map((element: any) => ({
        name: element.name || element.service,
        rate: element.amount || '0',
        qty: 1,
        date: item?.appointments?.[0]?.date || '',
        tax: true,
      }));

      setPredefinedServices(updateServicesWithChecklists(filteredData, item));
      setCustomServices([]);
      setNote('');
      setInvoiceNumber('');
      if (nextPage) {
        setNextPage(false);
      }
    }
  };
  const showDspDetails = (item: any) => {
    if (savedDSPData) {
      const { note, invoiceNumber, services } = savedDSPData;
      setNote(note);
      setInvoiceNumber(invoiceNumber);
      setServices(services);
      if (['generated', 'sent', 'updated'].includes(item?.dsp_invoice_status)) {
        if (!nextPage) {
          setNextPage(true);
        }
      } else {
        if (nextPage) {
          setNextPage(false);
        }
      }
      return;
    }
    if (['generated', 'sent', 'updated'].includes(item?.dsp_invoice_status)) {
      const servicesArr: any[] = [];
      const customArray: any[] = [];
      const saved_data: any = invoices?.[0]?.invoices?.filter((d: any) => d.recipient === 'dsp');
      saved_data?.forEach((d: any, index: number) => {
        const serviceDetail = d.service_detail;

        if (!serviceDetail?.checklist_id || serviceDetail?.additional_info?.custom) {
          customArray.push({
            name: serviceDetail?.service_name,
            pdf: d?.invoice_url,
            rate: serviceDetail?.rate || serviceDetail?.price,
            custom: true,
            tax: serviceDetail?.is_taxable || false,
            date: serviceDetail?.service_date || item?.appointments?.[0]?.date,
            ...(serviceDetail?.product_code && { code: serviceDetail?.product_code }),
            ...(serviceDetail?.description && { description: serviceDetail?.description }),
            ...(serviceDetail?.quantity && { qty: serviceDetail?.quantity }),
          });
        } else {
          servicesArr.push({
            name: serviceDetail?.service_name,
            pdf: d.invoice_url,
            rate: serviceDetail?.rate || serviceDetail?.price,
            proofs: serviceDetail?.proofs,
            tax: serviceDetail?.is_taxable || false,
            date: serviceDetail?.service_date || item?.appointments?.[0]?.date,
            checklist_id:
              serviceDetail?.checklist_id || getChecklistName.get(serviceDetail?.service_name),
            ...(serviceDetail?.product_code && { code: serviceDetail?.product_code }),
            ...(serviceDetail?.description && { description: serviceDetail?.description }),
            ...(serviceDetail?.quantity && { qty: serviceDetail?.quantity }),
          });
        }
      });
      setPredefinedServices(servicesArr);
      setCustomServices(customArray);
      setNote(saved_data?.[0]?.additional_info?.note || '');
      setInvoiceNumber(saved_data?.[0]?.invoice_number?.split('/')[0] || '');
      if (!nextPage) {
        setNextPage(true);
      }
    } else {
      const filteredData: any[] = checklists.map((element: any) => ({
        name: element.name,
        rate: 0,
        proofs: element?.proofs || [],
        checklist_id: element?.id,
        qty: 1,
        date: item?.appointments?.[0]?.date || '',
        tax: true,
      }));
      setPredefinedServices(filteredData);
      setCustomServices([]);
      setNote('');
      setInvoiceNumber('');
      if (nextPage) {
        setNextPage(false);
      }
    }
  };
  useEffect(() => {
    if (item) {
      if (showVendor) {
        showVendorDetails(item);
      }
    }
  }, [showVendor, item, VENDOR_SERVICES, invoiceDetails, savedVendorData]);
  useEffect(() => {
    if (item) {
      if (!showVendor) {
        showDspDetails(item);
      }
    }
  }, [showVendor, item, checklists, invoices]);

  useEffect(() => {
    if (!invoiceData?.bulkSchedules) {
      setItem(invoiceData);
    }
  }, [invoiceData]);
  useEffect(() => {
    if (selected || invoiceData) {
      const { onClicked } = selected || invoiceData;
      if (onClicked === 'DSP') {
        setShowVendor(false);
      } else {
        setShowVendor(true);
      }
      if (invoiceData?.clickedFromViewInvoice || selected?.clickedFromViewInvoice) {
        setTimeout(() => {
          setNextPage(false);
        }, 2000);
      }
    }
  }, []);
  useEffect(() => {
    if (selected || invoiceData) {
      if (invoiceData?.clickedFromViewInvoice) {
        setTimeout(() => {
          setNextPage(false);
        }, 1000);
      }
    }
  }, [item?.id]);
  // console.log(invoiceData);
  useEffect(() => {
    setuserInfoInvoice({});
  }, []);
  useEffect(() => {
    if (item) {
      getCheckList(item);
      setSavedDSPData(null);
      setSavedVendorData(null);
    }
  }, [item]);

  useEffect(() => {
    if (!savedDSPData && !savedVendorData) {
      if (showVendor) {
        showVendorDetails(item);
      } else {
        showDspDetails(item);
      }
    }
  }, [savedDSPData, savedVendorData]);

  const vendorStatus: { text: string; color: string } = useMemo(() => {
    return getVendorInvoiceStatus(item);
  }, [item]);

  const dspStatus: { text: string; color: string } = useMemo(() => {
    return getDspInvoiceStatus(item);
  }, [item]);

  const DropDown = () => (
    <BulkVehciles
      item={item}
      invoiceData={invoiceData}
      setItem={setItem}
      selected={selected}
      handleClose={handleClose}
      getStatus={(item: any) => getStatus(item, showVendor)}
    />
  );

  useEffect(() => {
    if (isMoveToComplete && item?.final_payment) {
      setConfirmNumber(item?.final_payment || '');
    } else {
      setConfirmNumber(null);
    }
  }, [item]);
  useEffect(() => {
    setInvoiceLoader(true);
    const timeoutId = setTimeout(() => {
      setInvoiceLoader(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [showVendor, nextPage]);
  console.log(services);
  return (
    <div className={`${isMoveToCompletePage ? 'h-[40rem]' : 'h-[44rem]'} w-full`}>
      <div className='h-full  w-full'>
        <div className='parentContainer flex justify-between h-full  w-full'>
          <div className='border-r-[0.2rem]'>
            <div className='leftContainer w-[12rem]  border-[#A0A0A0] pr-4 mt-7'>
              <div className='border-r-indigo-500'>
                {(!updateVendorInvoice || isMoveToComplete) && (
                  <div>
                    <button
                      className={`w-full py-9 content-center border-2 mb-5 rounded-[1rem] hover:bg-primary-light ${
                        showVendor ? '' : 'bg-primary-light'
                      }`}
                      onClick={() => {
                        if (showVendor) {
                          if (
                            ![
                              'sent',
                              'amount_submitted',
                              'amount_submitted_by_csr',
                              'updated',
                            ].includes(item?.vendor_invoice_status)
                          ) {
                            setSavedVendorData({
                              ...{
                                vendorPDF,
                                note,
                                invoiceNumber,
                                services,
                              },
                            });
                            setShowEdit({ ...showEdit, show: false });
                          }
                          setShowVendor(false);
                          isMoveToComplete &&
                            setCompleteData({ ...moveToCompleteData, isVendor: false });
                        }
                      }}>
                      <div className='flex justify-center'>
                        <InvoiceForDspButton showVendor={showVendor} />
                      </div>
                      <p>Invoice for DSP</p>
                    </button>
                  </div>
                )}
                <div>
                  <button
                    className={`w-full py-8 content-center border-2 mb-4 rounded-[1rem] hover:bg-primary-light ${
                      showVendor ? 'bg-primary-light' : ''
                    }`}
                    onClick={() => {
                      if (!showVendor) {
                        if (!['sent', 'updated'].includes(item?.dsp_invoice_status)) {
                          setSavedDSPData({
                            ...{
                              note,
                              invoiceNumber,
                              services,
                            },
                          });
                          setShowEdit({ ...showEdit, show: false });
                        }
                        setShowVendor(true);
                        isMoveToComplete &&
                          setCompleteData({ ...moveToCompleteData, isVendor: true });
                      }
                    }}>
                    <div className='flex justify-center'>
                      <InvoiceForVendorButton showVendor={showVendor} />
                    </div>
                    <div className='ml-1'>
                      Invoice for <br /> Vendor {isPartner && <>(Partner)</>}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='rightContainer  flex flex-col gap-2 justify-start pl-6 flex-1 py-6  overflow-auto h-full'>
            <div className='overflow-auto h-full flex flex-col pr-2 gap-2'>
              {/* Request Van Number and UserInfo  */}
              <div className='flex justify-between'>
                <div className=''>
                  <div className='flex items-center text-[1.5rem] text-[#4378C8] mb-2'>
                    Request {item?.id || item?.preventive_request_id}
                    {invoiceDetails?.invoice?.date && (
                      <p className='flex gap-2  ml-2 text-gray-400 items-center text-[1.3rem]'>
                        <span className='mb-1'>{'|'}</span>{' '}
                        {moment(invoiceDetails?.invoice?.date).format('L')}
                      </p>
                    )}
                  </div>
                  <div className='text-[1.2rem] text-[#515151] font-bold'>
                    {item?.vehicle?.name || 'N/A'}
                  </div>
                  <div className='text-[1.1rem] text-[#A0A0A0]'>{item?.vehicle?.vin || 'N/A'}</div>
                </div>

                <div className='flex flex-col justify-between'>
                  {showVendor ? (
                    <div className='mr-10 flex gap-2 h-fit items-center'>
                      <UserDetail
                        label={item?.vendor?.name || 'N/A'}
                        position='right'
                        labelSx='whitespace-nowrap overflow-hidden text-ellipsis  flex-1 text-[0.9rem] text-slate-400'
                        AvatarSx={'w-[2rem] h-[2rem] text-[1rem]'}
                        bgColor={'#A271D3'}
                      />
                    </div>
                  ) : (
                    <div className='mr-10 flex gap-2 h-fit items-center'>
                      <UserDetail
                        label={item?.dsp?.name || 'N/A'}
                        position='right'
                        labelSx='whitespace-nowrap overflow-hidden text-ellipsis flex-1 text-[0.9rem] text-slate-400'
                        AvatarSx={'w-[2rem] h-[2rem] text-[1rem]'}
                        bgColor={'#EA7B59'}
                      />
                    </div>
                  )}
                  {!moveToCompleteData?.selected &&
                    (selected
                      ? invoiceData.bulkSchedules
                        ? nextPage && DropDown()
                        : getStatus(item, showVendor)
                      : getStatus(item, showVendor))}
                  {moveToCompleteData?.selected && <DropdownBulkMove />}
                </div>
              </div>

              {!nextPage && (
                <div className='w-full flex justify-between'>
                  <div className='flex justify-between'>
                    <div className='mt-5'>
                      <div className='flex justify-between gap-5'>
                        <div className='flex flex-col'>
                          <h1 className='text-l font-normal mb-2'>Invoice Number *</h1>
                          <input
                            type='text'
                            className='border border-[#DDDDDD] px-2 py-2 rounded-md focus:border-primary  focus:outline-none  w-[18rem] '
                            placeholder=''
                            value={invoiceNumber}
                            onChange={(e: any) => setInvoiceNumber(e?.target?.value)}
                          />
                        </div>
                        {isMoveToComplete && showVendor && isMoveToCompletePage && (
                          <div className='ml-[12rem]'>
                            <div className='flex justify-between'>
                              <div className='flex flex-col'>
                                <h1 className='text-l font-normal mb-2'>Confirmation Value*</h1>
                                <input
                                  type='text'
                                  className={`border ${
                                    isValidConfirmationValue ? 'border-[#C55]' : 'border-[#DDDDDD]'
                                  }  px-2 py-2 rounded-md focus:border-primary  focus:outline-none  w-[18rem] `}
                                  placeholder=''
                                  value={confirmNumber || ''}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value;
                                    const isValidInput = /^-?\d*\.?\d*$/.test(value);
                                    if (isValidInput) {
                                      setConfirmNumber(value);
                                      if (isValidConfirmationValue) {
                                        setIsValidConfirmationValue(false);
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div></div>
                  </div>

                  <div className='flex flex-col gap-2'>
                    <div>{DropDown()}</div>
                    {/* {showVendor ? ( */}
                    <Address
                      props={{
                        item,
                        address,
                        setAddress,
                        showVendor,
                        invoiceDetails,
                      }}
                    />
                  </div>
                </div>
              )}

              {/* box */}
              {!nextPage ? (
                <div className={`flex justify-center ${isMoveToCompletePage && 'mt-[-0.5rem]'}`}>
                  {/* Edit Details */}
                  <ServicesOptedVendor
                    handelNextButtonvendor={handelNextProcess}
                    item={item}
                    services={services}
                    setServices={setServices}
                    predefinedServices={predefinedServices}
                    showVendor={showVendor}
                    setNote={setNote}
                    note={note}
                    DropDown={DropDown}
                    updateVendorInvoice={updateVendorInvoice}
                    confirmNumber={confirmNumber}
                    invoiceDetails={invoiceDetails}
                    invoices={invoices}
                    propsNew={{ showEdit, setShowEdit }}
                  />
                </div>
              ) : (
                <>
                  {invoiceLoader ? (
                    <div className='w-full h-full flex justify-center items-center'>
                      <ThreeDots
                        height='30'
                        width='50'
                        radius='9'
                        color={'#4378C8'}
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      {showVendor && (
                        <ShowInvoiceVendor
                          selectedPDF={vendorPDF}
                          GoBack={handelNextButton}
                          handelNextButtonvendor={handelNextProcess}
                          vendorStatus={vendorStatus}
                          updateVendorInvoice={updateVendorInvoice}
                          item={item}
                          invoiceDetails={invoiceDetails}
                          fetchInvoiceDetails={fetchInvoiceDetails}
                          invoices={invoices}
                          invoiceLoader={invoiceLoader}
                        />
                      )}
                      {!showVendor && (
                        <ShowInvoicesDSP
                          services={dspInvoices}
                          GoBack={handelNextButton}
                          handelNextButtonvendor={handelNextProcess}
                          dspStatus={dspStatus}
                          vendorStatus={vendorStatus}
                          item={item}
                          invoices={invoices}
                          invoiceDetails={invoiceDetails}
                          fetchInvoiceDetails={fetchInvoiceDetails}
                          invoiceLoader={invoiceLoader}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const InvoiceModal = ({
  handleClose,
  invoiceData,
  selected,
  refetch,
  onClicked,
  isNotModel,
}: any) => {
  const dispatch = useDispatch();
  const { setuserInfoInvoice } = bindActionCreators(actionCreators, dispatch);
  return (
    <OverlayContainer show={true}>
      <ModalForm title={'Add CSR'} onClose={handleClose} width={'w-[88vw]'} noLabel>
        <Invoice
          handleClose={() => {
            handleClose();
            setuserInfoInvoice({});
          }}
          invoiceData={invoiceData}
          selected={selected}
          refetch={refetch}
          onClicked={onClicked}
          isNotModel={isNotModel}
        />
      </ModalForm>
    </OverlayContainer>
  );
};

export default InvoiceModal;

import { request } from './base';
import { IAcceptCSRequestBulk, IRejectPartnerRequest, IGetSpecificInfoPartner } from './types';

export default class PartnerAPI {
  static getAllPartnerData(data?: any) {
    return request({
      url: '/partner/v1/get_all_partners',
      method: 'get',
      data: data,
    });
  }
  static getUnassignedVendors(data: any) {
    return request({
      url: '/partner/v1/unassigned_vendors',
      method: 'get',
      data: data,
    });
  }
  static getPartnerDetails(data: any) {
    return request({
      url: `/partner/v1/fleetmax_partner/id=${data.id}`,
      method: 'get',
      data: data,
    });
  }
  static assignVendor(data: any) {
    return request({
      url: `/partner/v1/assign_vendor`,
      method: 'post',
      data: data,
    });
  }
  static delete(data: any) {
    return request({
      url: `/partner/v1/unassign_vendor/${data.id}`,
      method: 'delete',
    });
  }
  static deletePartner(data: any) {
    return request({
      url: `partner/v1/partner_delete/${data.id}?email=${data.email}&phone=${data.phone}`,
      method: 'patch',
    });
  }
  static getAllVendor(data?: any) {
    return request({
      url: `/partner/v1/vendors`,
      method: 'get',
    });
  }

  static getAllRequestDetails(data: any) {
    return request({
      url: `/partner/v1/requests?${data.params}`,
      method: 'get',
    });
  }
  static acceptPartnerRequest(data: IAcceptCSRequestBulk) {
    return request({
      url: `/partner/v1/accept_request`,
      method: 'patch',
      data: data,
    });
  }
  static rejectPartnerRequest(data: IRejectPartnerRequest) {
    return request({
      url: `/partner/v1/reject_request`,
      method: 'patch',
      data: data,
    });
  }
  static getAllDataParticularId(data: IGetSpecificInfoPartner) {
    return request({
      url: `/partner/v1/request_info`,
      method: 'patch',
      data: data,
    });
  }
  static getAllInvoiceDetails(data: any) {
    return request({
      url: `/partner/v1/invoice_management?${data.params}`,
      method: 'get',
      data: data,
    });
  }
}

import React, { useState, useRef, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../state/reducers';
import { Button, Menu, Tooltip } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state';
import HandleLoad from '../../../../../components/Loader/HandleLoad';
import { Environment } from '../../../../../constants/environment';
import axios from 'axios';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { toast } from '../../../../../components/Toast/CustomToast';
type SetDownloadOpenFunction = (open: boolean) => void;
type SetTypeFunction = (type: string) => void;
interface DownloadProps {
  setDownloadOpen: SetDownloadOpenFunction;
  setType: SetTypeFunction;
}
interface DataRow {
  date: string;
  invoice_id: string;
  request_id: string;
  station_code?: string;
  dsp_shortcode?: string;
  van_name?: string;
  van_type?: string;
  vin_number?: string;
  csr?: string;
  vendor?: string;
  technician: string;
  amount: string;
  service_items?: string;
  status: string;
  dsp_name?: string;
}

const fields: { name: string; key: keyof DataRow }[] = [
  { name: 'Date', key: 'date' },
  { name: 'Invoice Id', key: 'invoice_id' },
  { name: 'Request Id', key: 'request_id' },
  { name: 'Station code', key: 'station_code' },
  { name: 'DSP Shortcode', key: 'dsp_shortcode' },
  { name: 'DSP Name', key: 'dsp_name' },
  { name: 'Van Name', key: 'van_name' },
  { name: 'Van Type', key: 'van_type' },
  { name: 'VIN Number', key: 'vin_number' },
  { name: 'CSR', key: 'csr' },
  { name: 'Vendor', key: 'vendor' },
  { name: 'Technician', key: 'technician' },
  { name: 'Amount', key: 'amount' },
  { name: 'Service Items', key: 'service_items' },
  { name: 'Status', key: 'status' },
];
const Download: React.FC<DownloadProps> = ({ setDownloadOpen, setType }) => {
  const loader = HandleLoad();
  const date = new Date();
  const baseUrl = Environment.baseUrl;
  const [urlParams, setSearchParams]: any = useSearchParams();
  const dispatch = useDispatch();
  const {
    user,
    multiSelectInvoiceItems,
    downloadView,
    invoiceLoader: isDownloading,
  }: any = useSelector((state: RootState) => state.userState);
  const { setMultiInvoiceItems, setInvoicesLoader } = bindActionCreators(actionCreators, dispatch);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const ref: any = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const [totalAmount, setTotalAmount] = useState(0);
  const calculateTotalAmount = () => {
    let total = 0;
    multiSelectInvoiceItems.forEach((invoice: any) => {
      if (invoice.status === 'payment_received') {
        total += parseFloat(invoice.amount === 'N/A' ? '0' : invoice?.amount);
      }
    });
    setTotalAmount(total);
  };
  useEffect(() => {
    calculateTotalAmount();
  }, [totalAmount, multiSelectInvoiceItems]);

  const handleDownload = ({ type }: any) => {
    const selectedData = multiSelectInvoiceItems.map((row: any) => {
      const filteredRow: { [key in keyof DataRow]: string } = {} as {
        [key in keyof DataRow]: string;
      };
      Object.keys(downloadView).forEach((key) => {
        if (downloadView[key as keyof DataRow]) {
          filteredRow[key as keyof DataRow] = (row[key as keyof DataRow] || '').toString();
        }
      });
      return filteredRow;
    });

    function capitalizeFirstLetter(sentence: any) {
      let words = sentence.split(' ');
      let capitalizedWords = words.map((word: any) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      let capitalizedSentence = capitalizedWords.join(' ');
      return capitalizedSentence;
    }

    if (type === 'excel') {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Selected Data');
      const headerRow = worksheet.addRow(
        Object.keys(selectedData[0]).map((column) => {
          const field = fields.find((field) => field.key === column);
          return field ? capitalizeFirstLetter(field.name) : '';
        })
      );
      selectedData.forEach((row: any) => {
        const rowData = Object.values(row).map((word: any) =>
          capitalizeFirstLetter(word === 'payment_received' ? 'Payment Received' : word)
        );
        worksheet.addRow(rowData);
      });
      const lastColumnIndex = headerRow.actualCellCount - 1;
      const lastRowIndex = headerRow.number + selectedData.length;
      worksheet.getCell(lastRowIndex + 1, lastColumnIndex + 1).value = '';
      worksheet.getCell(lastRowIndex + 2, lastColumnIndex).value = `Total Revenue: $${
        totalAmount === undefined ? '0.00' : totalAmount.toFixed(2)
      }`;
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `${user.name}_${date.toLocaleDateString()}.xlsx`);
      });
    }

    if (type === 'pdf') {
      const doc = new jsPDF();
      doc.setFontSize(6);
      doc.text('Bulk Invoice', 14.5, 15);
      const columns = Object.keys(selectedData[0]);
      const rows = selectedData.map((row: any) =>
        Object.values(row).map((word: any) =>
          capitalizeFirstLetter(word === 'payment_received' ? 'Payment Received' : word)
        )
      );
      rows.push([
        `Total Revenue: $${totalAmount === undefined ? '0.00' : totalAmount.toFixed(2)}`,
        '',
        '',
        '',
        '',
        '',
      ]);
      autoTable(doc, {
        head: [
          columns.map((column) => {
            const field = fields.find((field) => field.key === column);
            return field ? capitalizeFirstLetter(field.name) : '';
          }),
        ],
        body: rows,
        startY: 20,
        styles: {
          cellPadding: 1.5,
          fontSize: 4,
        },
      });
      doc.save(`${user.name}_${date.toLocaleDateString()}.pdf`);
    }
    setMultiInvoiceItems([]);
  };

  return (
    <div className='cursor-pointer'>
      <Tooltip
        title={
          multiSelectInvoiceItems.length === 0 && 'Please select at least 1 invoice to download '
        }>
        <div>
          <Button
            className={`flex px-[1rem] py-[0.6rem] ${
              multiSelectInvoiceItems.length !== 0
                ? `bg-buttonLightColor`
                : 'text-gray-200 bg-gray-100'
            }  rounded-full flex gap-3 items-center  font-bold`}
            // ref={parentRef}
            disabled={multiSelectInvoiceItems.length === 0}
            onClick={handleClick}
            variant='outlined'>
            <i
              className={`pi pi-download font-bold ${
                multiSelectInvoiceItems.length === 0 ? 'text-gray-500' : 'text-primary'
              } `}></i>
            <div
              className={`text-[1rem]  ${
                multiSelectInvoiceItems.length !== 0 ? `text-tableLinkColor` : 'text-gray-400'
              }  `}>
              Download
            </div>
            <i
              className={`pi pi-angle-up font-bold ${
                multiSelectInvoiceItems.length === 0 ? 'text-gray-500' : 'text-primary'
              }  ${isOpen ? 'rotate-0' : 'rotate-180'} ease-in-out duration-200`}></i>
          </Button>
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: '10rem', // Set your desired width here
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div ref={menuRef} style={{ minWidth: parentRef.current?.offsetWidth }}>
          <div
            className='mr-[0.12rem] py-[0.3rem]  text-[1.1rem] cursor-pointer text-center hover:bg-primary-light'
            onClick={() => {
              if (!isDownloading) {
                if (multiSelectInvoiceItems.length > 0) {
                  multiSelectInvoiceItems.map((item: any) => {
                    setInvoicesLoader(true);
                    toast.info('Downloading PDF!');
                    axios
                      .get(
                        `${baseUrl}csr/v1/download_invoice?ids_arr=${JSON.stringify([
                          item?.invoice_id,
                        ])}`,
                        {
                          responseType: 'arraybuffer',
                          headers: {
                            'x-access-token': user?.token || '',
                            'x-access-user': user?.account_id || '',
                            'app-type': '9',
                          },
                        }
                      )
                      .then((res) => {
                        const url = window.URL.createObjectURL(
                          new Blob([res.data], { type: 'application/pdf' })
                        );
                        var link = document.createElement('a');
                        link.href = url;
                        link.setAttribute(
                          'download',
                          `${item?.vendor}_${date.toLocaleDateString()}.pdf`
                        );
                        document.body.appendChild(link);
                        link.click();
                        setInvoicesLoader(false);
                      });
                  });
                  setMultiInvoiceItems([]);
                  // urlParams.delete('date_type');
                  // setSearchParams(urlParams);
                } else {
                  toast.error('Please Select Atleast One Row');
                }
                handleClose();
              } else {
                toast.error('Please Wait until the previous is downloading');
              }
            }}>
            Invoice ({multiSelectInvoiceItems?.length > 0 ? multiSelectInvoiceItems.length : 0})
          </div>{' '}
          <div
            className='mr-[0.12rem] py-[0.3rem]  text-[1.1rem] cursor-pointer text-center hover:bg-primary-light'
            onClick={() => {
              // setDownloadOpen(true);
              handleDownload({ type: 'excel' });
              handleClose();
            }}>
            Excel
          </div>{' '}
          <div
            className='mr-[0.12rem] py-[0.3rem] text-[1.1rem] cursor-pointer text-center hover:bg-primary-light'
            onClick={() => {
              handleDownload({ type: 'pdf' });
              handleClose();
            }}>
            PDF
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default Download;

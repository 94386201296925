import { Tooltip } from '@mui/material';
export const formatToFixed = (value: number) => {
  return value % 1 === 0 ? value : value?.toFixed(2) || 0;
};
const TillMiles = (item: any) => {
  const miles = Number(item?.miles?.toString()?.replaceAll(/,/gi, ''));
  const curr_miles = Number(item?.curr_miles?.toString()?.replaceAll(/,/gi, ''));
  if (curr_miles > miles + 5000) {
    const value: number = Number(curr_miles - (miles + 5000)) || 0;
    return (
      <div className='flex flex-col items-center justify-center w-[6rem]'>
        <div className={`bg-overdue p-1 rounded-[0.7rem] min-w-[6rem]`}>
          <div className='px-3 py-[0.3rem] bg-white rounded-[0.4rem]'>{formatToFixed(value)}m</div>
          <p className='text-white text-sm text-semibold'>Overdue</p>
        </div>
      </div>
    );
  }
  if (curr_miles >= miles + 3000 && curr_miles <= miles + 5000) {
    const value: number = Number(miles + 5000 - curr_miles) || 0;
    return (
      <div className='flex flex-col items-center justify-center w-[6rem]'>
        <div className={`bg-indue p-1 rounded-[0.7rem] min-w-[6rem]`}>
          <div className='px-3 py-[0.3rem] bg-white rounded-[0.4rem]'>{formatToFixed(value)}m</div>
          <p className='text-white text-sm text-semibold'>In-due</p>
        </div>
      </div>
    );
  } else {
    return (
      <Tooltip
        arrow
        title={
          <p className='text-center'>
            No value falls in the <span className={`text-amber-400 font-bold`}> In-due</span> and
            <span className={`text-rose-300 font-bold`}> Overdue</span> as per the Last Preventive
            and Current Mileage
          </p>
        }>
        <p className=''>{'----'}</p>
      </Tooltip>
    );
  }
};

export default TillMiles;
